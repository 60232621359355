import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#f5f2f2',
    width: '100%'
  },
  paper: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: -25,
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#f5f2f2',
    width: '100%'
  },
  search: {
    width: '100%'
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20,
    width: '100%'
  },
  card: {
    marginTop: 15,
    minWidth: 200,
    minHeight: 120,
    borderRadius: 15,
    width: '100%',
    backgroundColor: '#f5f2f2',
    border: 15
  },
  label: {
    textAlign: 'left',
    fontSize: '17px',
    marginBottom: 15
  },
  textField: {
    width: '100%'
  },
  table: {
    backgroundColor: '#f5f2f2'
  },
  legend: {
    maxWidth: '1px'
  },
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 240
  },
  textLeft: {
    textAlign: 'left',
    fontSize: 17
  }
}))

export default useStyles;
