import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Product {
  id: string
  produto: string
  quantidade: string
  valorTotal: string
}

export const AppTop10: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)

  const [products, setProducts] = useState<Product[]>([])
  const [selectedValue, setSelectedValue] = useState<string>('7,quantidade')
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async (selectedValue: string) => {
    // if (filiais) return
    setLoading(true)
    socket.emit('filtroTop10App', {
      message: {
        room: token,
        filial: filiais,
        filtro: selectedValue
      }
    })
    socket.on('top10DadosApp', (data: any) => {
      try {
        const dados = data?.dados

        setProducts(dados?.itens)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts(selectedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedValue])

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedValue(event.target.value as string)
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <InputLabel
          className={classes.label}
          id="demo-controlled-open-select-label"
        >
          Selecione o período
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={selectedValue}
          onChange={handleChange}
          className={classes.search}
        >
          <MenuItem className={classes.menuItem} value={'0,quantidade'}>
            Por quantidade, hoje.
          </MenuItem>
          <MenuItem className={classes.menuItem} value={'7,quantidade'}>
            Por quantidade, últimos 7 dias.
          </MenuItem>
          <MenuItem className={classes.menuItem} value={'30,quantidade'}>
            Por quantidade, últimos 30 dias.
          </MenuItem>
          <MenuItem className={classes.menuItem} value={'0,valortotal'}>
            Por valor, hoje.
          </MenuItem>
          <MenuItem className={classes.menuItem} value={'7,valortotal'}>
            Por valor, últimos 7 dias.
          </MenuItem>
          <MenuItem className={classes.menuItem} value={'30,valortotal'}>
            Por valor, últimos 30 dias.
          </MenuItem>
        </Select>

        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : products.length === 0 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          products.map((result, index) => (
            <Card key={index} className={classes.card}>
              <CardContent>
                <h3 className={classes.p}>{`${index + 1}º - ${
                  result?.produto
                }`}</h3>
                <p className={classes.p}>Quantidade: {result.quantidade}</p>
                <p className={classes.p}>
                  Valor Total:{' '}
                  {formatPrice(Number(result.valorTotal)) || 'Não informado'}
                </p>
              </CardContent>
            </Card>
          ))
        )}
      </Paper>
    </GridContainer>
  )
}
