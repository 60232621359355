import {LicenseInfo} from '@material-ui/x-grid'
import React from 'react'
import {BrowserRouter} from 'react-router-dom'

import {Providers} from 'providers'

import {Routes} from 'routes'

export const App = () => {
  return (
    <Providers>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Providers>
  )
}
