import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: 'left',
    fontSize: '15px',
    marginBottom: 15,
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  dialogInput: {
    marginBottom: 25
  },
  p: {
    fontSize: 14,
    marginBottom: 10,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '90vh'
  },
  paper: {
    alignSelf: 'flex-start',
    marginTop: -35,
    margin: 'auto',
    flexGrow: 1,
    padding: 20,
    width: '100%',
    maxWidth: 400,
    maxHeight: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  content2: {
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: theme.spacing(2)
  },
  touchable: {
    backgroundColor: '#224aea',
    borderRadius: 20,
    marginBottom: 5,
    height: 40,
    margin: 'auto',
    width: '70%',
    alignSelf: 'flex-end',
    marginTop: 13,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  root: {
    display: 'flex'
  },
  deleteIcon: {
    marginRight: 'auto',
    marginBottom: 25
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: '#631763'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  card: {
    marginTop: 15,
    minWidth: 200,
    minHeight: 120,
    maxHeight: 120,
    borderRadius: 15,
    width: '100%',
    backgroundColor: '#f5f5f5',
    border: 15
  }
}))

export default useStyles;
