import {Grid, GridProps} from '@material-ui/core'
import React from 'react'

export const GridContainer: React.FC<GridProps> = ({children, ...props}) => {
  return (
    <Grid container spacing={1} {...props}>
      {children}
    </Grid>
  )
}
