import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core'

import {ItemObservacao} from '..'

import {useState} from 'react'

import {Button} from 'components/Button'

interface DialogObservacaoItemProps {
  handleCancelClick: () => void
  handleConfirmClick: (observacaoProduto: string) => void
  itemObservacao: ItemObservacao
}

export const DialogObservacaoItem = (props: DialogObservacaoItemProps) => {
  const [novaObservacao, setNovaObservacao] = useState(
    props.itemObservacao.observacao
  )

  return (
    <Dialog open>
      <DialogTitle>Observação</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>produto: {props.itemObservacao.descricao}</strong> <br />
          <TextField
            autoFocus
            label="Observação"
            fullWidth
            multiline
            maxRows={4}
            value={novaObservacao}
            onChange={(e) => setNovaObservacao(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancelClick}>Cancelar</Button>
        <Button onClick={() => props.handleConfirmClick(novaObservacao)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
