import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Product {
  id: string
  produto: string
  quantidade: string
  valorTotal: string
}

export const AppVendaAcougue: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)

  const [products, setProducts] = useState<Product[]>([])
  const [selectedValue, setSelectedValue] = useState<string>('0')
  const [searchValue, setSearchValue] = useState<string>()
  const [socketConnected, setSocketConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([])

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async (selectedValue: string) => {
    setLoading(true)
    socket.emit('filtroVendaAcougueApp', {
      message: {
        room: token,
        filial: filiais,
        filtro: selectedValue,
        produto: searchValue
      }
    })
    socket.on('vendaAcougueDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados?.itens)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts(selectedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedValue])

  useEffect(() => {
    if (products) {
      const filtered = products.filter((product) =>
        product?.produto
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase() || '')
      )
      setFilteredProducts(filtered)
    }
  }, [searchValue, products])

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedValue(event.target.value as string)
  }

  const handleChangeS = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchValue(event.target.value as string)
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <InputLabel
          className={classes.label}
          id="demo-controlled-open-select-label"
        >
          Selecione o período
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          variant="outlined"
          value={selectedValue}
          onChange={handleChange}
          className={classes.search}
          defaultValue={'0'}
        >
          {data.map((item) => (
            <MenuItem
              className={classes.menuItem}
              key={item.key}
              value={item.key}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label="Buscar..."
          margin="normal"
          variant="outlined"
          onChange={handleChangeS}
          className={classes.search2}
          InputProps={{
            type: 'search',
            value: searchValue,
            color: 'primary'
          }}
        />
        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : products.length === 0 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          <>
            {filteredProducts.map((result, index) => (
              <Card key={index} className={classes.card}>
                <CardContent>
                  <h3 className={classes.title}>{result?.produto}</h3>
                  <p className={classes.p}>Quantidade: {result.quantidade}</p>
                  <p className={classes.p}>
                    Valor Total:{' '}
                    {formatPrice(Number(result.valorTotal)) || 'Não informado'}
                  </p>
                </CardContent>
              </Card>
            ))}
          </>
        )}
      </Paper>
    </GridContainer>
  )
}

const data = [
  {key: '0', value: 'Hoje'},
  {key: '7', value: 'Últimos 7 dias'},
  {key: '15', value: 'Ultimos 15 dias'},
  {key: '30', value: 'Últimos 30 dias'}
]
