import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Conta {
  nome: string
  valor: number
}

interface Relatorio {
  contas: Conta[]
  total: number
}

interface ApiResponse {
  atrasadas: Relatorio
  hoje: Relatorio
  total: Relatorio
}

export const AppContasReceber: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)

  const [products, setProducts] = useState<ApiResponse | null>(null)
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    setLoading(true)
    socket.emit('filtroContasReceberApp', {
      message: {
        room: token,
        filial: filiais
      }
    })
    socket.on('contasReceberDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}

        {!loading &&
          products?.atrasadas?.total === 0 &&
          products?.hoje?.total === 0 &&
          products?.total?.total === 0 && (
            <div className={classes.root}>
              <p>Nenhum dado encontrado!</p>
            </div>
          )}
        {products &&
          Object.entries(products).map(([key, value]) => (
            <Card key={key} className={classes.card}>
              <CardContent>
                <h2 className={classes.textLeft}>{key.toLocaleUpperCase()}</h2>
                <div>
                  {value.contas.map((conta: Conta, index: number) => (
                    <div key={index}>
                      <p className={classes.textLeft}>
                        {conta.nome}:{' '}
                        {formatPrice(Number(conta.valor)) || 'Não informado'}
                      </p>
                    </div>
                  ))}
                </div>
                <p className={classes.textLeft}>
                  Total: {formatPrice(Number(value.total)) || 'Não informado'}
                </p>
              </CardContent>
            </Card>
          ))}
      </Paper>
    </GridContainer>
  )
}
