import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IRelatorioBot} from 'types/IRelatorioBot'

import {apiErroHandle} from 'helpers/erro'

export const RelatorioBot: React.FC = () => {
  /**
   * Hooks
   */
  const {idRelatorioBot} = useParams<{idRelatorioBot: string}>()
  const {push} = useHistory()
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()

  /**
   * Consts
   */
  const RELATORIO_BOT = {} as IRelatorioBot

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [relatorioBot, setRelatorioBot] = useState(RELATORIO_BOT)

  /**
   * Memos
   */
  const incluirRegistro = useMemo(() => !Boolean(Number(idRelatorioBot)), [
    idRelatorioBot
  ])

  /**
   * Callbacks
   */
  const carregarRelatorioBot = useCallback(async () => {
    if (incluirRegistro) return

    const {data} = await api.get(`/relatorioBot/${idRelatorioBot}`)

    setRelatorioBot(data)
  }, [idRelatorioBot, incluirRegistro])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarRelatorioBot()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarRelatorioBot, error])

  const add = useCallback(async () => {
    await api.post('/relatorioBot', relatorioBot)
  }, [relatorioBot])

  const edit = useCallback(async () => {
    if (incluirRegistro) return

    await api.put(`/relatorioBot/${idRelatorioBot}`, relatorioBot)
  }, [idRelatorioBot, incluirRegistro, relatorioBot])

  /**
   * Handles
   */
  const handleCancelar = useCallback(() => {
    push('/relatorioBot')
  }, [push])

  const handleConfirmar = useCallback(async () => {
    try {
      setLoading(true)

      if (incluirRegistro) {
        await add()
      } else {
        await edit()
      }

      push('/relatorioBot')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [add, edit, error, incluirRegistro, push])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo(`Relatório do bot ${incluirRegistro ? '' : idRelatorioBot}`)
  }, [alterarTitulo, idRelatorioBot, incluirRegistro])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleConfirmar}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={12}>
          <TextField label="Código" disabled value={relatorioBot.id} />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TextField
            label="Nome"
            value={relatorioBot.nome}
            onChange={(e) =>
              setRelatorioBot({...relatorioBot, nome: e.target.value})
            }
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TextField
            label="Valor"
            value={relatorioBot.valor}
            onChange={(e) =>
              setRelatorioBot({...relatorioBot, valor: e.target.value})
            }
          />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
