import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#FFF'
  },
  paper: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#FFF',
    marginTop: -15
  },
  touchable: {
    flexDirection: 'row',
    width: '80%',
    height: 65,
    backgroundColor: '#224aea',
    fontSize: 13,
    marginBottom: 10,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 20,
    alignSelf: 'center',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  icon: {
    marginRight: 10,
    fontSize: '27px'
  }
}))

export default useStyles;
