import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import {addDays} from 'date-fns'
import React, {useState, useEffect} from 'react'
import {Chart} from 'react-google-charts'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

const initialDate = new Date()
initialDate.setHours(0, 0, 0, 0)

const initialDateFim = new Date()
initialDateFim.setHours(23, 59, 59, 999)

export const AppVendaPeriodo: React.FC = () => {
  const socket = getSocket()
  const classes = useStyles()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)
  const [dados, setDados] = useState<any>(null)
  const [chartColors, setChartColors] = useState<string[]>([])
  const [tableColors, setTableColors] = useState<string[]>([])
  const [total, setTotal] = useState<number | null>(null)
  const [ticketMedio, setTicketMedio] = useState<number | null>(null)
  const [devolucoes, setDevolucoes] = useState<number | null>(null)
  const [selectedDate, setSelectedDate] = useState(
    initialDate.toISOString().split('T')[0]
  )
  const [selectedDateFim, setSelectedDateFim] = useState(
    initialDateFim.toISOString().split('T')[0]
  )
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async (
    selectedValue: string,
    selectedValueFim: string
  ) => {
    if (!token) return
    setLoading(true)
    socket.emit('filtroVendaPeriodoApp', {
      message: {
        room: token,
        filial: filiais,
        dataIni: selectedValue,
        dataFim: selectedValueFim
      }
    })

    socket.on('vendaPeriodoDadosApp', (data: any) => {
      try {
        const totalValue = data?.dados?.total
        const ticketMedioValue = data?.dados?.ticketMedio
        const devolucoesValue = data?.dados?.devolucoesRealizadas
        const margemLucroValue = data?.dados?.margemLucro
        setDados(data?.dados)
        setTotal(totalValue)
        setTicketMedio(ticketMedioValue)
        setDevolucoes(devolucoesValue)
        const newChartColors = data.dados.formasDePagamento.map(() =>
          getRandomColor()
        )
        setChartColors(newChartColors)
        setTableColors(newChartColors)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts(selectedDate, selectedDateFim)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedDate, selectedDateFim])

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value)
  }

  const handleDateChangeFim = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (new Date(event.target.value) < new Date(selectedDate)) {
      setError('A data final não pode ser menor que a data inicial.')
    } else {
      setError(null)
      setSelectedDateFim(event.target.value)
    }
  }

  const chartData = [['Descrição', 'Valor', {role: 'style'}]]
  if (dados && dados.formasDePagamento) {
    dados.formasDePagamento.forEach((item: any, index: number) => {
      chartData.push([item.nome, item.valor, chartColors[index]])
    })
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          id="date"
          label="Início"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="date"
          label="Fim"
          type="date"
          value={selectedDateFim}
          onChange={handleDateChangeFim}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          error={!!error}
          helperText={error}
        />
        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : !dados || dados.formasDePagamento.length === 0 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          <>
            <Chart
              chartType="PieChart"
              data={chartData}
              options={{
                title: '',
                legend: 'none',
                backgroundColor: 'transparent',
                colors: chartColors
              }}
              width={'100%'}
              height={'300px'}
            />
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell className={classes.rowCell} align="left">
                      Descrição
                    </TableCell>
                    <TableCell className={classes.rowCell} align="left">
                      Valor
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dados.formasDePagamento.map((item: any, index: number) => {
                    const rowStyle = {
                      backgroundColor: tableColors[index]
                    }
                    return (
                      <TableRow key={item.nome}>
                        <TableCell
                          align="left"
                          style={rowStyle}
                          className={classes.legend}
                        ></TableCell>
                        <TableCell className={classes.rowCell} align="left">
                          {item.nome}
                        </TableCell>
                        <TableCell className={classes.rowCell} align="left">
                          {formatPrice(Number(item.valor))}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Card className={classes.card}>
              <CardContent>
                <p className={classes.textLeft}>
                  Total: {total !== null ? formatPrice(total) : 'N/A'}
                </p>
                <p className={classes.textLeft}>
                  Ticket Médio:{' '}
                  {ticketMedio !== null ? formatPrice(ticketMedio) : 'N/A'}
                </p>
                <p className={classes.textLeft}>
                  Devoluções: {devolucoes !== null ? devolucoes : 'N/A'}
                </p>
              </CardContent>
            </Card>
          </>
        )}
      </Paper>
    </GridContainer>
  )
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
