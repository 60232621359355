import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {addDays} from 'date-fns'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

export const AppProdutosVencer: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>({})
  const [socketConnected, setSocketConnected] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split('T')[0]
  )
  const [selectedDateFim, setSelectedDateFim] = useState(
    addDays(new Date(), 1).toISOString().split('T')[0]
  )
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    // if (filiais) return
    setLoading(true)
    socket.emit('produtosVencerApp', {
      message: {
        room: token,
        dataInicio: selectedDate,
        dataFim: selectedDateFim
      }
    })
    socket.on('produtosVencerDadosApp', (data: any) => {
      try {
        const dados = data?.dados[0]
        setData(dados)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value)
  }

  const handleDateChangeFim = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (new Date(event.target.value) < new Date(selectedDate)) {
      setError('A data final não pode ser menor que a data inicial.')
    } else {
      setError(null)
      setSelectedDateFim(event.target.value)
      fetchProducts()
    }
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedDate, selectedDateFim])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          id="date"
          label="Início"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="date"
          label="Fim"
          type="date"
          value={selectedDateFim}
          onChange={handleDateChangeFim}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          error={!!error}
          helperText={error}
        />
        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : !loading && data && Object.keys(data).length === 0 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          data?.produtos?.map((result, index) => (
            <Card key={index} className={classes.card}>
              <CardContent>
                <h3>{result?.nome || 'Sem informação.'}</h3>
                <p className={classes.p}>
                  Ean: {result?.ean || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Código: {result?.codigo || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Vencimento: {result?.vencimento || 'Sem informação.'}
                </p>
              </CardContent>
            </Card>
          ))
        )}
      </Paper>
    </GridContainer>
  )
}
