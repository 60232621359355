import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect, useMemo} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../components/DrawerApp/index'
import useStyles from './style'

interface Product {
  id: string
  nome: string
  preco: string
  ean: string
  codigo: string
  ultimavenda: string
  ncm: string
  ultimopreco: string
}

export const AppProduto: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const token = localStorage.getItem(TOKEN_APP_ID)
  const idUsuario = localStorage.getItem(ID_APP)

  const [socketConnected, setSocketConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [produtos, setProdutos] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [produto, setProduto] = useState<string>('')

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = useMemo(
    () => async () => {
      socket.emit('filtroProdutosApp', {
        message: {
          room: token,
          produto: produto
        }
      })

      socket.on('produtosDadosApp', (data: any) => {
        try {
          const dados = data?.dados
          setData(dados)
          setProdutos(dados?.length > 0)
        } catch (error) {
          console.error('Erro ao processar dados recebidos:', error)
        } finally {
          setLoading(false)
        }
      })
    },
    [socket, token, produto]
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.preventDefault()
    if (produto.trim() !== '') {
      fetchProducts()
    }
  }

  const handleChangeProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduto(event.target.value)
  }

  if (!idUsuario || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Buscar produto..."
            margin="normal"
            variant="outlined"
            onChange={handleChangeProduto}
            className={classes.search2}
            InputProps={{
              type: 'search',
              value: produto,
              color: 'primary'
            }}
          />
        </form>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {!loading && !produtos && produto && !data && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}
        {produtos && data && !loading && (
          <div>
            {data.map((item: Product, index: number) => (
              <Link
                className={classes.card}
                key={item.codigo}
                to={{
                  pathname: '/app/produtodetalhes',
                  state: {productId: item.codigo}
                }}
              >
                <Card key={index} className={classes.card}>
                  <CardContent>
                    <h3 className={classes.p}>{item.nome}</h3>
                    <p className={classes.p}>Código: {item.codigo}</p>
                    <p className={classes.p}>Ean: {item.ean}</p>
                  </CardContent>
                </Card>
              </Link>
            ))}
          </div>
        )}
      </Paper>
    </GridContainer>
  )
}
