import {TextFieldProps} from '@material-ui/core'
import React from 'react'
import {NumericFormat} from 'react-number-format'

import {TextField} from 'components/TextField'

function NumericInput(props: any) {
  const {inputRef, onChange, ...other} = props

  return (
    //@ts-ignore
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) =>
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }
      decimalScale={2}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      inputMode="numeric"
      prefix="R$ "
    />
  )
}

export const PriceTextField: React.FC<TextFieldProps> = ({...props}) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumericInput,
        ...props.InputProps
      }}
    />
  )
}
