import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import React, {useState, useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import useStyles from './style'

interface Branch {
  apelido: string
  cnpj: string
  token: string
}

export const AppEmpresa: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP = '@TOKEN_APP'
  const CNPJ_APP = '@CNPJ_APP'
  const TOKENS_APP_EXTRA = '@TOKENS_APP_EXTRA'
  const token = localStorage.getItem(TOKEN_APP)
  const cnpj = localStorage.getItem(CNPJ_APP)

  const [socketConnected, setSocketConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clickedToken, setClickedToken] = useState<string | null>(null)
  const [branches, setBranches] = useState<Branch[]>([])

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
      }
    }
  }, [socket, token, socketConnected])

  useEffect(() => {
    const tokens_extra = localStorage.getItem(TOKENS_APP_EXTRA)

    if (tokens_extra) {
      const parsedTokens: Branch[] = JSON.parse(tokens_extra)
      setBranches(parsedTokens)
    }
  }, [])

  const handleCardClick = (tokenCard: string, cnpjCard: string) => {
    setClickedToken(token)
    localStorage.setItem(TOKEN_APP, tokenCard)
    localStorage.setItem(CNPJ_APP, cnpjCard)
  }

  if (!cnpj || !token) {
    return <Redirect to="/app" />
  }

  if (Number(branches.length) == 1) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <Paper className={classes.paper}>
        {branches && !loading && (
          <div>
            <Typography variant="body1" className={classes.label}>
              <b>SELECIONE A EMPRESA:</b>
            </Typography>
            {branches.map((item: Branch, index: number) => (
              <div
                key={item.cnpj}
                onClick={() => handleCardClick(item.token, item.cnpj)}
              >
                <Link
                  className={classes.card}
                  to={{
                    pathname: '/app/login'
                  }}
                >
                  <Card className={classes.card}>
                    <CardContent>
                      <h3 className={classes.p}>{item.apelido}</h3>
                      <br />
                      <p className={classes.p}>
                        <b>CNPJ:</b> {item.cnpj}
                      </p>
                      <p className={classes.p}>
                        <b>Token:</b> {item.token}
                      </p>
                    </CardContent>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
        )}
      </Paper>
    </GridContainer>
  )
}
