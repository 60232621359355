import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BarChartIcon from '@material-ui/icons/BarChart'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HomeIcon from '@material-ui/icons/Home'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import MenuIcon from '@material-ui/icons/Menu'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import StorageIcon from '@material-ui/icons/Storage'
import clsx from 'clsx'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {getSocket, disconnectSocket} from 'socket'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: '#224aea',
      fontFamily: 'Poppins, sans-serif'
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2),
      fontFamily: 'Poppins, sans-serif'
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      fontFamily: 'Poppins, sans-serif'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      fontFamily: 'Poppins, sans-serif'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth,
      fontFamily: 'Poppins, sans-serif'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      fontFamily: 'Poppins, sans-serif'
    },
    listItemText: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '15px'
    },
    dialogTitle: {
      fontFamily: 'Poppins, sans-serif'
    },
    dialogContent: {
      fontFamily: 'Poppins, sans-serif'
    },
    dialogContentText: {
      fontFamily: 'Poppins, sans-serif'
    },
    dialogActions: {
      fontFamily: 'Poppins, sans-serif'
    }
  })
)

export const DrawerApp: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const socket = getSocket()
  const TOKEN_APP = '@TOKEN_APP'
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ADMIN_APP = '@ADMIN_APP'
  const MASTER_APP = '@MASTER_APP'
  const ID_APP = '@ID_APP'
  const FILIAL_APP = '@FILIAL_APP'
  const CNPJ_APP = '@CNPJ_APP'
  const TOKENS_APP_EXTRA = '@TOKENS_APP_EXTRA'
  const token = localStorage.getItem(TOKEN_APP)
  const tokenId = localStorage.getItem(TOKEN_APP_ID)
  const [open, setOpen] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openDialogLogin, setOpenDialogLogin] = React.useState(false)
  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  const handleLogout = () => {
    if (socket) {
      disconnectSocket(tokenId)
      disconnectSocket(token)
    }
    localStorage.removeItem(MASTER_APP)
    localStorage.removeItem(ADMIN_APP)
    localStorage.removeItem(ID_APP)
    localStorage.removeItem(TOKEN_APP_ID)
    localStorage.removeItem(FILIAL_APP)
    handleButtonClick('/app/empresa')
  }

  const handleToken = () => {
    if (socket) {
      disconnectSocket(tokenId)
      disconnectSocket(token)
    }
    localStorage.removeItem(MASTER_APP)
    localStorage.removeItem(ADMIN_APP)
    localStorage.removeItem(ID_APP)
    localStorage.removeItem(TOKEN_APP_ID)
    localStorage.removeItem(TOKEN_APP)
    localStorage.removeItem(FILIAL_APP)
    localStorage.removeItem(CNPJ_APP)
    localStorage.removeItem(TOKENS_APP_EXTRA)
    handleButtonClick('/app')
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClickOpenLogin = () => {
    setOpenDialogLogin(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleCloseLogin = () => {
    setOpenDialogLogin(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => handleButtonClick('/app/home')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Página Inicial"
            />
          </ListItem>

          <ListItem button onClick={() => handleButtonClick('/app/vendas')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Vendas"
            />
          </ListItem>

          <ListItem button onClick={() => handleButtonClick('/app/financeiro')}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Financeiro"
            />
          </ListItem>

          <ListItem button onClick={() => handleButtonClick('/app/opprodutos')}>
            <ListItemIcon>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Produtos"
            />
          </ListItem>

          <ListItem button onClick={() => handleButtonClick('/app/outros')}>
            <ListItemIcon>
              <StorageIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Outros"
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => handleButtonClick('/app/filial')}>
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Filiais"
            />
          </ListItem>

          <ListItem button onClick={() => handleButtonClick('/app/config')}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Nova Empresa"
            />
          </ListItem>

          <ListItem button onClick={() => handleClickOpenLogin()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Sair"
            />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleClickOpen()}>
            <ListItemIcon>
              <RotateLeftIcon />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary="Resetar"
            />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      {/* Diálogo de substituição de token */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="token-dialog-title"
        aria-describedby="token-dialog-description"
      >
        <DialogTitle id="token-dialog-title" className={classes.dialogTitle}>
          Tem certeza que deseja resetar?
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            id="token-dialog-description"
            className={classes.dialogContentText}
          >
            Toda configuração atual será perdida!
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleToken} color="primary">
            Resetar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Login */}
      <Dialog
        open={openDialogLogin}
        onClose={handleCloseLogin}
        aria-labelledby="login-dialog-title"
        aria-describedby="login-dialog-description"
      >
        <DialogTitle id="login-dialog-title" className={classes.dialogTitle}>
          Tem certeza que deseja sair?
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            id="login-dialog-description"
            className={classes.dialogContentText}
          >
            Você será redirecionado para a página de login!
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseLogin} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleLogout} color="primary">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
