import {Grid, GridProps} from '@material-ui/core'
import React from 'react'

export const GridItem: React.FC<GridProps> = ({children, ...props}) => {
  return (
    <Grid item {...props}>
      {children}
    </Grid>
  )
}
