import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

interface ConfirmacaoProps {
  titulo: string
  conteudo: string
  acao: Function
}

export interface DialogoContextProps {
  confirmacao(props: ConfirmacaoProps): void
}

const DialogoContext = createContext({} as DialogoContextProps)

export const DialogoProvider: React.FC = ({children}) => {
  /**
   * States
   */
  const [open, setOpen] = useState(false)
  const [titulo, setTitulo] = useState('')
  const [conteudo, setConteudo] = useState('')
  const [handle, setHandle] = useState<Function>(() => {})

  /**
   * Callback
   */
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleClick = useCallback(() => {
    handle()
    handleClose()
  }, [handle, handleClose])

  const confirmacao = useCallback(
    (props: ConfirmacaoProps) => {
      setTitulo(props.titulo)
      setConteudo(props.conteudo)
      setHandle(() => props.acao)
      handleOpen()
    },
    [handleOpen]
  )

  useEffect(() => {
    if (!open) {
      setHandle(() => {})
    }
  }, [open])

  /**
   * Returns
   */
  return (
    <>
      <DialogoContext.Provider value={{confirmacao}}>
        {children}
      </DialogoContext.Provider>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>{conteudo}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const useDialogo = () => {
  const context = useContext(DialogoContext)

  return context
}
