import EqualizerIcon from '@material-ui/icons/Equalizer'
import React, {useState, useEffect} from 'react'
import {useHistory, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Loading} from 'components/Loading'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../components/DrawerApp/index'
import useStyles from './style'

export const AppOpOutros: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const token = localStorage.getItem(TOKEN_APP_ID)
  const id = localStorage.getItem(ID_APP)
  const [rankingPromocao, setRankingPromocao] = React.useState<boolean>(false)
  const [divergenciaNota, setDivergenciaNota] = React.useState<boolean>(false)
  const [sangriaSuprimento, setSangriaSuprimento] =
    React.useState<boolean>(false)
  const [cancelamentoVenda, setCancelamentoVenda] =
    React.useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  const fetchRelatorios = async () => {
    if (!socket || !token || !id) return
    socket.emit('filtroRelatoriosApp', {
      message: {room: token, id: id}
    })

    socket.on('relatoriosDadosApp', (data: any) => {
      try {
        const dados = data?.dados

        if (data) {
          const filterRankingPromocao = dados.filter(
            (item: any) => item?.valorrelatorio === 'ranking_promocao_diaria'
          )
          const filterDivergenciaNota = dados.filter(
            (item: any) => item?.valorrelatorio === 'divergencias_notas_fiscais'
          )
          const filterCancelamentoVenda = dados.filter(
            (item: any) => item?.valorrelatorio === 'relatorio_cancelamentos'
          )
          const filterSangriaSuprimento = dados.filter(
            (item: any) => item?.valorrelatorio === 'sangria_suprimento'
          )

          if (filterRankingPromocao && filterRankingPromocao.length > 0) {
            setRankingPromocao(true)
          }
          if (filterDivergenciaNota && filterDivergenciaNota.length > 0) {
            setDivergenciaNota(true)
          }
          if (filterCancelamentoVenda && filterCancelamentoVenda.length > 0) {
            setCancelamentoVenda(true)
          }
          if (filterSangriaSuprimento && filterSangriaSuprimento.length > 0) {
            setSangriaSuprimento(true)
          }
        }
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchRelatorios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  if (loading) {
    return <Loading />
  }

  if (!id || !token) {
    return <Redirect to="/app" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <GridContainer>
          {rankingPromocao && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/listapromocoes')}
              >
                <EqualizerIcon className={classes.icon} />
                Lista de Promoções
              </Button>
            </GridItem>
          )}
          {divergenciaNota && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/divergencia')}
              >
                <EqualizerIcon className={classes.icon} />
                Divergências NF
              </Button>
            </GridItem>
          )}
          {cancelamentoVenda && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendascanceladas')}
              >
                <EqualizerIcon className={classes.icon} />
                Vendas Canceladas
              </Button>
            </GridItem>
          )}
          {sangriaSuprimento && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/sangrias')}
              >
                <EqualizerIcon className={classes.icon} />
                Sangrias e Suprimentos
              </Button>
            </GridItem>
          )}
        </GridContainer>
      </Paper>
    </GridContainer>
  )
}
