import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#FFF',
    fontFamily: 'Poppins, sans-serif'
  },
  container2: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#FFF',
    fontFamily: 'Poppins, sans-serif'
  },
  paper: {
    textAlign: 'center',
    margin: 20,
    backgroundColor: '#FFF',
    fontFamily: 'Poppins, sans-serif'
  },
  logo: {
    width: '75%',
    height: 'auto',
    marginBottom: 50
  },
  touchable: {
    marginTop: 30,
    backgroundColor: '#224aea',
    borderRadius: 20,
    height: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  touchableBack: {
    marginTop: 10,
    backgroundColor: '#224aea',
    borderRadius: 20,
    height: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif'
  },
  label: {
    textAlign: 'center',
    fontSize: '17px',
    marginTop: 8,
    color: 'red',
    fontFamily: 'Poppins, sans-serif'
  },
  textField: {
    '& input': {
      fontFamily: 'Poppins, sans-serif'
    },
    '& input::placeholder': {
      fontFamily: 'Poppins, sans-serif'
    }
  }
}))

export default useStyles
