import React, {useEffect} from 'react'

import {useTitulo} from 'contexts/titulo'

export const Home: React.FC = () => {
  /**
   * Hooks
   */
  const {alterarTitulo} = useTitulo()

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Home')
  }, [alterarTitulo])

  /**
   * Returns
   */
  return <></>
}
