import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Product {
  valorvendanaocuncluida: string
  tipo: number
  relatorio: string
  cnpj: string
  filial: string
  operacao: string
  serie: string
  valortotal: string
  nomeusuario: string
  supervisor: string
  motivocancelamento: string
  datahoracancelamento: string
}

const initialDate = new Date()
initialDate.setHours(0, 0, 0, 0)

export const AppVendasCanceladas: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)

  const [products, setProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    initialDate.toISOString().split('T')[0]
  )

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async (selectedValue: string) => {
    setLoading(true)
    socket.emit('filtroVendaCanceladaApp', {
      message: {
        room: token,
        data: selectedValue
      }
    })
    socket.on('vendaCanceladaDadosApp', (data: any) => {
      try {
        const dados = data?.dados || []
        const filteredData = dados.filter(
          (item: Product) => item.nomeusuario !== item.supervisor
        )
        setProducts(filteredData)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  function formatData(dataOriginal: string): string {
    const dataHoraObj: Date = new Date(dataOriginal)

    const dia: number = dataHoraObj.getUTCDate()
    const mes: number = dataHoraObj.getUTCMonth() + 1
    const ano: number = dataHoraObj.getUTCFullYear()

    const dataFormatada: string = `${dia < 10 ? '0' : ''}${dia}-${
      mes < 10 ? '0' : ''
    }${mes}-${ano}`

    return dataFormatada
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value)
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts(selectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedDate])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          id="date"
          label="Selecione o dia"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />

        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}

        {!loading && products.length === 0 && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}

        {products.map((result, index) => (
          <Card key={index} className={classes.card}>
            <CardContent>
              <h3 className={classes.textLeft}>Venda cancelada</h3>
              <br></br>
              <p className={classes.textLeft}>
                Valor:{' '}
                {formatPrice(Number(result.valorvendanaocuncluida)) ||
                  'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Tipo: {result.tipo || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Relatório: {result.relatorio || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                CNPJ: {result.cnpj || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Operação: {result.operacao || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Filial: {result.filial || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Série: {result.serie || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Valor Total:{' '}
                {formatPrice(Number(result.valortotal)) || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Usuário: {result.nomeusuario || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Supervisor: {result.supervisor || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Motivo: {result.motivocancelamento || 'Não informado'}
              </p>
              <p className={classes.textLeft}>
                Data:
                {formatData(result.datahoracancelamento) || 'Não Informado'}
              </p>
            </CardContent>
          </Card>
        ))}
      </Paper>
    </GridContainer>
  )
}
