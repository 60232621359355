import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, {useState, useEffect} from 'react'
import {Chart} from 'react-google-charts'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

export const AppContasRecebidas: React.FC = () => {
  const socket = getSocket()
  const classes = useStyles()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)
  const [dados, setDados] = useState<any>({hoje: 0, acumuladoMes: 0})
  const [selectedDate, setSelectedDate] = useState('2024-01-05')
  const [chartColors, setChartColors] = useState<string[]>([])
  const [tableColors, setTableColors] = useState<string[]>([])

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    if (!token) return
    setLoading(true)
    socket.emit('filtroContasRecebidasApp', {
      message: {
        room: token,
        filial: filiais
      }
    })

    socket.on('contasRecebidasDadosApp', (data: any) => {
      try {
        setDados(data?.dados)
        const newChartColors = Object.keys(dados).map(() => getRandomColor())
        setChartColors(newChartColors)
        setTableColors(newChartColors)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedDate])

  const chartData: any = [['Descrição', 'Valor', {role: 'style'}]]

  if (dados) {
    chartData.push(['Hoje', Number(dados.hoje), getRandomColor()])
    chartData.push([
      'Acumulado do Mês',
      Number(dados.acumuladoMes),
      {role: 'style'}
    ])
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {!loading && dados.hoje === 0 && dados.acumuladoMes === 0 && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}
        {dados && dados.hoje > 0 && dados.acumuladoMes > 0 ? (
          <>
            <Chart
              chartType="PieChart"
              data={chartData}
              options={{
                title: '',
                legend: 'none',
                backgroundColor: 'transparent',
                colors: chartColors
              }}
              width={'100%'}
              height={'300px'}
            />
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell className={classes.rowCell} align="left">
                      Descrição
                    </TableCell>
                    <TableCell className={classes.rowCell} align="left">
                      Valor
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dados ? (
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.legend}
                        style={{backgroundColor: tableColors[0]}}
                      ></TableCell>
                      <TableCell className={classes.rowCell} align="left">
                        Hoje
                      </TableCell>
                      <TableCell className={classes.rowCell} align="left">
                        {formatPrice(Number(dados.hoje)) || 'Não informado'}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {dados ? (
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.legend}
                        style={{backgroundColor: tableColors[1]}}
                      ></TableCell>
                      <TableCell className={classes.rowCell} align="left">
                        Acumulado do Mês
                      </TableCell>
                      <TableCell className={classes.rowCell} align="left">
                        {formatPrice(Number(dados.acumuladoMes)) ||
                          'Não informado'}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : null}
      </Paper>
    </GridContainer>
  )
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
