import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, {useState, useEffect} from 'react'
import {useLocation, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../components/DrawerApp/index'
import useStyles from './style'

interface LocationState {
  productId: string
}

interface ProductDetail {
  saldos: {
    codigoFilial: string
    quantidade: number
  }[]
  nomeProduto: string
  codigoProduto: string
  eanProduto: string
  fornecedor: string
  quantidadeUltimaCompra: number
  dataUltimaCompra: string
  precoUltimaCompra: number
  custo: number
  preco: number
  margem: number
  markup: number
  vendas7Dias: number
  vendas14Dias: number
  vendas30Dias: number
}

export const AppProdutoDetalhes: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const location = useLocation<LocationState>()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)
  const id = location.state && location.state.productId
  const [loading, setLoading] = useState(false)
  const [produtos, setProdutos] = useState<boolean>(false)

  const [products, setProducts] = useState<ProductDetail>()
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    // if (filiais) return
    setLoading(true)
    socket.emit('filtroProdutoDetalhesApp', {
      message: {
        room: token,
        filial: filiais,
        produto: id
      }
    })
    socket.on('produtoDetalhesDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados)
        if (dados) {
          setProdutos(true)
        } else {
          setProdutos(false)
        }
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  function formatData(dataOriginal: string): string {
    const dataHoraObj: Date = new Date(dataOriginal)

    const dia: number = dataHoraObj.getUTCDate()
    const mes: number = dataHoraObj.getUTCMonth() + 1
    const ano: number = dataHoraObj.getUTCFullYear()

    const dataFormatada: string = `${dia < 10 ? '0' : ''}${dia}-${
      mes < 10 ? '0' : ''
    }${mes}-${ano}`

    return dataFormatada
  }

  if (!token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {!loading && produtos && (
          <Card className={classes.card}>
            <CardContent>
              <h3 className={classes.p}>
                {products?.nomeProduto || 'Não informado'}
              </h3>
              <br />
              {products?.fornecedor !== undefined && (
                <p className={classes.p}>Fornecedor: {products.fornecedor}</p>
              )}
              {products?.preco !== undefined && (
                <p className={classes.p}>
                  Preço: {formatPrice(Number(products.preco))}
                </p>
              )}
              {products?.custo !== undefined && products.custo >= 0 && (
                <p className={classes.p}>
                  Custo: {formatPrice(Number(products.custo))}
                </p>
              )}
              {products?.margem !== undefined && (
                <p className={classes.p}>
                  Margem: {products.margem.toFixed(2)}%
                </p>
              )}
              {products?.markup !== undefined && (
                <p className={classes.p}>
                  Markup: {products.markup.toFixed(2)}%
                </p>
              )}
              {products?.precoUltimaCompra !== undefined &&
                products.precoUltimaCompra > 0 && (
                  <p className={classes.p}>
                    Preço última compra:{' '}
                    {formatPrice(Number(products.precoUltimaCompra))}
                  </p>
                )}
              {products?.quantidadeUltimaCompra !== undefined &&
                products.quantidadeUltimaCompra > 0 && (
                  <p className={classes.p}>
                    Quantidade última compra:{' '}
                    {Number(products.quantidadeUltimaCompra)}
                  </p>
                )}
              {products?.dataUltimaCompra !== undefined && (
                <p className={classes.p}>
                  Data última compra:{' '}
                  {formatData(String(products.dataUltimaCompra.split('T')[0]))}
                </p>
              )}
              {products?.eanProduto !== undefined && (
                <p className={classes.p}>Ean: {products.eanProduto}</p>
              )}
              {products?.codigoProduto !== undefined && (
                <p className={classes.p}>Código: {products.codigoProduto}</p>
              )}
              {products?.vendas7Dias !== undefined && (
                <p className={classes.p}>
                  Vendas últimos 7 dias: {products.vendas7Dias}
                </p>
              )}
              {products?.vendas14Dias !== undefined && (
                <p className={classes.p}>
                  Vendas últimos 14 dias: {products.vendas14Dias}
                </p>
              )}
              {products?.vendas30Dias !== undefined && (
                <p className={classes.p}>
                  Vendas últimos 30 dias: {products.vendas30Dias}
                </p>
              )}
              {products?.saldos?.[0]?.quantidade !== undefined && (
                <p className={classes.p}>
                  Estoque: {products.saldos[0].quantidade}
                </p>
              )}
            </CardContent>
          </Card>
        )}
      </Paper>
    </GridContainer>
  )
}
