import {format, parseISO, isValid} from 'date-fns'
import {ptBR} from 'date-fns/locale'

export const {format: formatPrice} = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

export const {format: formatNumber} = new Intl.NumberFormat('pt-BR', {
  style: 'decimal'
})

export const formatDataString = 'dd/MM/yyyy'
export const formatDataStringISO = 'yyyy-MM-dd'

export const formatData = (data: any) => {
  if (!data) return ''
  if (data instanceof Date) return format(data, formatDataString)
  return format(parseISO(data), formatDataString)
}

export const formatDataISO = (data: any) => {
  if (!data) return ''
  if (isValid(data)) return format(data, formatDataStringISO)
  return format(parseISO(data), formatDataString)
}

export const formatPercent = (value: any, precision: number) =>
  parseFloat(`${value}`).toFixed(precision)

export const formatDataHora = (data: any) => {
  if (!data) return ''
  if (data instanceof Date) return format(data, 'dd/MM/yyyy HH:mm:ss')
  return format(parseISO(data), 'dd/MM/yyyy HH:mm:ss')
}

export function formatarDataSemFuso(data: Date | number) {
  const dt = new Date(data)

  const dtsemFuso = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

  return formatData(dtsemFuso)
}
