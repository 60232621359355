import {Paper as MuiPaper, PaperProps} from '@material-ui/core'
import React from 'react'

export const Paper: React.FC<PaperProps> = ({children, ...props}) => {
  return (
    <MuiPaper elevation={0} {...props}>
      {children}
    </MuiPaper>
  )
}
