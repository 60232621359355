import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#fff',
    width: '100%'
  },
  paper: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: -35,
    backgroundColor: '#fff',
    width: '100%'
  },
  search: {
    width: '100%'
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    width: '100%'
  },
  card: {
    minWidth: 200,
    minHeight: 50,
    alignItems: 'center',
    borderRadius: 15,
    width: '100%',
    backgroundColor: '#f5f5f5',
    border: 15,
    margin: 8,
    fontFamily: 'Poppins, sans-serif',
  },
  busca: {
    marginLeft: 15,
    marginRight: 15
  },
  bkcard: {
    backgroundColor: '#f5f5f5',
    fontSize: 12
  },
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 240
  }
}))

export default useStyles;
