import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#FFF',
    fontFamily: 'Poppins, sans-serif'
  },
  paper: {
    textAlign: 'center',
    height: '100%',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    fontFamily: 'Poppins, sans-serif'
  },
  grid: {
    textAlign: 'center',
    height: '100%',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    fontFamily: 'Poppins, sans-serif'
  },
  logo: {
    width: '70%',
    height: 'auto',
    marginBottom: 50,
    marginTop: 33,
    marginRight: 3
  },
  touchable: {
    flexDirection: 'row',
    width: '80%',
    height: 70,
    backgroundColor: '#224aea',
    fontSize: 14,
    marginBottom: 15,
    marginTop: 15,
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 20,
    alignSelf: 'center',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  icon: {
    marginRight: 10,
    fontSize: '30px'
  }
}))

export default useStyles;
