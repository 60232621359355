import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Loading} from 'components/Loading'
import {Table} from 'components/Table'

import {useDialogo} from 'contexts/dialogo'
import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IUsuario} from 'types/IUsuario'

import {filterArray} from 'helpers/array'
import {apiErroHandle} from 'helpers/erro'

export const UsuarioPesquisa: React.FC = () => {
  /**
   * Hooks
   */
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()
  const {push} = useHistory()
  const {confirmacao} = useDialogo()

  /**
   * States
   */
  const [pesquisar, setPesquisar] = useState('')
  const [loading, setLoading] = useState(false)
  const [idSelecionado, setIdSelecionado] = useState<string | null>(null)

  const [usuarios, setUsuarios] = useState<IUsuario[]>([])
  const [usuariosFilter, setUsuariosFilter] = useState<IUsuario[]>([])

  /**
   * Callbacks
   */
  const carregarUsuarios = useCallback(async () => {
    const {data} = await api.get('/usuario')

    setUsuarios(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarUsuarios()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarUsuarios, error])

  const excluirUsuario = useCallback(async () => {
    if (!idSelecionado) return

    try {
      setLoading(true)

      await api.delete(`/usuario/${idSelecionado}`)

      await carregarUsuarios()
    } catch (err) {
      apiErroHandle(err)
    } finally {
      setLoading(false)
    }
  }, [carregarUsuarios, idSelecionado])

  /**
   * Handles
   */
  const handleAdicionar = useCallback(() => {
    push('/usuario/0')
  }, [push])

  const handleEditar = useCallback(() => {
    if (!idSelecionado) return

    push(`/usuario/${idSelecionado}`)
  }, [idSelecionado, push])

  const handleExcluir = useCallback(() => {
    if (!idSelecionado) return

    confirmacao({
      titulo: 'Atenção',
      conteudo: 'Deseja excluir o usuário?',
      acao: excluirUsuario
    })
  }, [confirmacao, excluirUsuario, idSelecionado])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Usuários')
  }, [alterarTitulo])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  useEffect(() => {
    setUsuariosFilter(filterArray(pesquisar, usuarios))
  }, [pesquisar, usuarios])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar
        inputValue={pesquisar}
        onInputChange={(e) => setPesquisar(e.target.value)}
        onSubmit={carregarDados}
      >
        <ActionBarIcon
          icon={<AddIcon />}
          tip="Adicionar"
          onClick={handleAdicionar}
        />
        <ActionBarIcon
          icon={<EditIcon />}
          tip="Alterar"
          onClick={handleEditar}
        />
        <ActionBarIcon
          icon={<DeleteIcon />}
          tip="Excluir"
          onClick={handleExcluir}
        />
      </ActionBar>

      <Table.Container>
        <Table.Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell width={150}>Código</Table.HeadCell>
              <Table.HeadCell>Nome</Table.HeadCell>
              <Table.HeadCell>Usuário</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {usuariosFilter.map((usuario) => (
              <Table.Row
                key={usuario.id}
                selected={usuario.id === idSelecionado}
                onClick={() => setIdSelecionado(usuario.id)}
                onDoubleClick={handleEditar}
              >
                <Table.Cell>{usuario.id}</Table.Cell>
                <Table.Cell>{usuario.nome}</Table.Cell>
                <Table.Cell>{usuario.nomeUsuario}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Table>
      </Table.Container>
    </>
  )
}
