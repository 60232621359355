import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {addDays} from 'date-fns'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface ApiResponse {
  cofins: {credito: number; debito: number; total: number}
  pis: {credito: number; debito: number; total: number}
  icms: {credito: number; debito: number; total: number}
  total: number
}

const initialDate = new Date()
initialDate.setHours(0, 0, 0, 0)

const initialDateFim = new Date()
initialDateFim.setHours(23, 59, 59, 999)

export const AppApuracaoImposto: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const FILIAL_APP = '@FILIAL_APP'
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)

  const [products, setProducts] = useState<ApiResponse | null>(null)
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    initialDate.toISOString().split('T')[0]
  )
  const [selectedDateFim, setSelectedDateFim] = useState(
    initialDateFim.toISOString().split('T')[0]
  )
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async (
    selectedValue: string,
    selectedValueFim: string
  ) => {
    setLoading(true)
    socket.emit('filtroApuracaoImpostoApp', {
      message: {
        room: token,
        filial: filiais,
        dataInicio: selectedValue,
        dataFim: selectedValueFim
      }
    })
    socket.on('apuracaoImpostoDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value)
  }

  const handleDateChangeFim = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (new Date(event.target.value) < new Date(selectedDate)) {
      setError('A data final não pode ser menor que a data inicial.')
    } else {
      setError(null)
      setSelectedDateFim(event.target.value)
    }
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts(selectedDate, selectedDateFim)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedDate, selectedDateFim])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          id="date"
          label="Início"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="date"
          label="Fim"
          type="date"
          value={selectedDateFim}
          onChange={handleDateChangeFim}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          error={!!error}
          helperText={error}
        />
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}

        {!loading &&
          products?.cofins?.total === 0 &&
          products?.pis?.total === 0 &&
          products?.icms?.total === 0 && (
            <div className={classes.root}>
              <p>Nenhum dado encontrado!</p>
            </div>
          )}
        <div className={classes.cardContainer}>
          {!loading && products?.cofins?.total !== 0 && (
            <Card className={classes.card}>
              <CardContent>
                <h2 className={classes.textLeft}>CONFINS</h2>
                <div>
                  <p className={classes.textLeft}>
                    Crédito:{' '}
                    {formatPrice(products?.cofins?.credito) || 'Não informado'}
                  </p>
                  <p className={classes.textLeft}>
                    Débito:{' '}
                    {formatPrice(products?.cofins?.debito) || 'Não informado'}
                  </p>
                  <p className={classes.textLeft}>
                    Total:{' '}
                    {formatPrice(products?.cofins?.total) || 'Não informado'}
                  </p>
                </div>
              </CardContent>
            </Card>
          )}
          {!loading && products?.pis?.total !== 0 && (
            <Card className={classes.card}>
              <CardContent>
                <h2 className={classes.textLeft}>PIS</h2>
                <div>
                  <p className={classes.textLeft}>
                    Crédito:{' '}
                    {formatPrice(products?.pis?.credito) || 'Não informado'}
                  </p>
                  <p className={classes.textLeft}>
                    Débito:{' '}
                    {formatPrice(products?.pis?.debito) || 'Não informado'}
                  </p>
                  <p className={classes.textLeft}>
                    Total:{' '}
                    {formatPrice(products?.pis?.total) || 'Não informado'}
                  </p>
                </div>
              </CardContent>
            </Card>
          )}
          {!loading && products?.icms?.total !== 0 && (
            <Card className={classes.card}>
              <CardContent>
                <h2 className={classes.textLeft}>ICMS</h2>
                <div>
                  <p className={classes.textLeft}>
                    Crédito:{' '}
                    {formatPrice(products?.icms?.credito) || 'Não informado'}
                  </p>
                  <p className={classes.textLeft}>
                    Débito:{' '}
                    {formatPrice(products?.icms?.debito) || 'Não informado'}
                  </p>
                  <p className={classes.textLeft}>
                    Total:{' '}
                    {formatPrice(products?.icms?.total) || 'Não informado'}
                  </p>
                </div>
              </CardContent>
            </Card>
          )}

          {!loading && products?.total !== 0 && (
            <Card className={classes.card}>
              <CardContent>
                <h2 className={classes.textLeft}>Total Geral</h2>
                <p className={classes.textLeft}>
                  Total: {formatPrice(products?.total) || 'Não informado'}
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </Paper>
    </GridContainer>
  )
}
