import {Snackbar} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

export type SeverityType = 'error' | 'warning' | 'info' | 'success'

export interface ToastContextProps {
  error(mensagem: string): void
  warning(mensagem: string): void
  info(mensagem: string): void
  success(mensagem: string): void
}

const ToastContext = createContext({} as ToastContextProps)

export const ToastProvider: React.FC = ({children}) => {
  /**
   * State
   */
  const [severity, setSeverity] = useState<SeverityType>('error')
  const [mensagem, setMensagem] = useState('')
  const [open, setOpen] = useState(false)

  /**
   * Callbacks
   */
  const error = useCallback((mensagem: string) => {
    setOpen(true)
    setSeverity('error')
    setMensagem(mensagem)
  }, [])

  const warning = useCallback((mensagem: string) => {
    setOpen(true)
    setSeverity('warning')
    setMensagem(mensagem)
  }, [])

  const info = useCallback((mensagem: string) => {
    setOpen(true)
    setSeverity('info')
    setMensagem(mensagem)
  }, [])

  const success = useCallback((mensagem: string) => {
    setOpen(true)
    setSeverity('success')
    setMensagem(mensagem)
  }, [])

  /**
   * Effects
   */
  useEffect(() => {
    const idTimeout = setTimeout(() => {
      setOpen(false)
    }, 5000)

    return () => {
      clearTimeout(idTimeout)
    }
  }, [open])

  /**
   * Returns
   */
  return (
    <ToastContext.Provider value={{error, info, success, warning}}>
      <Snackbar open={open}>
        <Alert
          elevation={6}
          variant="filled"
          severity={severity}
          onClose={() => setOpen(false)}
        >
          {mensagem}
        </Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)

  return context
}
