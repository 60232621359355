import CssBaseline from '@material-ui/core/CssBaseline'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import clsx from 'clsx'
import React, {useState, useEffect} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Loading} from 'components/Loading'
import {Paper} from 'components/Paper'
import {TextField} from 'components/TextField'

import logo from '../../../assets/logo.png'
import useStyles from './style'

const drawerWidth = 240

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: '#631763'
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }
  })
)

export const AppLogin: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const classes2 = useStyles2()
  const socket = getSocket()
  const TOKEN_APP = '@TOKEN_APP'
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ADMIN_APP = '@ADMIN_APP'
  const MASTER_APP = '@MASTER_APP'
  const ID_APP = '@ID_APP'
  const FILIAL_APP = '@FILIAL_APP'
  const CNPJ_APP = '@CNPJ_APP'
  const TOKENS_APP_EXTRA = '@TOKENS_APP_EXTRA'
  const token = localStorage.getItem(TOKEN_APP)
  const cnpj = localStorage.getItem(CNPJ_APP)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [nomeUsuario, setNomeUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!cnpj || !token) {
    return <Redirect to="/app" />
  }

  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleToken = () => {
    if (socket) {
      disconnectSocket(token)
    }
    localStorage.removeItem(MASTER_APP)
    localStorage.removeItem(ADMIN_APP)
    localStorage.removeItem(ID_APP)
    localStorage.removeItem(TOKEN_APP_ID)
    localStorage.removeItem(TOKEN_APP)
    localStorage.removeItem(FILIAL_APP)
    localStorage.removeItem(CNPJ_APP)
    localStorage.removeItem(TOKENS_APP_EXTRA)
    localStorage.removeItem(TOKENS_APP_EXTRA)
    handleButtonClick('/app')
  }

  const handleSavePress = async (): Promise<void> => {
    setError(null)

    if (nomeUsuario && senha) {
      socket.emit('filtroLoginApp', {
        message: {room: token, codigo: nomeUsuario, password: senha}
      })

      try {
        let receivedResponse = false

        const timer = setTimeout(() => {
          if (!receivedResponse) {
            setError('Erro - Verifique a conexão com o servidor!')
          }
        }, 3000)

        socket.on('loginDadosApp', async (data: any) => {
          // socket.emit('filtroLoginIdApp', {
          //   message: {room: token, id: data?.id}
          // })
          receivedResponse = true
          clearTimeout(timer)
          setLoading(true)
          const result: string = data?.logado
          const admin: string = data?.administrador
          const master: string = data?.master
          const id: string = data?.id

          if (result) {
            localStorage.setItem(TOKEN_APP_ID, `${token}-${id}`)
            localStorage.setItem(MASTER_APP, master)
            localStorage.setItem(ADMIN_APP, admin)
            localStorage.setItem(ID_APP, id)
            localStorage.setItem(TOKEN_APP_ID, `${token}-${id}`)
            localStorage.setItem(FILIAL_APP, '1')

            joinRoom(`${token}-${id}`)
            handleButtonClick('/app/home')
          } else {
            setError('Usuário e/ou Senha incorretos.')
          }

          setLoading(false)
        })
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      }
    } else {
      setError('Usuário e/ou Senha vazios. Preencha ambos.')
    }
  }

  const handleCodigoChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setNomeUsuario(event.target.value)
  }

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSenha(event.target.value)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container2}>
      <GridContainer spacing={0} className={classes.container}>
        <Paper className={classes.paper}>
          <GridContainer>
            <GridItem xs={12}>
              <img src={logo} className={classes.logo} alt="Logo" />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                required
                label="Usuário"
                variant="standard"
                value={nomeUsuario}
                onChange={handleCodigoChange}
                className={classes.textField}
              />
            </GridItem>

            <GridItem xs={12}>
              <TextField
                required
                label="Senha"
                variant="standard"
                type="password"
                value={senha}
                onChange={handlePasswordChange}
                className={classes.textField}
              />
            </GridItem>
            <GridItem xs={12}>
              <InputLabel
                className={classes.label}
                id="demo-controlled-open-select-label"
              >
                {error}
              </InputLabel>
            </GridItem>

            <GridItem xs={12}>
              <Button className={classes.touchable} onClick={handleSavePress}>
                Entrar
              </Button>
            </GridItem>
            <GridItem xs={12} style={{marginTop: '5vh'}}>
              <a
                onClick={handleClickOpen}
                style={{
                  color: '#224aea',
                  textDecoration: 'underline',
                  fontSize: 15
                }}
              >
                Resetar Token
              </a>
            </GridItem>
          </GridContainer>
        </Paper>
      </GridContainer>
      <main
        className={clsx(classes2.content, {
          [classes2.contentShift]: open
        })}
      >
        <div className={classes2.drawerHeader} />
      </main>
      {/* Diálogo de substituição de token */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="token-dialog-title"
        aria-describedby="token-dialog-description"
      >
        <DialogTitle id="token-dialog-title">
          Tem certeza que deseja resetar?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="token-dialog-description">
            Toda configuração atual será perdida!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleToken} color="primary">
            Resetar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
