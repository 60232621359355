import {IFornecedorCompraDireta} from './IFornecedorCompraDireta'
import {IItemPedidoCompraDireta} from './IItemPedidoCompraDireta'

export enum StatusPedidoCompraDireta {
  ABERTO = '0',
  FECHADO = '1'
}

export type IPedidoCompraDireta = {
  id?: number
  idFornecedorCompraDireta: number
  fornecedorCompraDireta: IFornecedorCompraDireta
  status: StatusPedidoCompraDireta
  dataPrevisao: Date
  dataCriacao: Date
  dataUltimoEnvio: Date
  observacao: string
  valorTotal: number
  itens: IItemPedidoCompraDireta[]
}
