import {
  Divider,
  InputAdornment,
  Typography,
  useMediaQuery,
  TableContainer,
  IconButton,
  TablePagination
} from '@material-ui/core'
import LoopIcon from '@material-ui/icons/Loop'
import VisibilityIcon from '@material-ui/icons/Visibility'
import {DatePicker} from '@material-ui/pickers'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Toaster, toast} from 'react-hot-toast'
import {useHistory, useParams} from 'react-router-dom'

import {Button} from 'components/Button'
import {List} from 'components/List'
import {Loading} from 'components/Loading'
import {Paper} from 'components/Paper'
import {Table} from 'components/Table'
import {TextField} from 'components/TextField'

import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {
  ICotacaoFornecedor,
  ICotacaoFornecedorItem
} from 'types/ICotacaoFornecedor'

import {formatarDataHora} from 'helpers/data'
import {apiErroHandle} from 'helpers/erro'

import {DialogObservacaoItem} from './components/DialogObservacaoItem'
import {PrecoInput} from './PrecoInput'
import {useClasses} from './styles'

export interface produtoObservacao {
  observacao: string
  index: number
  cotacaoFornecedorItem: ICotacaoFornecedorItem
}

export const Cotacao: React.FC = () => {
  /**
   * Hooks
   */
  const {chave}: any = useParams()
  const {push} = useHistory()
  const {error, success} = useToast()
  const matches = useMediaQuery('(min-width:750px)')
  const classes = useClasses()

  /**
   * Consts
   */
  const COTACAO_FORNECEDOR = {
    cotacaoFornecedorItens: [] as any[],
    cotacao: {
      inicio: new Date(),
      fim: new Date(),
      empresa: {}
    }
  } as ICotacaoFornecedor

  /**
   * Ref
   */

  const precoInput = useRef<HTMLInputElement[]>([])

  /**
   * State
   */

  const [referenciasPrecoInputDom, setReferenciasPrecoInputDom] = useState<
    HTMLInputElement[]
  >([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [loading, setLoading] = useState(false)
  const [observacaoLoading, setObservacaoLoading] = useState(false)
  const [dataPrevisaoLoading, setDataPrevisaoLoading] = useState(false)
  const [inputLoading, setInputLoading] = useState({} as any)
  const [produtoObservacao, setProdutoObservacao] = useState(
    {} as produtoObservacao
  )
  const [dialogObservacaoProduto, setDialogObservacaoProduto] = useState(false)
  const [cotacaoFornecedor, setCotacaoFornecedor] = useState(COTACAO_FORNECEDOR)

  /**
   * Memos
   */
  const mobile = useMemo(() => !matches, [matches])

  const dadosPagina = cotacaoFornecedor.cotacaoFornecedorItens
    .map((item, index) => ({...item, index}))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((item) => ({...item}))

  /**
   * Callbacks
   */
  const carregarCotacaoFornecedor = useCallback(async () => {
    try {
      const {data} = await api.get(`/cotacaoFornecedor/${chave}`)

      setCotacaoFornecedor(data)
    } catch (err) {
      push('/cotacao')

      throw err
    }
  }, [chave, push])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarCotacaoFornecedor()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarCotacaoFornecedor, error])

  const alterarPreco = useCallback(
    async (idCotacaoFornecedorItem: string, preco: string) => {
      await api.patch(
        `/cotacaoFornecedorItem/${idCotacaoFornecedorItem}/preco`,
        {preco}
      )
    },
    []
  )

  const alterarObservacaoProduto = useCallback(
    async (idCotacaoFornecedorItem: string, observacaoProduto: string) => {
      await api.patch(
        `/cotacaoFornecedorItem/${idCotacaoFornecedorItem}/observacao`,
        {observacaoProduto}
      )
    },
    []
  )

  const alterarObservacao = useCallback(
    async (idCotacaoFornecedor: string, observacao: string) => {
      await api.patch(`/cotacaoFornecedor/${idCotacaoFornecedor}/observacao`, {
        observacao
      })
    },
    []
  )

  const alterarDataPrevisao = useCallback(
    async (idCotacaoFornecedor: string, dataPrevisaoEntrega: Date) => {
      await api.patch(
        `/cotacaoFornecedor/${idCotacaoFornecedor}/dataPrevisaoEntrega`,
        {
          dataPrevisaoEntrega
        }
      )
    },
    []
  )

  /**
   * Handles
   */

  const pressKeyNextFocusPrecoProduto = useCallback(
    (e: any, index: number) => {
      e.preventDefault()

      const proximoItem = cotacaoFornecedor.cotacaoFornecedorItens[index + 1]
      if (!proximoItem) {
        return
      }

      if (e.key === 'Enter') {
        referenciasPrecoInputDom
          .find((el, indice) => indice === index + 1)
          ?.focus()
      }
    },
    [cotacaoFornecedor, referenciasPrecoInputDom]
  )

  const handleObservacaoProdutoChange = useCallback(
    (itemIndex: number, newValue: string) => {
      const temp = {...cotacaoFornecedor}

      temp.cotacaoFornecedorItens[itemIndex].observacaoProduto = newValue

      setCotacaoFornecedor(temp)
    },
    [cotacaoFornecedor]
  )

  const handleObservacaoProduto = useCallback(
    (itemIndex: number, cotacaoFornecedorItem: ICotacaoFornecedorItem) => {
      setProdutoObservacao({
        cotacaoFornecedorItem: cotacaoFornecedorItem,
        index: itemIndex,
        observacao: cotacaoFornecedorItem.observacaoProduto
      })

      setDialogObservacaoProduto(true)
    },
    []
  )

  const handlePrecoChange = (itemIndex: number, newValue: string) => {
    const temp = {...cotacaoFornecedor}

    temp.cotacaoFornecedorItens[itemIndex].preco = newValue

    setCotacaoFornecedor(temp)
  }

  const handleCloseDialogObservacaoProduto = useCallback(
    async (
      index: number,
      idCotacaoFornecedorItem: string,
      observacaoProduto: string
    ) => {
      try {
        if (new Date(cotacaoFornecedor.cotacao.fim) < new Date()) {
          setProdutoObservacao({} as produtoObservacao)
          setDialogObservacaoProduto(false)
          return
        }

        await alterarObservacaoProduto(
          idCotacaoFornecedorItem,
          observacaoProduto
        )

        handleObservacaoProdutoChange(index, observacaoProduto)
        setProdutoObservacao({} as produtoObservacao)
        setDialogObservacaoProduto(false)
      } catch (err) {
        error(apiErroHandle(err))
      }
    },
    [
      alterarObservacaoProduto,
      error,
      handleObservacaoProdutoChange,
      cotacaoFornecedor.cotacao.fim
    ]
  )

  const handleBlurPreco = useCallback(
    async (index: number, idCotacaoFornecedorItem: string, preco: string) => {
      try {
        setInputLoading({...inputLoading, [index]: true})

        await alterarPreco(idCotacaoFornecedorItem, preco)

        toast.success('Preço salvo!', {
          position: 'top-center',
          style: {
            background: '#b5ff96'
          }
        })
      } catch (err) {
        toast.error(apiErroHandle(err), {
          position: 'top-center',
          duration: 10000,
          style: {
            background: 'red',
            color: 'white'
          }
        })
      } finally {
        setInputLoading((prev: any) => ({...prev, [index]: false}))
      }
    },
    [alterarPreco, inputLoading]
  )

  const handleBlurObservacao = useCallback(async () => {
    if (!cotacaoFornecedor.observacao) return

    try {
      setObservacaoLoading(true)

      await alterarObservacao(
        cotacaoFornecedor.id,
        cotacaoFornecedor.observacao
      )
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setObservacaoLoading(false)
    }
  }, [
    alterarObservacao,
    cotacaoFornecedor.id,
    cotacaoFornecedor.observacao,
    error
  ])

  const handleBlurDataPrevisao = useCallback(async () => {
    if (!cotacaoFornecedor.dataPrevisaoEntrega) return

    try {
      setDataPrevisaoLoading(true)

      await alterarDataPrevisao(
        cotacaoFornecedor.id,
        cotacaoFornecedor.dataPrevisaoEntrega
      )
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setDataPrevisaoLoading(false)
    }
  }, [
    alterarDataPrevisao,
    cotacaoFornecedor.dataPrevisaoEntrega,
    cotacaoFornecedor.id,
    error
  ])

  const handleConfirmarPreenchimento = useCallback(async () => {
    try {
      setLoading(true)

      for (const item of cotacaoFornecedor.cotacaoFornecedorItens) {
        if (isNaN(Number(item.preco.replace(',', '.')))) {
          toast.error(
            `Verifique o preço do item ${item.cotacaoItem.produtoNome} e salve.`,
            {
              position: 'top-center',
              duration: 10000,
              style: {
                background: 'red',
                color: 'white'
              }
            }
          )
          return
        }
      }

      await api.post(`/cotacaoFornecedor/${chave}/confirmarPreenchimento`)

      success('Confirmação enviado com sucesso')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [chave, error, success, cotacaoFornecedor])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  /**
   * Effects
   */
  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  useEffect(() => {
    setReferenciasPrecoInputDom(precoInput.current)
  }, [])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Toaster />
      <Paper className={classes.paper}>
        <Typography variant="h6">
          <strong>Solicitante: </strong>
          {cotacaoFornecedor.cotacao.empresa.nome}
          {` (${cotacaoFornecedor.cotacao.empresa.cnpj})`}
        </Typography>

        {cotacaoFornecedor.cotacao.observacao && (
          <Typography variant="body1">
            <strong>Observação do solicitante: </strong>
            {cotacaoFornecedor.cotacao.observacao}
          </Typography>
        )}

        <Typography variant="body2">
          <strong>Condição de pagamento: </strong>
          {cotacaoFornecedor.cotacao.condicaoPagamento}
        </Typography>

        <Typography variant="body2">
          <strong>Data inicio: </strong>
          {formatarDataHora(cotacaoFornecedor.cotacao.inicio)}
        </Typography>

        <Typography variant="body2">
          <strong>Data final: </strong>
          {formatarDataHora(cotacaoFornecedor.cotacao.fim)}
        </Typography>

        {new Date(cotacaoFornecedor.cotacao.fim) < new Date() && (
          <Typography color="error" variant="body1">
            <strong>Atenção! Esta cotação já foi encerrada!</strong>
          </Typography>
        )}
      </Paper>

      <Divider />

      <Paper className={classes.paper}>
        <Typography variant="h6">
          <strong>Cotador:</strong> {cotacaoFornecedor.nomeFornecedor}
          {` (${cotacaoFornecedor.cnpjFornecedor})`}
        </Typography>

        <DatePicker
          className={classes.dateText}
          label="Data prevista para entrega"
          autoOk
          disabled={new Date(cotacaoFornecedor.cotacao.fim) < new Date()}
          disableToolbar
          format="dd/MM/yyyy"
          variant="inline"
          margin="dense"
          fullWidth
          value={cotacaoFornecedor.dataPrevisaoEntrega}
          onBlur={handleBlurDataPrevisao}
          onChange={(date) => {
            if (!date) return

            setCotacaoFornecedor({
              ...cotacaoFornecedor,
              dataPrevisaoEntrega: date
            })
          }}
          InputProps={{
            endAdornment: dataPrevisaoLoading && (
              <InputAdornment position="end">
                <LoopIcon />
              </InputAdornment>
            )
          }}
        />

        <TextField
          multiline
          label="Observação do cotador"
          rows={3}
          disabled={new Date(cotacaoFornecedor.cotacao.fim) < new Date()}
          value={cotacaoFornecedor.observacao}
          onBlur={handleBlurObservacao}
          onChange={(e) =>
            setCotacaoFornecedor({
              ...cotacaoFornecedor,
              observacao: e.target.value
            })
          }
          InputProps={{
            endAdornment: observacaoLoading && (
              <InputAdornment position="end">
                <LoopIcon />
              </InputAdornment>
            )
          }}
        />
      </Paper>

      <Divider />

      {mobile ? (
        <List.Container>
          {dadosPagina.map((cotacaoFornecedorItem) => (
            <List.Item key={cotacaoFornecedorItem.id}>
              <List.ItemText
                primary={
                  <>
                    <strong>Nome: </strong>
                    {cotacaoFornecedorItem.cotacaoItem.produtoNome}
                    <br />
                    <strong>Ean: </strong>
                    {cotacaoFornecedorItem.cotacaoItem.produtoEan}
                    <br />
                    <strong>Referência: </strong>
                    {cotacaoFornecedorItem.cotacaoItem.produtoReferencia}
                    <br />
                    <strong>Quantidade: </strong>
                    {Number(
                      cotacaoFornecedorItem.cotacaoItem.quantidade
                    ).toLocaleString('pt-br')}
                    <br />
                    <div
                      style={{
                        background: '#ffff66',
                        paddingTop: 8,
                        paddingBottom: 8
                      }}
                    >
                      <strong>Unidade de medida: </strong>
                      {cotacaoFornecedorItem.cotacaoItem.produtoUnidadeMedida}
                    </div>
                  </>
                }
                secondary={
                  <>
                    <div style={{display: 'flex'}}>
                      <strong
                        style={{color: 'black', marginRight: 10, minWidth: 100}}
                      >
                        {'Preço: '}{' '}
                      </strong>
                      <PrecoInput
                        label={`Preço ${cotacaoFornecedorItem.cotacaoItem.produtoUnidadeMedida}`}
                        index={cotacaoFornecedorItem.index}
                        value={Number(
                          cotacaoFornecedorItem.preco.replace(',', '.')
                        ).toLocaleString('pt-br')}
                        disabled={
                          new Date(cotacaoFornecedor.cotacao.fim) < new Date()
                        }
                        onChange={handlePrecoChange}
                        loading={inputLoading[cotacaoFornecedorItem.index]}
                        onBlur={() =>
                          handleBlurPreco(
                            cotacaoFornecedorItem.index,
                            cotacaoFornecedorItem.id,
                            cotacaoFornecedorItem.preco
                          )
                        }
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        background: '#ffff66',
                        overflow: 'hidden'
                      }}
                    >
                      <strong
                        style={{color: 'black', marginRight: 10, minWidth: 100}}
                      >
                        {'Valor cotado unitário: '}{' '}
                      </strong>
                      <TextField
                        label="Valor cotado unitário"
                        style={{background: 'transparent'}}
                        inputProps={{
                          style: {fontWeight: 'bold', color: '#000'}
                        }}
                        InputLabelProps={{
                          style: {
                            fontWeight: 'bold',
                            color: '#000',
                            whiteSpace: 'nowrap'
                          }
                        }}
                        disabled
                        value={(
                          Number(
                            cotacaoFornecedorItem.preco.replace(',', '.')
                          ) /
                          (cotacaoFornecedorItem.cotacaoItem.fatorConversao ??
                            1)
                        ).toFixed(2)}
                      />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <strong style={{color: 'black', marginRight: 10}}>
                        {'Obs: '}{' '}
                      </strong>
                      <IconButton
                        aria-label="observação"
                        size="medium"
                        onClick={() =>
                          handleObservacaoProduto(
                            cotacaoFornecedorItem.index,
                            cotacaoFornecedorItem
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </div>
                  </>
                }
              />
            </List.Item>
          ))}
        </List.Container>
      ) : (
        <TableContainer>
          <Table.Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>Produto</Table.HeadCell>
                <Table.HeadCell>Ean</Table.HeadCell>
                <Table.HeadCell>Referência</Table.HeadCell>
                <Table.HeadCell>Observação</Table.HeadCell>
                <Table.HeadCell>Quantidade</Table.HeadCell>
                <Table.HeadCell>Und. medida</Table.HeadCell>
                <Table.HeadCell width={230}>Preço</Table.HeadCell>
                <Table.HeadCell width={230}>
                  Valor cotado unitário
                </Table.HeadCell>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {dadosPagina.map((cotacaoFornecedorItem) => (
                <Table.Row key={cotacaoFornecedorItem.id}>
                  <Table.Cell>
                    {cotacaoFornecedorItem.cotacaoItem.produtoNome}
                  </Table.Cell>
                  <Table.Cell>
                    {cotacaoFornecedorItem.cotacaoItem.produtoEan}
                  </Table.Cell>
                  <Table.Cell>
                    {cotacaoFornecedorItem.cotacaoItem.produtoReferencia}
                  </Table.Cell>
                  <Table.Cell>
                    <IconButton
                      aria-label="observação"
                      size="medium"
                      onClick={() =>
                        handleObservacaoProduto(
                          cotacaoFornecedorItem.index,
                          cotacaoFornecedorItem
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Table.Cell>
                  <Table.Cell>
                    {cotacaoFornecedorItem.cotacaoItem.quantidade}
                  </Table.Cell>
                  <Table.Cell style={{background: '#ffff66'}}>
                    {cotacaoFornecedorItem.cotacaoItem.produtoUnidadeMedida}
                  </Table.Cell>
                  <Table.Cell>
                    <PrecoInput
                      label={`Preço ${cotacaoFornecedorItem.cotacaoItem.produtoUnidadeMedida}`}
                      index={cotacaoFornecedorItem.index}
                      disabled={
                        new Date(cotacaoFornecedor.cotacao.fim) < new Date()
                      }
                      value={Number(
                        cotacaoFornecedorItem.preco.replace(',', '.')
                      ).toLocaleString('pt-br')}
                      onChange={handlePrecoChange}
                      onKeyUp={(e) =>
                        pressKeyNextFocusPrecoProduto(
                          e,
                          cotacaoFornecedorItem.index
                        )
                      }
                      inputRef={(el: any) =>
                        (precoInput.current[cotacaoFornecedorItem.index] = el)
                      }
                      loading={inputLoading[cotacaoFornecedorItem.index]}
                      onBlur={() =>
                        handleBlurPreco(
                          cotacaoFornecedorItem.index,
                          cotacaoFornecedorItem.id,
                          cotacaoFornecedorItem.preco
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell style={{background: '#ffff66'}}>
                    <TextField
                      label="Valor cotado unitário"
                      style={{background: 'transparent'}}
                      inputProps={{
                        style: {fontWeight: 'bold', color: '#000'}
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 'bold',
                          color: '#000',
                          whiteSpace: 'nowrap'
                        }
                      }}
                      disabled
                      value={(
                        Number(cotacaoFornecedorItem.preco.replace(',', '.')) /
                        (cotacaoFornecedorItem.cotacaoItem.fatorConversao ?? 1)
                      ).toFixed(2)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Table>
        </TableContainer>
      )}

      <TablePagination
        component="div"
        count={cotacaoFornecedor.cotacaoFornecedorItens.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<></>}
      />

      <DialogObservacaoItem
        cotacaoFornecedor={cotacaoFornecedor}
        dialogObservacaoProduto={dialogObservacaoProduto}
        onCloseDialogObservacaoProduto={handleCloseDialogObservacaoProduto}
        produtoObservacao={produtoObservacao}
      />
      {new Date(cotacaoFornecedor.cotacao.fim) > new Date() && (
        <Button
          className={classes.confirmarPreenchimento}
          fullWidth={false}
          onClick={handleConfirmarPreenchimento}
        >
          Enviar e-mail de confirmação de preenchimento
        </Button>
      )}
    </>
  )
}
