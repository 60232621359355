import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Loading} from 'components/Loading'
import {Table} from 'components/Table'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IDownloadPage} from 'types/IDownloadPage'

import {apiErroHandle} from 'helpers/erro'

export const DownloadPageEdicao: React.FC = () => {
  /**
   * Hooks
   */
  const {alterarTitulo} = useTitulo()
  const {error, success} = useToast()
  const {push} = useHistory()

  /**
   * Consts
   */
  const DOWNLOAD_PAGE = {} as IDownloadPage

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [downloadPage, setDownloadPage] = useState(DOWNLOAD_PAGE)

  /**
   * Callbacks
   */
  const carregarDownloadPage = useCallback(async () => {
    const {data} = await api.get('/downloadPage')
    setDownloadPage(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarDownloadPage()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarDownloadPage, error])

  const atualizarDownloadPage = useCallback(async () => {
    await api.put('/downloadPage', downloadPage)
  }, [downloadPage])

  /**
   * Handles
   */
  const handleCancelar = useCallback(async () => {
    push('/home')
  }, [push])

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)

      await atualizarDownloadPage()

      success('Página de download atualizada!!!')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [atualizarDownloadPage, error, success])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Página de downloads')
  }, [alterarTitulo])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleSubmit}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Table.Container>
        <TextField
          fullWidth
          label="Texto"
          multiline
          rows={20}
          value={downloadPage.texto}
          onChange={(e) =>
            setDownloadPage({...downloadPage, texto: e.target.value})
          }
        />
      </Table.Container>
    </>
  )
}
