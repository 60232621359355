import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Contas {
  dia: string
  total: string
  documento?: string
  [key: string]: string | number | undefined
}

export const AppForaLimite: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)

  const [products, setProducts] = useState<Contas[]>([])
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    // if (filiais) return
    setLoading(true)
    socket.emit('filtroForaLimiteApp', {
      message: {
        room: token,
        filial: filiais
      }
    })
    socket.on('foraLimiteDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados?.itens)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  const renderProductItem = ({item}: {item: Contas}) => (
    <Card className={classes.card} key={item?.dia}>
      <CardContent>
        <h2 className={classes.textLeft}>{item?.dia}</h2>
        {Object.keys(item).map(
          (key, index) =>
            key !== 'dia' && (
              <p className={classes.textLeft} key={index}>
                {key}: {formatPrice(Number(item[key])) || 'Não informado'}
              </p>
            )
        )}
      </CardContent>
    </Card>
  )

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {!loading && products.length === 0 && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}

        {products.length > 0 ? (
          <div>
            {products.map((product, index) =>
              renderProductItem({item: product})
            )}
          </div>
        ) : null}
      </Paper>
    </GridContainer>
  )
}
