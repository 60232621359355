import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IUsuario} from 'types/IUsuario'

import {apiErroHandle} from 'helpers/erro'

export const Usuario: React.FC = () => {
  /**
   * Hooks
   */
  const {idUsuario} = useParams<{idUsuario: string}>()
  const {push} = useHistory()
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()

  /**
   * Consts
   */
  const USUARIO = {} as IUsuario

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [usuario, setUsuario] = useState(USUARIO)

  /**
   * Memos
   */
  const incluirRegistro = useMemo(() => !Boolean(Number(idUsuario)), [
    idUsuario
  ])

  /**
   * Callbacks
   */
  const carregarUsuario = useCallback(async () => {
    if (incluirRegistro) return

    const {data} = await api.get(`/usuario/${idUsuario}`)
    setUsuario(data)
  }, [idUsuario, incluirRegistro])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarUsuario()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarUsuario, error])

  const add = useCallback(async () => {
    await api.post('/usuario', usuario)
  }, [usuario])

  const edit = useCallback(async () => {
    await api.put(`/usuario/${idUsuario}`, usuario)
  }, [idUsuario, usuario])

  /**
   * Handles
   */
  const handleCancelar = useCallback(() => {
    push('/usuario')
  }, [push])

  const handleConfirmar = useCallback(async () => {
    try {
      setLoading(true)

      if (incluirRegistro) {
        await add()
      } else {
        await edit()
      }

      push('/usuario')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [add, edit, error, incluirRegistro, push])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo(`Usuário ${incluirRegistro ? '' : idUsuario}`)
  }, [alterarTitulo, idUsuario, incluirRegistro])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleConfirmar}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={12}>
          <TextField label="Código" disabled value={usuario.id} />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TextField
            label="Nome"
            value={usuario.nome}
            onChange={(e) => setUsuario({...usuario, nome: e.target.value})}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TextField
            label="Usuário"
            value={usuario.nomeUsuario}
            onChange={(e) =>
              setUsuario({...usuario, nomeUsuario: e.target.value})
            }
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TextField
            type="password"
            label="Senha"
            value={usuario.senha}
            onChange={(e) => setUsuario({...usuario, senha: e.target.value})}
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TextField
            type="password"
            label="Senha confirmação"
            value={usuario.senhaConfirmacao}
            onChange={(e) =>
              setUsuario({...usuario, senhaConfirmacao: e.target.value})
            }
          />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
