import {Backdrop, CircularProgress} from '@material-ui/core'
import React from 'react'

export const Loading: React.FC = () => {
  return (
    <Backdrop style={{zIndex: 999, color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center'}} open>
      <CircularProgress />
      <p style={{marginTop: 15, fontSize: 16}}>Este processo pode demorar alguns segundos, aguarde!</p>
    </Backdrop>
  )
}
