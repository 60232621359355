import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, {useState, useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Item {
  id: string
  idproduto: string
  produto: string
  filial: string
  custo: number
  preco: number
  precoPromocao: number
  quantidadeVenda: number
  desconto: number
  margem: number | null
  percentualPromocao: number
}

interface Product {
  id: any
  nome: string
  dataInicial: string
  dataFinal: string
  quantidadeVendaTotal: string
  precoTotal: string
  precoPromocaoTotal: string
  descontoTotal: string
  itens: Item[]
}

export const AppListaPromocoes: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)

  const [socketConnected, setSocketConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    setLoading(true)
    socket.emit('filtroRankingApp', {
      message: {
        room: token
      }
    })
    socket.on('rankingDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados.promocoes)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  function formatData(dataOriginal: string): string {
    const dataHoraObj: Date = new Date(dataOriginal)

    const dia: number = dataHoraObj.getUTCDate()
    const mes: number = dataHoraObj.getUTCMonth() + 1
    const ano: number = dataHoraObj.getUTCFullYear()

    const dataFormatada: string = `${dia < 10 ? '0' : ''}${dia}-${
      mes < 10 ? '0' : ''
    }${mes}-${ano}`

    return dataFormatada
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {!loading && !products && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}
        {products && !loading && (
          <div>
            {products.map((item: Product, index: number) => (
              <Link
                className={classes.card}
                key={item.id}
                to={{
                  pathname: '/app/itenspromocao',
                  state: {productItens: item.itens}
                }}
              >
                <Card key={index} className={classes.card}>
                  <CardContent>
                    <h3 className={classes.p}>{item.nome}</h3>
                    <p className={classes.p}>
                      Data Inicial:{' '}
                      {formatData(String(item?.dataInicial.split('T')[0]))}
                    </p>
                    <p className={classes.p}>
                      Data Final:{' '}
                      {formatData(String(item?.dataFinal.split('T')[0]))}
                    </p>
                    <p className={classes.p}>
                      Quantidade de Vendas: {item.quantidadeVendaTotal}
                    </p>
                    <p className={classes.p}>
                      Preço Total:{' '}
                      {formatPrice(Number(item?.precoTotal)) || 'Não informado'}
                    </p>
                    <p className={classes.p}>
                      Preço Total da Promoção:{' '}
                      {formatPrice(Number(item?.precoPromocaoTotal)) ||
                        'Não informado'}
                    </p>
                    <p className={classes.p}>
                      Desconto Total:{' '}
                      {formatPrice(Number(item?.descontoTotal)) ||
                        'Não informado'}
                    </p>
                  </CardContent>
                </Card>
              </Link>
            ))}
          </div>
        )}
      </Paper>
    </GridContainer>
  )
}
