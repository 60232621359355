import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Button
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import {Autocomplete} from '@material-ui/lab'
import produce from 'immer'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {MaskTextField} from 'components/MaskTextField'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IEmpresa} from 'types/IEmpresa'
import {ILicenca} from 'types/ILicenca'
import {IRelatorioBot} from 'types/IRelatorioBot'

import {apiErroHandle} from 'helpers/erro'

export const Empresa: React.FC = () => {
  /**
   * Consts
   */
  const EMPRESA = {
    licencas: [] as any,
    relatoriosBot: [] as any
  } as IEmpresa

  /**
   * Hooks
   */
  const {idEmpresa}: any = useParams()
  const {push} = useHistory()
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [empresa, setEmpresa] = useState(EMPRESA)

  const [licencas, setLicencas] = useState<ILicenca[]>([])
  const [licenca, setLicenca] = useState<ILicenca | null>(null)

  const [relatoriosBot, setRelatoriosBot] = useState<IRelatorioBot[]>([])
  const [relatorioBot, setRelatorioBot] = useState<IRelatorioBot | null>(null)

  /**
   * Memos
   */
  const incluirRegistro = useMemo(
    () => !Boolean(Number(idEmpresa)),
    [idEmpresa]
  )

  /**
   * Callbacks
   */
  const carregarEmpresa = useCallback(async () => {
    if (incluirRegistro) return

    const {data} = await api.get(`/empresa/${idEmpresa}`)
    setEmpresa(data)
  }, [idEmpresa, incluirRegistro])

  const carregarLicencas = useCallback(async () => {
    const {data} = await api.get('/licenca')
    setLicencas(data)
  }, [])

  const carregarRelatoriosBot = useCallback(async () => {
    const {data} = await api.get('/relatorioBot')
    setRelatoriosBot(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        carregarEmpresa(),
        carregarLicencas(),
        carregarRelatoriosBot()
      ])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarEmpresa, carregarLicencas, carregarRelatoriosBot, error])

  const add = useCallback(async () => {
    await api.post('/empresa', empresa)
  }, [empresa])

  const edit = useCallback(async () => {
    await api.put(`/empresa/${idEmpresa}`, empresa)
  }, [empresa, idEmpresa])

  /**
   * Handles
   */
  const handleConfirmar = useCallback(async () => {
    try {
      setLoading(true)

      if (incluirRegistro) {
        await add()
      } else {
        await edit()
      }

      push('/empresa')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [add, edit, error, incluirRegistro, push])

  const handleCancelar = useCallback(() => {
    push('/empresa')
  }, [push])

  const handleAddLicenca = useCallback(() => {
    if (!licenca) return

    setEmpresa(
      produce(empresa, (draft) => {
        const licencaIndex = draft.licencas.findIndex(
          (l) => l.id === licenca.id
        )

        if (licencaIndex >= 0) return

        draft.licencas.push(licenca)
      })
    )

    setLicenca(null)
  }, [empresa, licenca])

  const handleAddRelatorioBot = useCallback(() => {
    if (!relatorioBot) return

    setEmpresa(
      produce(empresa, (draft) => {
        const relatorioBotIndex = draft.relatoriosBot.findIndex(
          (r) => r.id === relatorioBot.id
        )

        if (relatorioBotIndex >= 0) return

        draft.relatoriosBot.push(relatorioBot)
      })
    )

    setRelatorioBot(null)
  }, [empresa, relatorioBot])

  function gerarTokenAleatorio() {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const comprimentoToken = 15

    let novoToken = ''

    for (let i = 0; i < comprimentoToken; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length)
      novoToken += caracteres.charAt(indiceAleatorio)
    }

    return novoToken
  }

  const handleGerarToken = useCallback(async () => {
    try {
      setLoading(true)

      const novoToken = gerarTokenAleatorio()

      setEmpresa((prevConfiguracao: any) => ({
        ...prevConfiguracao,
        botToken: novoToken
      }))
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [setEmpresa])

  const handleDeleteLicenca = useCallback(
    (licencaIndex: number) => {
      setEmpresa(
        produce(empresa, (draft) => {
          draft.licencas.splice(licencaIndex, 1)
        })
      )
    },
    [empresa]
  )

  const handleDeleteRelatorioBot = useCallback(
    (relatorioBotIndex: number) => {
      setEmpresa(
        produce(empresa, (draft) => {
          draft.relatoriosBot.splice(relatorioBotIndex, 1)
        })
      )
    },
    [empresa]
  )

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo(`Empresa ${incluirRegistro ? '' : idEmpresa}`)
  }, [alterarTitulo, idEmpresa, incluirRegistro])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleConfirmar}
        />

        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={1}>
          <TextField disabled label="Código" value={empresa.id} />
        </Grid.Item>

        <Grid.Item xs={4}>
          <TextField
            label="Nome"
            value={empresa.nome}
            onChange={(e) => setEmpresa({...empresa, nome: e.target.value})}
          />
        </Grid.Item>

        <Grid.Item xs={4}>
          <Tooltip title="É nesse email que é enviado as confirmações de preenchimento de cotações">
            <TextField
              type="email"
              label="Email"
              value={empresa.email}
              onChange={(e) => setEmpresa({...empresa, email: e.target.value})}
            />
          </Tooltip>
        </Grid.Item>

        <Grid.Item xs={3}>
          <MaskTextField.Cnpj
            label="Cnpj"
            value={empresa.cnpj}
            onChange={(e) => setEmpresa({...empresa, cnpj: e.target.value})}
          />
        </Grid.Item>

        <Grid.Item xs={4}>
          <TextField
            label="Token do Bot/App"
            value={empresa.botToken}
            onChange={(e) => setEmpresa({...empresa, botToken: e.target.value})}
          />
        </Grid.Item>

        <Grid.Item
          xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="contained"
            onClick={handleGerarToken}
            style={{margin: 4}}
          >
            Gerar Token
          </Button>
          <Tooltip title="O Token gerado automaticamente serve apenas para o Aplicativo de Relatórios, para utilizar o token do Bot crie um Bot no BotFather.">
            <InfoIcon />
          </Tooltip>
        </Grid.Item>

        <Grid.Item xs={6}>
          <TextField
            label="Nome do bot"
            value={empresa.botNome}
            onChange={(e) => setEmpresa({...empresa, botNome: e.target.value})}
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <Grid.Container>
            <Grid.Item xs={11}>
              <Autocomplete
                options={licencas}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField {...params} label="Licenças" />
                )}
                value={licenca}
                onChange={(e, value: ILicenca) => setLicenca(value)}
              />
            </Grid.Item>

            <Grid.Item xs={1}>
              <IconButton onClick={handleAddLicenca}>
                <AddIcon />
              </IconButton>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={6}>
          <Grid.Container>
            <Grid.Item xs={11}>
              <Autocomplete
                options={relatoriosBot}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField {...params} label="Relatórios do Bot" />
                )}
                value={relatorioBot}
                onChange={(e, value: IRelatorioBot) => setRelatorioBot(value)}
              />
            </Grid.Item>

            <Grid.Item xs={1}>
              <IconButton onClick={handleAddRelatorioBot}>
                <AddIcon />
              </IconButton>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={6}>
          <List>
            {empresa.licencas.map((licencaEmpresa, licencaIndex) => (
              <ListItem key={licencaEmpresa.id}>
                <ListItemText primary={licencaEmpresa.nome} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteLicenca(licencaIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid.Item>

        <Grid.Item xs={6}>
          <List>
            {empresa.relatoriosBot.map(
              (relatorioBotEmpresa, relatorioBotIndex) => (
                <ListItem key={relatorioBotEmpresa.id}>
                  <ListItemText primary={relatorioBotEmpresa.nome} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        handleDeleteRelatorioBot(relatorioBotIndex)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
