import {Table as TableMui, TableProps} from '@material-ui/core'
import React from 'react'

export const Table: React.FC<TableProps> = ({children, ...props}) => {
  return (
    <TableMui stickyHeader size="small" {...props}>
      {children}
    </TableMui>
  )
}
