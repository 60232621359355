import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#f5f2f2',
    width: '100%'
  },
  paper: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: -35,
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#f5f2f2',
    width: '100%'
  },
  search: {
    width: '100%',
    marginBottom: 0,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
    // borderRadius: 10
  },
  search2: {
    width: '100%',
    marginBottom: 10,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
    // borderRadius: 10
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20,
    width: '100%'
  },
  card: {
    marginTop: 15,
    minWidth: 200,
    minHeight: 120,
    borderRadius: 15,
    width: '100%',
    backgroundColor: '#f5f5f5',
    border: 15
  },
  label: {
    textAlign: 'left',
    fontSize: '17px',
    marginBottom: 15,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  p: {
    textAlign: 'left',
    fontSize: 15,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 140,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  }
}))

export default useStyles;
