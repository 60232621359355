import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Paper} from 'components/Paper'
import {TextField} from 'components/TextField'

import {DrawerApp} from '../../../components/DrawerApp/index'
import useStyles from './style'

interface Branch {
  apelido: string
  cnpj: string
  token: string
}

export const AppConfig: React.FC = () => {
  const classes = useStyles()
  const [branches, setBranches] = useState<Branch[]>([])
  const [apelido, setApelido] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [token, setToken] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const TOKENS_APP_EXTRA = '@TOKENS_APP_EXTRA'
  const TOKEN_APP = '@TOKEN_APP'
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const CNPJ_APP = '@CNPJ_APP'

  const tokenMatriz = localStorage.getItem(TOKEN_APP)
  const cnpjMatriz = localStorage.getItem(CNPJ_APP)
  const tokenId = localStorage.getItem(TOKEN_APP_ID)

  useEffect(() => {
    const tokens_extra = localStorage.getItem(TOKENS_APP_EXTRA)

    if (tokens_extra) {
      const parsedTokens: Branch[] = JSON.parse(tokens_extra)
      setBranches(parsedTokens)
    }
  }, [])

  const handleSave = (): void => {
    if (!apelido || !cnpj || !token) {
      alert('Por favor, preencha todos os campos!')
      return
    }

    const newBranch: Branch = {
      apelido: apelido,
      cnpj: cnpj,
      token: token
    }

    try {
      setBranches((prevState) => [...prevState, newBranch])

      localStorage.setItem(
        TOKENS_APP_EXTRA,
        JSON.stringify([...branches, newBranch])
      )

      handleClose()

      setApelido('')
      setCnpj('')
      setToken('')
    } catch (error) {
      console.error('Erro ao adicionar nova empresa:', error)
    }
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleApelidoChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setApelido(event.target.value)
  }

  const handleCnpjChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const text = event.target.value
    if (text.length <= 18) {
      let formattedCnpj = formatCnpj(text)
      setCnpj(formattedCnpj)
    }
  }

  const handleTokenChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setToken(event.target.value)
  }

  const formatCnpj = (cnpj: string): string => {
    let formattedCnpj = cnpj.replace(/\D/g, '')
    formattedCnpj = formattedCnpj.replace(/(\d{2})(\d)/, '$1.$2')
    formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1.$2')
    formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1/$2')
    formattedCnpj = formattedCnpj.replace(/(\d{4})(\d)/, '$1-$2')
    return formattedCnpj
  }

  const handleDelete = (index: number): void => {
    const branchToDelete = branches[index]

    if (branchToDelete.token === tokenMatriz) {
      alert('Não é permitido excluir este token.')
      return
    }

    const updatedBranches = [...branches]
    updatedBranches.splice(index, 1)
    setBranches(updatedBranches)
    localStorage.setItem(TOKENS_APP_EXTRA, JSON.stringify(updatedBranches))
  }

  if (!cnpjMatriz || !tokenMatriz || !tokenId) {
    return <Redirect to="/app" />
  }

  return (
    <div className="your-parent-container-class">
      {' '}
      <GridContainer spacing={0} className={classes.container}>
        <DrawerApp />
        <Paper className={classes.paper}>
          <Typography variant="body1" className={classes.label}>
            EMPRESAS:
          </Typography>
          {branches.length > 0 ? (
            branches.map((result, index) => (
              <Card key={index} className={classes.card}>
                <CardContent style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{flexGrow: 1}}>
                    <h3 className={classes.p}>{`${index + 1} - ${
                      result?.apelido
                    }`}</h3>
                    <p className={classes.p}>
                      <b>CNPJ:</b> {result.cnpj}
                    </p>
                    <p className={classes.p}>
                      <b>Token:</b> {result.token}
                    </p>
                    <br />
                  </div>
                  {result.token !== tokenMatriz && (
                    <div>
                      <DeleteIcon
                        onClick={() => handleDelete(index)}
                        color="secondary"
                        className={classes.deleteIcon}
                        style={{fontSize: 30}}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            ))
          ) : (
            <Card className={classes.card}>
              <CardContent>
                <br></br>
                <h3>Nenhuma empresa cadastrada!</h3>
                <br></br>
              </CardContent>
            </Card>
          )}
        </Paper>
        <Button className={classes.touchable} onClick={handleClickOpen}>
          Adicionar Empresa
        </Button>
      </GridContainer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      {/* Diálogo para adicionar empresa */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="token-dialog-title"
        aria-describedby="token-dialog-description"
      >
        <DialogTitle id="token-dialog-title">Nova empresa</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                required
                label="Apelido"
                value={apelido}
                onChange={handleApelidoChange}
                variant="standard"
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                required
                label="CNPJ"
                value={cnpj}
                onChange={handleCnpjChange}
                variant="standard"
              />
            </GridItem>
            <GridItem xs={12} className={classes.dialogInput}>
              <TextField
                required
                label="Token"
                value={token}
                onChange={handleTokenChange}
                variant="standard"
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.touchable}>
            Cancelar
          </Button>
          <Button onClick={handleSave} className={classes.touchable}>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
