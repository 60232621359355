import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Loading} from 'components/Loading'
import {Table} from 'components/Table'

import {useDialogo} from 'contexts/dialogo'
import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IBuild} from 'types/IBuild'

import {filterArray} from 'helpers/array'
import {formatarDataHora} from 'helpers/data'
import {apiErroHandle} from 'helpers/erro'

export const BuildPesquisa: React.FC = () => {
  /**
   * Hooks
   */
  const {push} = useHistory()
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()
  const {confirmacao} = useDialogo()

  /**
   * States
   */
  const [pesquisar, setPesquisar] = useState('')
  const [loading, setLoading] = useState(false)
  const [keySelecionado, setKeySelecionado] = useState<string | null>(null)

  const [builds, setBuilds] = useState<IBuild[]>([])
  const [buildsFilter, setBuildsFilter] = useState<IBuild[]>([])

  /**
   * Callbacks
   */
  const carregarBuilds = useCallback(async () => {
    const {data} = await api.get('/build')
    setBuilds(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarBuilds()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarBuilds, error])

  const excluirBuild = useCallback(async () => {
    if (!keySelecionado) return

    try {
      setLoading(true)

      await api.delete(`/build`, {params: {key: keySelecionado}})

      await carregarBuilds()
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarBuilds, error, keySelecionado])

  /**
   * Handles
   */
  const handleAdicionar = useCallback(() => {
    push('/build/0')
  }, [push])

  const handleExcluir = useCallback(async () => {
    if (!keySelecionado) return

    confirmacao({
      titulo: 'Atenção',
      conteudo: 'Deseja realmente excluir a build?',
      acao: excluirBuild
    })
  }, [confirmacao, excluirBuild, keySelecionado])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Builds')
  }, [alterarTitulo])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  useEffect(() => {
    setBuildsFilter(filterArray(pesquisar, builds))
  }, [builds, pesquisar])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar
        inputValue={pesquisar}
        onInputChange={(e) => setPesquisar(e.target.value)}
        onSubmit={carregarDados}
      >
        <ActionBarIcon
          icon={<AddIcon />}
          tip="Adicionar"
          onClick={handleAdicionar}
        />
        <ActionBarIcon
          icon={<DeleteIcon />}
          tip="Excluir"
          onClick={handleExcluir}
        />
      </ActionBar>

      <Table.Container>
        <Table.Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Nome</Table.HeadCell>
              <Table.HeadCell>Última modificação</Table.HeadCell>
              <Table.HeadCell>Tamanho</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {buildsFilter.map((build) => (
              <Table.Row
                key={build.key}
                selected={build.key === keySelecionado}
                onClick={() => setKeySelecionado(build.key)}
              >
                <Table.Cell>{build.key}</Table.Cell>
                <Table.Cell>{formatarDataHora(build.lastModified)}</Table.Cell>
                <Table.Cell>{build.size}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Table>
      </Table.Container>
    </>
  )
}
