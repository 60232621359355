import React, {useCallback, useState} from 'react'
import styled from 'styled-components'

import {TextField} from 'components/TextField'

interface Props {
  index: number
  onBlur?: any
  value: string
  loading: boolean
  disabled?: boolean
  onChange(itemIndex: number, newValue: string): void
  onKeyUp?(e: any): void
  inputRef?: any
  label: string
}

const Input = styled.input<any>`
  border-radius: 2px;
  border: ${(props) =>
    props['data-loading'] ? '2px solid #3F51B5' : '2px solid #EBE9ED'};
  width: 100%;
  font-size: 20px;
`

const PrecoInput: React.FC<Props> = (props) => {
  const [preco, setPreco] = useState(props.value)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let newValue = e.target.value

      if (Number(newValue) < 0) {
        return
      }

      setPreco(newValue)

      props.onChange(props.index, newValue)
    },
    [props]
  )

  const handleFocus = (event: any) => event.target.select()

  return (
    <TextField
      // workaround to show numeric keyboard
      type="tel"
      label={props.label}
      value={preco}
      disabled={props.disabled}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={props.onBlur}
      data-loading={props.loading}
      onKeyUp={props.onKeyUp}
      inputRef={props.inputRef}
    />
  )
}

export {PrecoInput}
