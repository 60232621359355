import {makeStyles} from '@material-ui/core'

export const useClasses = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  divider: {
    height: 28,
    margin: 4
  },
  iconButton: {
    padding: 10
  }
}))
