import {IconButton, IconButtonProps, Tooltip} from '@material-ui/core'
import React from 'react'

import {useClasses} from './styles'

export interface ActionBarIconProps extends IconButtonProps {
  icon: React.ReactNode
  tip?: string
}

export const ActionBarIcon: React.FC<ActionBarIconProps> = ({
  icon,
  tip = '',
  ...props
}) => {
  /**
   * Hooks
   */
  const classes = useClasses()

  /**
   * Returns
   */
  return (
    <Tooltip title={tip}>
      <IconButton className={classes.iconButton} {...props}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}
