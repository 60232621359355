import {IconButton, TableFooter} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React from 'react'
import {formatPrice} from 'util/format'

import {Table} from 'components/Table'
import {TableContainer} from 'components/Table/TableContainer'

import {IItemPedidoCompraDireta} from 'types/IItemPedidoCompraDireta'

type Props = {
  itens: IItemPedidoCompraDireta[]
  total: number
  handleClickItemEdicao: (item: IItemPedidoCompraDireta) => void
  handleClickDeleteItem: (idItem: number) => void
  handleClickObservacaoItem: (
    idItem: number,
    descricao: string,
    observacao: string
  ) => void
}

const TableItens = ({
  itens,
  total,
  handleClickItemEdicao,
  handleClickDeleteItem,
  handleClickObservacaoItem
}: Props) => {
  return (
    <TableContainer>
      <Table.Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Produto</Table.HeadCell>
            <Table.HeadCell>Ean</Table.HeadCell>
            <Table.HeadCell>Referência</Table.HeadCell>
            <Table.HeadCell>Und. medida</Table.HeadCell>
            <Table.HeadCell>Quantidade</Table.HeadCell>
            <Table.HeadCell>Preço</Table.HeadCell>
            <Table.HeadCell>Total</Table.HeadCell>
            <Table.HeadCell width={20}>Obs.</Table.HeadCell>
            <Table.HeadCell width={20}></Table.HeadCell>
            <Table.HeadCell width={20}></Table.HeadCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {itens.map((item) => (
            <Table.Row
              hover
              onDoubleClick={() => handleClickItemEdicao(item)}
              key={item.id}
            >
              <Table.Cell>
                {item.fornecedorProduto.produto.produtoNome}
              </Table.Cell>
              <Table.Cell>
                {item.fornecedorProduto.produto.produtoEan}
              </Table.Cell>
              <Table.Cell>{item.fornecedorProduto.referencia}</Table.Cell>
              <Table.Cell>
                {item.usaEmbalagem
                  ? `${item.embalagem.unidadeMedida.codigo}(${Number(
                      item.embalagem.fatorConversao
                    ).toFixed(item.unidadeMedida.casasDecimais)})`
                  : item.unidadeMedida.codigo}
              </Table.Cell>
              <Table.Cell>{item.quantidade}</Table.Cell>
              <Table.Cell>{formatPrice(item.custo)}</Table.Cell>
              <Table.Cell>
                {formatPrice(item.custo * item.quantidade)}
              </Table.Cell>
              <Table.Cell>
                <IconButton
                  aria-label="Obs."
                  size="medium"
                  onClick={() =>
                    handleClickObservacaoItem(
                      item.id,
                      `${item.fornecedorProduto.produto.produtoEan} - ${item.fornecedorProduto.produto.produtoNome}`,
                      item.observacao
                    )
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Table.Cell>
              <Table.Cell>
                <IconButton
                  size="medium"
                  onClick={() => handleClickItemEdicao(item)}
                >
                  <EditIcon />
                </IconButton>
              </Table.Cell>
              <Table.Cell>
                <IconButton
                  size="medium"
                  onClick={() => handleClickDeleteItem(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>
          <Table.Row>
            <Table.Cell colSpan={5} />
            <Table.Cell>Total:</Table.Cell>
            <Table.Cell>{formatPrice(total)}</Table.Cell>
            <Table.Cell colSpan={3} />
          </Table.Row>
        </TableFooter>
      </Table.Table>
    </TableContainer>
  )
}

export default React.memo(TableItens)
