import React from 'react'
import {Switch} from 'react-router-dom'

import {Route} from 'components/Route'

import {AppConfig} from 'pages/Aplicativo/configuracoes'
import {AppEmpresa} from 'pages/Aplicativo/Empresas'
import {AppFilial} from 'pages/Aplicativo/Filiais'
import {AppHome} from 'pages/Aplicativo/Home'
import {AppLogin} from 'pages/Aplicativo/Login'
import {AppOpFinanceiro} from 'pages/Aplicativo/Opcoes/Financeiro'
import {AppOpOutros} from 'pages/Aplicativo/Opcoes/Outros'
import {AppOpProdutos} from 'pages/Aplicativo/Opcoes/Produtos'
import {AppOpVendas} from 'pages/Aplicativo/Opcoes/Vendas'
import {AppProduto} from 'pages/Aplicativo/Produtos/ProdutoBusca'
import {AppProdutoDetalhes} from 'pages/Aplicativo/Produtos/ProdutoDetalhes'
import {AppApuracaoImposto} from 'pages/Aplicativo/Relatorios/Financeiro/ApuracaoImposto'
import {AppContasPagar} from 'pages/Aplicativo/Relatorios/Financeiro/ContasPagar'
import {AppContasReceber} from 'pages/Aplicativo/Relatorios/Financeiro/ContasReceber'
import {AppContasRecebidas} from 'pages/Aplicativo/Relatorios/Financeiro/ContasRecebidas'
import {AppForaLimite} from 'pages/Aplicativo/Relatorios/Financeiro/ForaLimite'
import {AppDivergencia} from 'pages/Aplicativo/Relatorios/Outros/DivergenciaNF'
import {AppListaPromocoes} from 'pages/Aplicativo/Relatorios/Outros/ListaPromocoes'
import {AppItensPromocao} from 'pages/Aplicativo/Relatorios/Outros/ListaPromocoes/itens'
import {AppProdutosVencer} from 'pages/Aplicativo/Relatorios/Outros/ProdutosVencer'
import {AppSangriaSuprimento} from 'pages/Aplicativo/Relatorios/Outros/Sangrias'
import {AppVendasCanceladas} from 'pages/Aplicativo/Relatorios/Outros/VendasCanceladas'
import {AppTop10} from 'pages/Aplicativo/Relatorios/Vendas/Top10'
import {AppVendaDiaria} from 'pages/Aplicativo/Relatorios/Vendas/VendaDiaria'
import {AppVendaDiariaSL} from 'pages/Aplicativo/Relatorios/Vendas/VendaDiariaSL'
import {AppVendaFilial} from 'pages/Aplicativo/Relatorios/Vendas/VendaFilial'
import {AppVendaMensal} from 'pages/Aplicativo/Relatorios/Vendas/VendaMensal'
import {AppVendaMensalSL} from 'pages/Aplicativo/Relatorios/Vendas/VendaMensalSL'
import {AppVendaPeriodo} from 'pages/Aplicativo/Relatorios/Vendas/VendaPeriodo'
import {AppVendaAcougue} from 'pages/Aplicativo/Relatorios/Vendas/VendasAcougue'
import {AppVendaGrupo} from 'pages/Aplicativo/Relatorios/Vendas/VendasGrupo'
import {AppGrupos} from 'pages/Aplicativo/Relatorios/Vendas/VendasGrupo/grupos'
import {AppVendaHortifruti} from 'pages/Aplicativo/Relatorios/Vendas/VendasHortifruti'
import {AppToken} from 'pages/Aplicativo/Token'
import {Build} from 'pages/Build'
import {BuildPesquisa} from 'pages/BuildPesquisa'
import {CompraDireta} from 'pages/CompraDireta'
import {CompraDiretaEntrar} from 'pages/CompraDiretaEntrar'
import {Configuracao} from 'pages/Configuracao'
import {Cotacao} from 'pages/Cotacao'
import {CotacaoEntrar} from 'pages/CotacaoEntrar'
import {DownloadPage} from 'pages/DownloadPage'
import {DownloadPageEdicao} from 'pages/DownloadPageEdicao'
import {Empresa} from 'pages/Empresa'
import {EmpresaPesquisa} from 'pages/EmpresaPesquisa'
import {Entrar} from 'pages/Entrar'
import {Home} from 'pages/Home'
import {Licenca} from 'pages/Licenca'
import {LicencaPesquisa} from 'pages/LicencaPesquisa'
import {PedidoCompraDireta} from 'pages/PedidoCompraDireta'
import {RelatorioBot} from 'pages/RelatorioBot'
import {RelatorioBotPesquisa} from 'pages/RelatorioBotPesquisa'
import {Usuario} from 'pages/Usuario'
import {UsuarioPesquisa} from 'pages/UsuarioPesquisa'

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Entrar} />

      <Route exact path="/cotacao" component={CotacaoEntrar} />
      <Route exact path="/cotacao/:chave" component={Cotacao} />

      <Route exact path="/compraDireta" component={CompraDiretaEntrar} />
      <Route exact path="/compraDireta/:chave" component={CompraDireta} />
      <Route
        exact
        path="/compraDireta/:chave/pedido/:idPedido"
        component={PedidoCompraDireta}
      />

      <Route exact path="/app" component={AppToken} />
      <Route exact path="/app/login" component={AppLogin} />
      <Route exact path="/app/home" component={AppHome} />
      <Route exact path="/app/vendas" component={AppOpVendas} />
      <Route exact path="/app/opprodutos" component={AppOpProdutos} />
      <Route exact path="/app/outros" component={AppOpOutros} />
      <Route exact path="/app/financeiro" component={AppOpFinanceiro} />
      <Route exact path="/app/filial" component={AppFilial} />
      <Route exact path="/app/empresa" component={AppEmpresa} />
      <Route exact path="/app/config" component={AppConfig} />
      <Route exact path="/app/produto" component={AppProduto} />
      <Route exact path="/app/top10" component={AppTop10} />
      <Route exact path="/app/vendadiaria" component={AppVendaDiaria} />
      <Route exact path="/app/vendaperiodo" component={AppVendaPeriodo} />
      <Route exact path="/app/sangrias" component={AppSangriaSuprimento} />
      <Route exact path="/app/vendafilial" component={AppVendaFilial} />
      <Route exact path="/app/vendamensal" component={AppVendaMensal} />
      <Route exact path="/app/vendaacougue" component={AppVendaAcougue} />
      <Route exact path="/app/vendahortifruti" component={AppVendaHortifruti} />
      <Route exact path="/app/vendagrupo" component={AppVendaGrupo} />
      <Route exact path="/app/grupos" component={AppGrupos} />
      <Route exact path="/app/vendadiariasl" component={AppVendaDiariaSL} />
      <Route exact path="/app/vendamensalsl" component={AppVendaMensalSL} />
      <Route exact path="/app/apuracaoimposto" component={AppApuracaoImposto} />
      <Route exact path="/app/contaspagar" component={AppContasPagar} />
      <Route exact path="/app/contasreceber" component={AppContasReceber} />
      <Route exact path="/app/contasrecebidas" component={AppContasRecebidas} />
      <Route exact path="/app/foralimite" component={AppForaLimite} />
      <Route exact path="/app/divergencia" component={AppDivergencia} />
      <Route exact path="/app/listapromocoes" component={AppListaPromocoes} />
      <Route exact path="/app/itenspromocao" component={AppItensPromocao} />
      <Route exact path="/app/produtosvencer" component={AppProdutosVencer} />
      <Route exact path="/app/produtodetalhes" component={AppProdutoDetalhes} />
      <Route
        exact
        path="/app/vendascanceladas"
        component={AppVendasCanceladas}
      />

      <Route exact isPrivate path="/home" component={Home} />

      <Route exact isPrivate path="/configuracao" component={Configuracao} />

      <Route exact isPrivate path="/empresa" component={EmpresaPesquisa} />
      <Route exact isPrivate path="/empresa/:idEmpresa" component={Empresa} />

      <Route exact isPrivate path="/usuario" component={UsuarioPesquisa} />
      <Route exact isPrivate path="/usuario/:idUsuario" component={Usuario} />

      <Route exact isPrivate path="/licenca" component={LicencaPesquisa} />
      <Route exact isPrivate path="/licenca/:idLicenca" component={Licenca} />

      <Route
        exact
        isPrivate
        path="/relatorioBot"
        component={RelatorioBotPesquisa}
      />
      <Route
        exact
        isPrivate
        path="/relatorioBot/:idRelatorioBot"
        component={RelatorioBot}
      />

      <Route exact isPrivate path="/build" component={BuildPesquisa} />
      <Route exact isPrivate path="/build/:key" component={Build} />

      <Route
        exact
        isPrivate
        path="/downloadPage"
        component={DownloadPageEdicao}
      />
      <Route exact path="/download" component={DownloadPage} />
    </Switch>
  )
}
