import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import React from 'react'

import {AutenticacaoProvider} from 'contexts/autenticacao'
import {DialogoProvider} from 'contexts/dialogo'
import {TituloProvider} from 'contexts/titulo'
import {ToastProvider} from 'contexts/toast'

export const Providers: React.FC = ({children}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AutenticacaoProvider>
        <ToastProvider>
          <TituloProvider>
            <DialogoProvider>
              <CssBaseline />
              {children}
            </DialogoProvider>
          </TituloProvider>
        </ToastProvider>
      </AutenticacaoProvider>
    </MuiPickersUtilsProvider>
  )
}
