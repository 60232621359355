import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: 'left',
    fontSize: '17px',
    marginBottom: 15,
    fontFamily: 'Poppins, sans-serif',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '90vh'
  },
  paper: {
    alignSelf: 'flex-start',
    marginTop: -35,
    margin: 'auto',
    flexGrow: 1,
    padding: 20,
    maxWidth: 400,
    maxHeight: '75vh',
    overflowY: 'auto',
    alignItems: 'center',
    justifyContent: 'center',

  },
  touchable: {
    backgroundColor: '#224aea',
    borderRadius: 20,
    marginBottom: 5,
    margin: 'auto',
    width: '90%',
    alignSelf: 'flex-end',
    marginTop: 30,
    fontSize: 13,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  check: {
    marginBottom: 25
  },
  formControlLabel: {
    fontFamily: 'Poppins, sans-serif',
  }
}))

export default useStyles;
