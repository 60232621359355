import {Button as ButtonMui, ButtonProps} from '@material-ui/core'
import React from 'react'

export const Button: React.FC<ButtonProps> = ({children, ...props}) => {
  return (
    <ButtonMui fullWidth variant="contained" color="primary" {...props}>
      {children}
    </ButtonMui>
  )
}
