import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

import {Button} from 'components/Button'

interface DialogComfirmExclusaoPedidoProps {
  handleClickCancel: () => void
  handleClickConfirmExclusao: (idPedido: number) => void
  idPedido: number
}

export const DialogComfirmExclusaoPedido = ({
  handleClickCancel,
  handleClickConfirmExclusao,
  idPedido
}: DialogComfirmExclusaoPedidoProps) => {
  return (
    <Dialog open>
      <DialogTitle>Atenção</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>Deseja realmente excluir o pedido {idPedido}?</strong> <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel}>Cancelar</Button>
        <Button onClick={() => handleClickConfirmExclusao(idPedido)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
