import {Backdrop, Typography, useMediaQuery} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Toaster} from 'react-hot-toast'
import {useHistory, useParams} from 'react-router-dom'
import {useUpdateEffect} from 'react-use'
import {formatPrice} from 'util/format'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import Autocomplete from 'components/Autocomplete'
import {Button} from 'components/Button'
import DataPicker from 'components/DataPicker'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {NumericTaxtField} from 'components/MaskTextField/NumericTextField'
import {PriceTextField} from 'components/MaskTextField/PriceTextField'
import {TextField} from 'components/TextField'

import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IEmbalagemProdutoCompraDireta} from 'types/IEmbalagemProdutoCompraDireta'
import {IFornecedorCompraDireta} from 'types/IFornecedorCompraDireta'
import {IFornecedorProdutoCompraDireta} from 'types/IFornecedorProdutoCompraDireta'
import {IItemPedidoCompraDireta} from 'types/IItemPedidoCompraDireta'
import {
  IPedidoCompraDireta,
  StatusPedidoCompraDireta
} from 'types/IPedidoCompraDireta'
import {IUnidadeMedida} from 'types/IUnidadeMedida'

import {apiErroHandle} from 'helpers/erro'

import {DialogObservacaoItem} from './DialogObservacaoItem'
import {ListaItensMobile} from './ListaItensMobile'
import TableItens from './TableItens'

type UnidadeMedidaSelect = {
  idUnidadeMedida: number
  casasDecimais: number
  idEmbalagem?: number
  embalagem?: IEmbalagemProdutoCompraDireta
  unidadeMedida: IUnidadeMedida
  usaEmbalagem: boolean
  label: string
}

export type ItemObservacao = {
  idItem: number
  descricao: string
  observacao: string
}

const defaultPedido: IPedidoCompraDireta = {
  dataCriacao: new Date(),
  dataPrevisao: new Date(),
  dataUltimoEnvio: new Date(),
  fornecedorCompraDireta: {} as IFornecedorCompraDireta,
  idFornecedorCompraDireta: 0,
  itens: [],
  observacao: '',
  status: StatusPedidoCompraDireta.ABERTO,
  valorTotal: 0
}

export const PedidoCompraDireta = () => {
  /**
   * Hooks
   */
  const {chave, idPedido} = useParams<{chave: string; idPedido: string}>()
  const {error, success} = useToast()
  const {push} = useHistory()

  const matches = useMediaQuery('(min-width:750px)')

  /**
   * Memos
   */
  const mobile = useMemo(() => !matches, [matches])

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [fornecedorCompraDireta, setFornecedorCompraDireta] =
    useState<IFornecedorCompraDireta>()
  const [fornedecorProdutosCompraDireta, setFornecedorProdutosCompradireta] =
    useState<IFornecedorProdutoCompraDireta[]>([])
  const [produtoCompraDiretaSelecionado, setProdutoCompraDiretaSelecionado] =
    useState<IFornecedorProdutoCompraDireta | null>(null)
  const [unidadeMedidaOptions, setUnidadeMedidaOptions] = useState<
    UnidadeMedidaSelect[]
  >([])
  const [unidadeMedidaSelecionada, setUnidadeMedidaSelecionada] =
    useState<UnidadeMedidaSelect | null>(null)
  const [quantidade, setQuantidade] = useState(0)
  const [preco, setPreco] = useState('0')
  const [pedidoCompraDireta, setPedidoCompraDireta] =
    useState<IPedidoCompraDireta>(defaultPedido)
  const [typeFormItem, setTypeFormItem] = useState<'ADD' | 'EDIT'>('ADD')
  const [idItemEdicao, setIdItemEdicao] = useState(0)
  const [idEmbalagemAtualItemEdicao, setIdEmbalagemAtualItemEdicao] =
    useState(0)
  const [isShowDialogObservacao, setIsShowDialogObservacao] = useState(false)
  const [itemObservacao, setItemObservacao] = useState<ItemObservacao | null>(
    null
  )

  /**
   * Memos
   */
  useUpdateEffect(() => {
    const unidadesMedidas: UnidadeMedidaSelect[] = []

    if (!produtoCompraDiretaSelecionado) {
      setUnidadeMedidaOptions([])
      return
    }

    unidadesMedidas.push({
      usaEmbalagem: false,
      unidadeMedida: produtoCompraDiretaSelecionado.produto.unidadeMedida,
      idUnidadeMedida: produtoCompraDiretaSelecionado.produto.idUnidadeMedida,
      label: produtoCompraDiretaSelecionado.produto.unidadeMedida.codigo,
      casasDecimais:
        produtoCompraDiretaSelecionado.produto.unidadeMedida.casasDecimais
    })

    for (const embalagem of produtoCompraDiretaSelecionado.produto.embalagens) {
      unidadesMedidas.push({
        usaEmbalagem: true,
        idUnidadeMedida: embalagem.idUnidadeMedida,
        unidadeMedida: embalagem.unidadeMedida,
        embalagem: embalagem,
        idEmbalagem: embalagem.id,
        label: `${embalagem.unidadeMedida.codigo}(${Number(
          embalagem.fatorConversao
        ).toFixed(
          produtoCompraDiretaSelecionado.produto.unidadeMedida.casasDecimais
        )})`,
        casasDecimais: embalagem.unidadeMedida.casasDecimais
      })
    }

    if (idEmbalagemAtualItemEdicao !== 0) {
      const unidadeMedida = unidadesMedidas.find(
        (un) => un.idEmbalagem === idEmbalagemAtualItemEdicao
      )
      setUnidadeMedidaSelecionada(unidadeMedida ?? unidadesMedidas[0])
    } else {
      setUnidadeMedidaSelecionada(unidadesMedidas[0])
    }
    setUnidadeMedidaOptions(unidadesMedidas)
  }, [produtoCompraDiretaSelecionado, idEmbalagemAtualItemEdicao])

  const carregarFornecedorProdutosCompraDireta = useCallback(async () => {
    if (!fornecedorCompraDireta?.id) return

    try {
      const {data} = await api.get<IFornecedorProdutoCompraDireta[]>(
        `/fornecedorProdutosCompraDireta/${fornecedorCompraDireta.id}`
      )

      setFornecedorProdutosCompradireta(data)
    } catch (err) {
      push('/compraDireta')

      throw err
    }
  }, [fornecedorCompraDireta?.id, push])

  const carregarFornecedorCompraDireta = useCallback(async () => {
    try {
      const {data} = await api.get<IFornecedorCompraDireta>(
        `/fornecedorCompraDireta/${chave}`
      )

      setFornecedorCompraDireta(data)
      setPedidoCompraDireta({
        ...pedidoCompraDireta,
        idFornecedorCompraDireta: data.id
      })
    } catch (err) {
      push('/compraDireta')

      throw err
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chave, push])

  const carregarDadosPedido = useCallback(async () => {
    if (idPedido === 'new') return

    const {data} = await api.get<IPedidoCompraDireta>(
      `/pedidoCompraDireta/${idPedido}`
    )

    setPedidoCompraDireta(data)
  }, [idPedido])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        carregarFornecedorCompraDireta(),
        carregarFornecedorProdutosCompraDireta(),
        carregarDadosPedido()
      ])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [
    carregarDadosPedido,
    carregarFornecedorCompraDireta,
    carregarFornecedorProdutosCompraDireta,
    error
  ])

  const resetForm = useCallback(() => {
    setTypeFormItem('ADD')
    setProdutoCompraDiretaSelecionado(null)
    setUnidadeMedidaSelecionada(null)
    setPreco('0')
    setQuantidade(0)
    setIdItemEdicao(0)
    setIdEmbalagemAtualItemEdicao(0)
  }, [])

  const handleClickConfirmarPedido = useCallback(async () => {
    try {
      setLoading(true)
      await api.put<IPedidoCompraDireta>(
        `/pedidoCompraDireta/${pedidoCompraDireta.id}`,
        pedidoCompraDireta
      )

      success(`Pedido número ${pedidoCompraDireta.id} editado com sucesso!`)

      push(`/compraDireta/${chave}`)
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [chave, error, pedidoCompraDireta, push, success])

  const criarPedidoCompraDireta = useCallback(async (): Promise<number> => {
    const {data} = await api.post<IPedidoCompraDireta>(
      `/pedidoCompraDireta`,
      pedidoCompraDireta
    )

    return data.id
  }, [pedidoCompraDireta])

  const criarPedidoCompraDiretaItem = useCallback(
    async (
      idPedido: number,
      item: IItemPedidoCompraDireta
    ): Promise<{total: number; itemCriado: IItemPedidoCompraDireta}> => {
      const {data} = await api.post<{
        total: number
        itemCriado: IItemPedidoCompraDireta
      }>(`/pedidoCompraDireta/${idPedido}/item`, item)

      return {...data}
    },
    []
  )

  const atualizarPedidoCompraDiretaItem = useCallback(
    async (
      idPedido: number,
      item: IItemPedidoCompraDireta
    ): Promise<{total: number; itemAtualizado: IItemPedidoCompraDireta}> => {
      const {data} = await api.put<{
        total: number
        itemAtualizado: IItemPedidoCompraDireta
      }>(`/pedidoCompraDireta/${idPedido}/item/${idItemEdicao}`, item)

      return {...data}
    },
    [idItemEdicao]
  )

  const atualizarObservacaoPedidoCompraDiretaItem = useCallback(
    async (novaObservacao: string) => {
      const {data} = await api.put(
        `/pedidoCompraDireta/${pedidoCompraDireta.id}/item/${itemObservacao?.idItem}/atualizarObservacao`,
        {observacao: novaObservacao}
      )

      return {...data}
    },
    [itemObservacao?.idItem, pedidoCompraDireta.id]
  )

  const deletarPedidoCompraDiretaItem = useCallback(
    async (idItem: number): Promise<{total: number}> => {
      const {data} = await api.delete<{
        total: number
      }>(`/pedidoCompraDireta/${pedidoCompraDireta.id}/item/${idItem}`)

      return {...data}
    },
    [pedidoCompraDireta.id]
  )

  const handleClickConfirmObservacaoItem = useCallback(
    async (novaObservacao: string) => {
      try {
        setLoading(true)

        await atualizarObservacaoPedidoCompraDiretaItem(novaObservacao)

        setPedidoCompraDireta({
          ...pedidoCompraDireta,
          itens: pedidoCompraDireta.itens.map((item) =>
            item.id === itemObservacao?.idItem
              ? {...item, observacao: novaObservacao}
              : item
          )
        })

        setItemObservacao(null)
        setIsShowDialogObservacao(false)
        success('Observação atualizada com sucesso!')
      } catch (err) {
        error(apiErroHandle(err))
      } finally {
        setLoading(false)
      }
    },
    [
      atualizarObservacaoPedidoCompraDiretaItem,
      error,
      itemObservacao?.idItem,
      pedidoCompraDireta,
      success
    ]
  )

  const handleClickObservacaoItem = useCallback(
    (idItem: number, descricao: string, observacao: string) => {
      setItemObservacao({
        idItem,
        descricao,
        observacao
      })

      setIsShowDialogObservacao(true)
    },
    []
  )

  const handleClickDeleteItem = useCallback(
    async (idItem: number) => {
      resetForm()

      try {
        setLoading(true)

        const {total} = await deletarPedidoCompraDiretaItem(idItem)

        const itens: IItemPedidoCompraDireta[] = []
        pedidoCompraDireta.itens.forEach((item) => {
          if (item.id !== idItem) itens.push(item)
        })

        setPedidoCompraDireta({
          ...pedidoCompraDireta,
          valorTotal: total,
          itens
        })

        success('Item removido com sucesso!')
      } catch (err) {
        error(apiErroHandle(err))
      } finally {
        setLoading(false)
      }
    },
    [
      deletarPedidoCompraDiretaItem,
      error,
      pedidoCompraDireta,
      resetForm,
      success
    ]
  )

  const handleClickEditItem = useCallback((item: IItemPedidoCompraDireta) => {
    setTypeFormItem('EDIT')
    setProdutoCompraDiretaSelecionado(item.fornecedorProduto)
    setPreco(String(item.custo))
    setQuantidade(item.quantidade)
    setIdItemEdicao(item.id)
    setIdEmbalagemAtualItemEdicao(item.usaEmbalagem ? item.idEmbalagem ?? 0 : 0)
  }, [])

  const handleClickConfirmItem = useCallback(async () => {
    if (!produtoCompraDiretaSelecionado) {
      error('Informe o produto')
      return
    }
    if (!unidadeMedidaSelecionada) {
      error('Informe a unidade de medida')
      return
    }
    if (Number(preco) <= 0) {
      error('Preço deve ser maior que 0')
      return
    }
    if (quantidade <= 0) {
      error('Quantidade deve ser maior que 0')
      return
    }

    let idPedidoCompraDireta = pedidoCompraDireta.id

    try {
      setLoading(true)

      if (!idPedidoCompraDireta) {
        idPedidoCompraDireta = await criarPedidoCompraDireta()
      }

      const itemPayload: IItemPedidoCompraDireta = {
        custo: Number(preco),
        quantidade: quantidade,
        observacao: '',
        fornecedorProduto: produtoCompraDiretaSelecionado,
        idFornecedorProdutoCompraDireta: produtoCompraDiretaSelecionado.id,
        pedidoCompraDireta,
        usaEmbalagem: unidadeMedidaSelecionada.usaEmbalagem,
        idEmbalagem: unidadeMedidaSelecionada.idEmbalagem,
        idPedidoCompraDireta,
        idUnidadeMedida: unidadeMedidaSelecionada.idUnidadeMedida,
        unidadeMedida: unidadeMedidaSelecionada.unidadeMedida,
        embalagem: unidadeMedidaSelecionada.embalagem
      }

      if (typeFormItem === 'ADD') {
        const {total, itemCriado} = await criarPedidoCompraDiretaItem(
          idPedidoCompraDireta,
          itemPayload
        )

        success('Item adicionado com sucesso!')

        setPedidoCompraDireta({
          ...pedidoCompraDireta,
          id: idPedidoCompraDireta,
          valorTotal: total,
          itens: [...pedidoCompraDireta.itens, itemCriado]
        })

        if (idPedido === 'new') {
          resetForm()
          push(`/compraDireta/${chave}/pedido/${idPedidoCompraDireta}`)
          return
        }
      }

      if (typeFormItem === 'EDIT') {
        const {total, itemAtualizado} = await atualizarPedidoCompraDiretaItem(
          idPedidoCompraDireta,
          itemPayload
        )

        setPedidoCompraDireta({
          ...pedidoCompraDireta,
          id: idPedidoCompraDireta,
          valorTotal: total,
          itens: pedidoCompraDireta.itens.map((item) =>
            item.id === idItemEdicao ? itemAtualizado : item
          )
        })

        success('Item atualizado com sucesso!')
      }

      resetForm()
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [
    atualizarPedidoCompraDiretaItem,
    chave,
    criarPedidoCompraDireta,
    criarPedidoCompraDiretaItem,
    error,
    idItemEdicao,
    idPedido,
    pedidoCompraDireta,
    preco,
    produtoCompraDiretaSelecionado,
    push,
    quantidade,
    resetForm,
    success,
    typeFormItem,
    unidadeMedidaSelecionada
  ])

  /**
   * Effects
   */
  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Toaster />
      <Typography>
        Cliente:{' '}
        {`${fornecedorCompraDireta?.empresa?.cnpj} - ${fornecedorCompraDireta?.empresa?.nome}`}
      </Typography>

      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleClickConfirmarPedido}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={() => push(`/compraDireta/${chave}`)}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={mobile ? 12 : 10}>
          <TextField
            label="Observação do pedido"
            value={pedidoCompraDireta.observacao}
            multiline
            maxRows={3}
            onChange={(e) => {
              setPedidoCompraDireta({
                ...pedidoCompraDireta,
                observacao: e.target.value
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={mobile ? 12 : 2}>
          <DataPicker
            value={pedidoCompraDireta.dataPrevisao}
            label="Previsão de entrega"
            onChange={(e) => {
              setPedidoCompraDireta({
                ...pedidoCompraDireta,
                dataPrevisao: e
              })
            }}
          />
        </Grid.Item>
      </Grid.Container>

      <Grid.Container>
        <Grid.Item xs={mobile ? 12 : 6}>
          <Autocomplete
            label="Produto"
            value={produtoCompraDiretaSelecionado}
            getOptionLabel={(option) => {
              const label =
                option.produto.produtoEan.trim().length > 0
                  ? `${option.produto.produtoEan} - ${option.produto.produtoNome}`
                  : option.produto.produtoNome

              return option.referencia?.trim().length > 0
                ? label + ` - Ref. ${option.referencia}`
                : label
            }}
            onChange={(e, value: IFornecedorProdutoCompraDireta | null) => {
              if (!value) setUnidadeMedidaSelecionada(null)
              setProdutoCompraDiretaSelecionado(value)
            }}
            options={fornedecorProdutosCompraDireta}
          />
        </Grid.Item>
        <Grid.Item xs={mobile ? 6 : 1}>
          <Autocomplete
            label="UN"
            getOptionLabel={(option) => option.label}
            value={unidadeMedidaSelecionada}
            onChange={(e, value: UnidadeMedidaSelect | null) => {
              setUnidadeMedidaSelecionada(value)
            }}
            options={unidadeMedidaOptions}
          />
        </Grid.Item>
        <Grid.Item xs={mobile ? 6 : 1}>
          <NumericTaxtField
            decimalScale={unidadeMedidaSelecionada?.casasDecimais ?? 0}
            fixedDecimalScale
            label="Qtd"
            value={quantidade}
            onChange={(e) => setQuantidade(Number(e.target.value))}
          />
        </Grid.Item>
        <Grid.Item xs={mobile ? 6 : 1}>
          <PriceTextField
            label={
              !!unidadeMedidaSelecionada
                ? `Preço ${unidadeMedidaSelecionada.label}`
                : 'Preço'
            }
            value={preco}
            onChange={(e) => setPreco(e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={mobile ? 6 : 1}>
          <Button onClick={handleClickConfirmItem}>
            {typeFormItem === 'ADD' ? 'Adicionar' : 'Editar'}
          </Button>
        </Grid.Item>
      </Grid.Container>

      {mobile ? (
        <>
          <strong>Total do pedido: </strong>
          {formatPrice(pedidoCompraDireta.valorTotal)}
          <ListaItensMobile
            handleClickObservacaoItem={handleClickObservacaoItem}
            handleClickDeleteItem={handleClickDeleteItem}
            handleClickItemEdicao={handleClickEditItem}
            itens={pedidoCompraDireta.itens}
          />
          <strong>Total do pedido: </strong>
          {formatPrice(pedidoCompraDireta.valorTotal)}
        </>
      ) : (
        <TableItens
          handleClickObservacaoItem={handleClickObservacaoItem}
          handleClickDeleteItem={handleClickDeleteItem}
          handleClickItemEdicao={handleClickEditItem}
          total={pedidoCompraDireta.valorTotal}
          itens={pedidoCompraDireta.itens}
        />
      )}

      {isShowDialogObservacao && (
        <DialogObservacaoItem
          handleConfirmClick={handleClickConfirmObservacaoItem}
          handleCancelClick={() => {
            setItemObservacao(null)
            setIsShowDialogObservacao(false)
          }}
          itemObservacao={itemObservacao}
        />
      )}
    </>
  )
}
