import {makeStyles} from '@material-ui/core'

export const useClasses = makeStyles({
  paper: {
    padding: 10
  },
  dateText: {marginBottom: 20},
  confirmarPreenchimento: {
    margin: 10
  }
})
