import {Card, CardContent, TextField} from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../../components/DrawerApp/index'
import useStyles from './style'

interface LocationState {
  productItens: ProductDetail[]
}

interface ProductDetail {
  produto: string
  filial: number
  custo: number
  preco: number
  precoPromocao: number
  quantidadeVenda: number
  desconto: number
  margem: number
  percentualPromocao: number
}

export const AppItensPromocao: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const location = useLocation<LocationState>()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const [products, setProducts] = useState<ProductDetail[]>([])
  const [socketConnected, setSocketConnected] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredProducts, setFilteredProducts] = useState<ProductDetail[]>([])

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  useEffect(() => {
    if (products) {
      const filtered = products.filter((product) =>
        product.produto.toLowerCase().includes(searchValue.toLowerCase())
      )

      setFilteredProducts(filtered)
    }
  }, [searchValue, products])

  const handleChangeS = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchValue(event.target.value as string)
  }

  useEffect(() => {
    if (location.state && location.state.productItens) {
      setProducts(location.state.productItens)
    }
  }, [location.state])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          label="Buscar..."
          margin="normal"
          variant="outlined"
          onChange={handleChangeS}
          className={classes.search2}
          InputProps={{
            type: 'search',
            value: searchValue,
            color: 'primary'
          }}
        />
        {products.length === 0 && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}
        {filteredProducts.length > 0 && (
          <div>
            {filteredProducts.map((item: ProductDetail, index: number) => (
              <Card key={index} className={classes.card}>
                <CardContent>
                  <h3 className={classes.p}>{item.produto}</h3>
                  <p className={classes.p}>
                    Filial: {item.filial || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Custo: {formatPrice(Number(item.custo)) || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Preço: {formatPrice(Number(item.preco)) || 0}
                  </p>
                  <p className={classes.p}>
                    Preço Promoção: {item.precoPromocao || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Quantidade Vendas: {item.quantidadeVenda || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Desconto:{' '}
                    {formatPrice(Number(item.desconto)) || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Margem: {Number(item.margem?.toFixed(2)) || 'Não informado'}
                    %
                  </p>
                  <p className={classes.p}>
                    % Part. Promoção:{' '}
                    {item.percentualPromocao?.toFixed(2) || 'Não informado'}%
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </Paper>
    </GridContainer>
  )
}
