import {FormControlLabel, Switch} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Button} from 'components/Button'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IConfiguracao} from 'types/IConfiguracao'

import {apiErroHandle} from 'helpers/erro'

export const Configuracao: React.FC = () => {
  /**
   * Hooks
   */
  const {error, success} = useToast()
  const {alterarTitulo} = useTitulo()
  const {push} = useHistory()

  /**
   * Consts
   */
  const CONFIGURACAO = {} as IConfiguracao

  /**
   * States
   */
  const [loading, setLoading] = useState(false)

  const [configuracao, setConfiguracao] = useState(CONFIGURACAO)

  /**
   * Callbacks
   */
  const carregarConfiguracao = useCallback(async () => {
    const {data} = await api.get('/configuracao')

    setConfiguracao(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarConfiguracao()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarConfiguracao, error])

  const atualizarConfiguracao = useCallback(async () => {
    await api.put('/configuracao', configuracao)
  }, [configuracao])

  /**
   * Handles
   */
  const handleCancelar = useCallback(() => {
    push('/')
  }, [push])

  const handleConfirmar = useCallback(async () => {
    try {
      setLoading(true)

      await atualizarConfiguracao()

      success('Configuração salva')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [atualizarConfiguracao, error, success])

  const handleTestarEmail = useCallback(async () => {
    try {
      setLoading(true)

      await api.post('/configuracao/emailTeste')

      success('Email válido')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [error, success])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Configurações')
  }, [alterarTitulo])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleConfirmar}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={6}>
          <TextField
            label="E-mail"
            value={configuracao.emailUsuario}
            onChange={(e) =>
              setConfiguracao({...configuracao, emailUsuario: e.target.value})
            }
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TextField
            label="Senha"
            type="password"
            value={configuracao.emailSenha}
            onChange={(e) =>
              setConfiguracao({...configuracao, emailSenha: e.target.value})
            }
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TextField
            label="Servidor SMTP"
            value={configuracao.emailHost}
            onChange={(e) =>
              setConfiguracao({...configuracao, emailHost: e.target.value})
            }
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TextField
            label="Porta"
            value={configuracao.emailPorta}
            onChange={(e) =>
              setConfiguracao({
                ...configuracao,
                emailPorta: Number(e.target.value)
              })
            }
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={configuracao.emailUsarTls}
                onChange={(e) =>
                  setConfiguracao({
                    ...configuracao,
                    emailUsarTls: e.target.checked
                  })
                }
              />
            }
            label="Usar TLS"
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <Button onClick={handleTestarEmail}>Testar e-mail</Button>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
