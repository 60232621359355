import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import React, {useState, useEffect} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../components/DrawerApp/index'
import useStyles from './style'

interface Branch {
  id: string
  codigo: string
  razaosocial: string
  nome: string
  cnpj: string
  selected?: boolean
}

export const AppFilial: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [branches, setBranches] = useState<Branch[]>([])
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [socketConnected, setSocketConnected] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const token = localStorage.getItem(TOKEN_APP_ID)
  const socket = getSocket()
  const filiais = localStorage.getItem(FILIAL_APP)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchFiliais = async () => {
    if (!token) return

    socket.emit('filtroBranchApp', {
      message: {room: token}
    })

    socket.on('branchDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        const initialBranches = dados.map((branch: any) => {
          return {
            ...branch,
            selected: filiais && filiais.includes(branch.id)
          }
        })
        setBranches(initialBranches)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        console.log('')
      }
    })
  }

  useEffect(() => {
    setSelectAll(branches.length > 0 && branches.length === checkedIds.length)
  }, [branches, checkedIds])

  useEffect(() => {
    if (socketConnected) {
      fetchFiliais()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  useEffect(() => {
    // Verifica se há valores armazenados em localStorage
    if (filiais) {
      const filiaisArray = filiais.split(',')
      setCheckedIds(filiaisArray)
    }
  }, [filiais])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    branch: Branch
  ) => {
    if (event.target.checked) {
      setCheckedIds([...checkedIds, branch.id])
    } else {
      setCheckedIds(checkedIds.filter((id) => id !== branch.id))
      setSelectAll(false)
    }
  }

  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  const handleSave = async (): Promise<void> => {
    const filiaisId: string = checkedIds.join(',')

    if (checkedIds.length > 0 && checkedIds) {
      localStorage.setItem(FILIAL_APP, filiaisId)
      alert('Salvo com sucesso!')
      handleButtonClick('/app/home')
    } else {
      alert('Atenção! Você deve selecionar pelo menos uma filial.')
    }
  }

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      setCheckedIds(branches.map((branch) => branch.id))
    } else {
      setCheckedIds([])
    }
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <FormControl component="fieldset">
          <Typography variant="body1" className={classes.label}>
            SELECIONE AS FILIAIS:
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  name="selectAll"
                />
              }
              label="SELECIONAR TODOS"
              classes={{label: classes.formControlLabel}}
            />
            <br></br>
            {branches.map((branch) => (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    checked={checkedIds.includes(branch.id)}
                    onChange={(event) => handleChange(event, branch)}
                    name={branch.id}
                  />
                }
                label={`${branch.nome} - ${branch.cnpj}`}
                key={branch.id}
                classes={{label: classes.formControlLabel}}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Paper>
      <Button className={classes.touchable} onClick={handleSave}>
        Salvar
      </Button>
    </GridContainer>
  )
}
