import {TextFieldProps} from '@material-ui/core'
import React from 'react'
import MaskedInput from 'react-text-mask'

import {TextField} from 'components/TextField'

const Cnpj: React.FC = (props: any) => {
  const {inputRef, ...other} = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '/',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/
      ]}
      placeholderChar={'_'}
    />
  )
}

export const CnpjMaskTextField: React.FC<TextFieldProps> = ({...props}) => {
  return <TextField {...props} InputProps={{inputComponent: Cnpj}} />
}
