import React from 'react'
import {Route as RouteDom, Redirect, RouteProps} from 'react-router-dom'

import {useAutenticacao} from 'contexts/autenticacao'

import {PrivateLayout} from 'pages/_layouts/private'
import {PublicLayout} from 'pages/_layouts/public'

interface RouteWrapperProps extends RouteProps {
  isPrivate?: boolean
}

export const Route: React.FC<RouteWrapperProps> = ({
  component = () => <></>,
  isPrivate = false,
  ...rest
}) => {
  const {autenticado} = useAutenticacao()
  const Component = component

  if (!autenticado && isPrivate) {
    return <Redirect to="/" />
  }

  if (autenticado && rest.path === '/') {
    return <Redirect to="/home" />
  }

  const Layout = autenticado ? PrivateLayout : PublicLayout

  return (
    <RouteDom
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}
