import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {apiErroHandle} from 'helpers/erro'

export const Build: React.FC = () => {
  /**
   * Hooks
   */
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()
  const {push} = useHistory()

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [build, setBuild] = useState<File | null>(null)

  /**
   * Handles
   */
  const handleCancelar = useCallback(() => {
    push('/build')
  }, [push])

  const handleConfirmar = useCallback(async () => {
    if (!build) return

    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('file', build)

      await api.post('/build', formData)

      push('/build')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [build, error, push])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Build')
  }, [alterarTitulo])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleConfirmar}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={12}>
          <TextField
            label="Build"
            type="file"
            inputProps={{accept: '.zip'}}
            onChange={(e: any) => setBuild(e.target.files[0])}
          />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
