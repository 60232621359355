import {
  TableContainer as TableContainerMui,
  TableContainerProps
} from '@material-ui/core'
import React, {useEffect, useRef, useState} from 'react'
import {useWindowSize} from 'react-use'

export const TableContainer: React.FC<TableContainerProps> = ({
  children,
  ...props
}) => {
  /**
   * Hooks
   */
  const {height} = useWindowSize()

  /**
   * State
   */
  const [containerHeight, setContainerHeight] = useState(0)

  /**
   * Refs
   */
  const containerRef = useRef<HTMLDivElement>(null)

  /**
   *Effects
   */
  useEffect(() => {
    const offsetTop = containerRef?.current?.offsetTop

    const offset = offsetTop || 0

    setContainerHeight(height - offset - 10)
  }, [height])

  /**
   * Returns
   */
  return (
    <TableContainerMui
      component="div"
      ref={containerRef}
      style={{height: containerHeight}}
      {...props}
    >
      {children}
    </TableContainerMui>
  )
}
