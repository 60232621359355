import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#f5f2f2',
    width: '100%'
  },
  paper: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -30,
    height: '100%',
    margin: '0 10px',
    backgroundColor: '#f5f2f2',
    width: 'calc(100% - 20px)'
  },
  search: {
    width: '100%',
    marginBottom: 20,
    marginTop: 10,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20,
    width: '100%'
  },
  card: {
    marginTop: 15,
    minWidth: 200,
    minHeight: 120,
    borderRadius: 15,
    width: '100%',
    backgroundColor: '#f5f5f5',
    border: 15,
    textAlign: 'center'
  },
  label: {
    textAlign: 'left',
    fontSize: '17px',
    marginBottom: 15,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 240,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  cardContentLeft: {
    textAlign: 'left'
  },
  textField: {
    width: '100%',
    marginBottom: 10,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  p: {
    fontSize: 15,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  menuItem: {
    fontSize: 13,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  }
}));

export default useStyles;
