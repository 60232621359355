import InputLabel from '@material-ui/core/InputLabel'
import React, {useState, useEffect} from 'react'
import {useHistory, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, connectSocket, disconnectSocket} from 'socket'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Loading} from 'components/Loading'
import {Paper} from 'components/Paper'
import {TextField} from 'components/TextField'

import logo from '../../../assets/logo.png'
import useStyles from './style'

interface Branch {
  apelido: string
  cnpj: string
  token: string
}

export const AppToken: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const socket = getSocket()

  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState<Branch[]>([])
  const [cnpj, setcnpj] = useState('')
  const [token, settoken] = useState('')
  const [error, setError] = useState<string | null>(null)

  const TOKEN_APP = '@TOKEN_APP'
  const CNPJ_APP = '@CNPJ_APP'
  const TOKENS_APP_EXTRA = '@TOKENS_APP_EXTRA'

  const tokenApp = localStorage.getItem(TOKEN_APP)
  const cnpjApp = localStorage.getItem(CNPJ_APP)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  if (cnpjApp && tokenApp) {
    return <Redirect to="/app/empresa" />
  }

  const formatCnpj = (cnpj: string): string => {
    let formattedCnpj = cnpj.replace(/\D/g, '')
    formattedCnpj = formattedCnpj.replace(/(\d{2})(\d)/, '$1.$2')
    formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1.$2')
    formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1/$2')
    formattedCnpj = formattedCnpj.replace(/(\d{4})(\d)/, '$1-$2')
    return formattedCnpj
  }

  const newBranch: Branch = {
    apelido: 'Matriz',
    cnpj: cnpj,
    token: token
  }

  const handleSaveAndJoinRoom = async (): Promise<void> => {
    setError(null)
    if (cnpj && token) {
      if (cnpj?.length < 18) {
        setError('CNPJ Inválido.')
        return
      }

      joinRoom(token)

      try {
        let receivedResponse = false

        socket.emit('filtroCompanyApp', {
          message: {room: token, cnpj: cnpj, token: token}
        })

        const timer = setTimeout(() => {
          if (!receivedResponse) {
            setError('Erro - Verifique a conexão com o servidor!')
          }
        }, 3000)

        socket.on('companyDadosApp', async (data: any) => {
          receivedResponse = true
          clearTimeout(timer)
          const results = data.dados

          if (results === true) {
            localStorage.setItem(TOKEN_APP, token)
            localStorage.setItem(CNPJ_APP, cnpj)
            setBranches((prevState) => [...prevState, newBranch])

            localStorage.setItem(
              TOKENS_APP_EXTRA,
              JSON.stringify([...branches, newBranch])
            )
            handleButtonClick('/app/empresa')
            // disconnectSocket(token)
          } else {
            setError('CNPJ e/ou Token incorretos.')
          }
        })
      } catch (error) {
        console.error('Error saving token:', error)
        setError('Erro ao processar a solicitação.')
      }
    } else {
      setError('Preencha todos os campos.')
    }
  }

  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  const handleCnpjInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const text = event.target.value
    if (text.length <= 18) {
      let formattedCnpj = formatCnpj(text)
      setcnpj(formattedCnpj)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <Paper className={classes.paper}>
        <GridContainer>
          <GridItem xs={12}>
            <img src={logo} className={classes.logo} alt="Logo" />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              required
              variant="standard"
              label="CNPJ"
              value={cnpj}
              onChange={handleCnpjInputChange}
              className={classes.textField}
            />
          </GridItem>

          <GridItem xs={12}>
            <TextField
              required
              variant="standard"
              label="Token"
              value={token}
              onChange={(e) => settoken(e.target.value)}
              className={classes.textField}
            />
          </GridItem>

          <GridItem xs={12}>
            <InputLabel
              className={classes.label}
              id="demo-controlled-open-select-label"
            >
              {error}
            </InputLabel>
          </GridItem>
          <GridItem xs={12}>
            <Button
              className={classes.touchable}
              onClick={handleSaveAndJoinRoom}
            >
              Entrar
            </Button>
          </GridItem>
        </GridContainer>
      </Paper>
    </GridContainer>
  )
}
