import React, {useCallback, useEffect, useState} from 'react'

import {Loading} from 'components/Loading'

import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IDownloadPage} from 'types/IDownloadPage'

import {apiErroHandle} from 'helpers/erro'

import {useClasses} from './styles'

import './styles.css'

export const DownloadPage: React.FC = () => {
  /**
   * Hooks
   */
  const {error} = useToast()
  const classes = useClasses()

  /**
   * Consts
   */
  const DOWNLOAD_PAGE = {texto: ''} as IDownloadPage

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [downloadPage, setDownloadPage] = useState(DOWNLOAD_PAGE)

  /**
   * Callbacks
   */
  const carregarDownloadPage = useCallback(async () => {
    const {data} = await api.get('/downloadPage')
    setDownloadPage(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarDownloadPage()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarDownloadPage, error])

  /**
   * Effects
   */
  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <section
      className={classes.section}
      dangerouslySetInnerHTML={{__html: downloadPage.texto}}
    />
  )
}
