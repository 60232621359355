import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

const initialDate = new Date()
initialDate.setHours(0, 0, 0, 0)

export const AppSangriaSuprimento: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
  const [socketConnected, setSocketConnected] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string>('1-1')
  const [selectedDate, setSelectedDate] = useState(
    initialDate.toISOString().split('T')[0]
  )

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchFiltro = async () => {
    socket.emit('filtroPdvSangriaApp', {
      message: {
        room: token
      }
    })
    socket.on('pdvSangriaDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setData(dados)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      }
    })
  }

  const fetchItems = async () => {
    setLoading(true)
    socket.emit('filtroItensSangriaApp', {
      message: {
        room: token,
        data: selectedDate,
        pdv: selectedValue
      }
    })
    socket.on('itensSangriaDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setItems(dados)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value)
  }

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedValue(event.target.value as string)
  }

  useEffect(() => {
    if (socketConnected) {
      fetchFiltro()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  useEffect(() => {
    if (socketConnected) {
      fetchItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedDate, selectedValue])

  function formatData(dataOriginal: string): string {
    const dataHoraObj: Date = new Date(dataOriginal)

    const dia: number = dataHoraObj.getUTCDate()
    const mes: number = dataHoraObj.getUTCMonth() + 1
    const ano: number = dataHoraObj.getUTCFullYear()

    const dataFormatada: string = `${dia < 10 ? '0' : ''}${dia}-${
      mes < 10 ? '0' : ''
    }${mes}-${ano}`

    return dataFormatada
  }

  const formatTime = (datetime: string | undefined): string => {
    if (!datetime) {
      return 'Sem informação.'
    }

    const dataHoraObj: Date = new Date(datetime)

    const timeZoneOffset = -3
    dataHoraObj.setHours(dataHoraObj.getUTCHours() + timeZoneOffset)

    const horas: number = dataHoraObj.getHours()
    const minutos: number = dataHoraObj.getMinutes()
    const segundos: number = dataHoraObj.getSeconds()

    const horaFormatada: string = `${horas < 10 ? '0' : ''}${horas}:${
      minutos < 10 ? '0' : ''
    }${minutos}:${segundos < 10 ? '0' : ''}${segundos}`

    return horaFormatada
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          id="date"
          label="Data"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={selectedValue}
          onChange={handleChange}
          className={classes.search}
        >
          {data.map((item) => (
            <MenuItem
              className={classes.menuItem}
              key={`${item.filial}-${item.pdv}`}
              value={`${item.filial}-${item.pdv}`}
            >
              {`PDV ${item.pdv} - ${item.nome}`}
            </MenuItem>
          ))}
        </Select>
        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : !items || items.length === 0 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          items.map((result, index) => (
            <Card key={index} className={classes.card}>
              <CardContent className={classes.cardContentLeft}>
                <h3 className={classes.p}>
                  {result?.tipo || 'Sem informação.'}
                </h3>
                <p className={classes.p}>
                  Filial: {result?.filial || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  PDV: {result?.pdv || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Data: {formatData(result?.data) || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Hora: {formatTime(result?.horafinal) || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Usuário: {result?.nome || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Valor Bruto:{' '}
                  {formatPrice(Number(result?.valorbruto)) || 'Sem informação.'}
                </p>
              </CardContent>
            </Card>
          ))
        )}
      </Paper>
    </GridContainer>
  )
}
