import { Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core"
import { useState, useEffect } from "react"

import { produtoObservacao } from "pages/Cotacao"

import { ICotacaoFornecedor } from "types/ICotacaoFornecedor"

interface DialogObservacaoItemProps {
  cotacaoFornecedor: ICotacaoFornecedor
  dialogObservacaoProduto: boolean
  produtoObservacao: produtoObservacao
  onCloseDialogObservacaoProduto: (
    index: number,
    idCotacaoFornecedorItem: string,
    observacaoProduto: string
  ) => {}

}

export const DialogObservacaoItem = (props: DialogObservacaoItemProps) => {
  const [produtoObservacao, setProdutoObservacao] = useState({} as produtoObservacao)

  useEffect(() => {
    setProdutoObservacao(props.produtoObservacao)
  }, [props])

  return (
    <Dialog
      open={props.dialogObservacaoProduto}
      onClose={
        () => {
          props.onCloseDialogObservacaoProduto(
          props.produtoObservacao.index,
          props.produtoObservacao.cotacaoFornecedorItem.id,
          produtoObservacao.observacao
      )}}
    >
      <DialogTitle>Observação</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>
            produto: {props.produtoObservacao?.cotacaoFornecedorItem?.cotacaoItem?.produtoNome}
          </strong> <br />
          <TextField
            autoFocus
            label="Observação"
            fullWidth
            disabled={
              new Date(props.cotacaoFornecedor.cotacao.fim) < new Date()
              ? true
              : false
            }
            multiline
            rows={4}
            value={produtoObservacao.observacao}
            onKeyPress={(e) => {
              if(e.key === 'Enter' && e.shiftKey) {
                props.onCloseDialogObservacaoProduto(
                  props.produtoObservacao.index,
                  props.produtoObservacao.cotacaoFornecedorItem.id,
                  produtoObservacao.observacao
                )
              }
            }}
            onChange={(e) =>
              setProdutoObservacao({
                ...produtoObservacao,
                observacao: e.target.value
              })
            }
          />
          <span>
            SHIFT + ENTER (SALVAR)
          </span>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
