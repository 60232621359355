import {Table as TableFC} from './Table'
import {TableBody} from './TableBody'
import {TableCell} from './TableCell'
import {TableContainer} from './TableContainer'
import {TableHead} from './TableHead'
import {TableHeadCell} from './TableHeadCell'
import {TableRow} from './TableRow'

export const Table = {
  Container: TableContainer,
  Table: TableFC,
  Body: TableBody,
  Cell: TableCell,
  Head: TableHead,
  HeadCell: TableHeadCell,
  Row: TableRow
}
