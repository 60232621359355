import {Divider, IconButton, InputBase, Paper} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'

import {useClasses} from './styles'

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
type FormEvent = React.FormEvent<HTMLDivElement>

export interface ActionBarProps {
  inputValue?: string
  onInputChange?(event: InputEvent): void
  onSubmit?: (event: FormEvent) => void
  style?: React.CSSProperties
  noInput?: boolean
}

export const ActionBar: React.FC<ActionBarProps> = ({
  children,
  noInput = false,
  inputValue,
  onInputChange = () => {},
  onSubmit = (e) => e.preventDefault(),
  style
}) => {
  /**
   * Hooks
   */
  const classes = useClasses()

  /**
   * Returns
   */
  return (
    <Paper
      style={style}
      component="form"
      elevation={0}
      className={classes.root}
      onSubmit={onSubmit}
    >
      {noInput && <div style={{width: '100%'}}></div>}
      {!noInput && (
        <>
          <InputBase
            className={classes.input}
            placeholder="Pesquisar..."
            value={inputValue}
            onChange={onInputChange}
          />
          <IconButton type="submit" className={classes.iconButton}>
            <SearchIcon />
          </IconButton>
        </>
      )}
      {children && !noInput && (
        <Divider className={classes.divider} orientation="vertical" />
      )}
      {children}
    </Paper>
  )
}
