import React, {useCallback, useState} from 'react'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Loading} from 'components/Loading'
import {Paper} from 'components/Paper'
import {TextField} from 'components/TextField'

import {useAutenticacao} from 'contexts/autenticacao'
import {useToast} from 'contexts/toast'

import {apiErroHandle} from 'helpers/erro'

import {useClasses} from './styles'

export const Entrar: React.FC = () => {
  /**
   * Hooks
   */
  const {entrar} = useAutenticacao()
  const {error} = useToast()
  const classes = useClasses()

  /**
   * States
   */
  const [loading, setLoading] = useState(false)

  const [nomeUsuario, setNomeUsuario] = useState('')
  const [senha, setSenha] = useState('')

  /**
   * Handles
   */
  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)

      await entrar(nomeUsuario, senha)
    } catch (err) {
      error(apiErroHandle(err))
      setNomeUsuario('')
      setSenha('')
    } finally {
      setLoading(false)
    }
  }, [entrar, error, nomeUsuario, senha])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <Paper component="form" onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              required
              label="Usuário"
              value={nomeUsuario}
              onChange={(e) => setNomeUsuario(e.target.value)}
            />
          </GridItem>

          <GridItem xs={12}>
            <TextField
              required
              label="Senha"
              type="password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </GridItem>

          <GridItem xs={12}>
            <Button type="submit">Entrar</Button>
          </GridItem>
        </GridContainer>
      </Paper>
    </GridContainer>
  )
}
