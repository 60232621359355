import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Loading} from 'components/Loading'
import {Table} from 'components/Table'

import {useDialogo} from 'contexts/dialogo'
import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IEmpresa} from 'types/IEmpresa'

import {filterArray} from 'helpers/array'
import {apiErroHandle} from 'helpers/erro'

export const EmpresaPesquisa: React.FC = () => {
  /**
   * Hooks
   */
  const {alterarTitulo} = useTitulo()
  const {push} = useHistory()
  const {error} = useToast()
  const {confirmacao} = useDialogo()

  /**
   * States
   */
  const [pesquisar, setPesquisar] = useState('')
  const [loading, setLoading] = useState(false)
  const [idSelecionado, setIdSelecionado] = useState(0)

  const [empresas, setEmpresas] = useState<IEmpresa[]>([])
  const [empresasFilter, setEmpresasFilter] = useState<IEmpresa[]>([])

  /**
   * Callbacks
   */
  const carregarEmpresas = useCallback(async () => {
    const {data} = await api.get('/empresa')
    setEmpresas(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarEmpresas()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarEmpresas, error])

  const excluirEmpresa = useCallback(async () => {
    if (!idSelecionado) return

    try {
      setLoading(true)

      await api.delete(`/empresa/${idSelecionado}`)

      await carregarEmpresas()
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarEmpresas, error, idSelecionado])

  /**
   * Handles
   */
  const handleCellClick = useCallback((newId: string) => {
    setIdSelecionado(Number(newId))
  }, [])

  const handleIncluir = useCallback(() => {
    push('/empresa/0')
  }, [push])

  const handleAlterar = useCallback(() => {
    if (!idSelecionado) return

    push(`/empresa/${idSelecionado}`)
  }, [idSelecionado, push])

  const handleExcluir = useCallback(() => {
    if (!idSelecionado) return

    confirmacao({
      titulo: 'Atenção',
      conteudo: 'Deseja excluir a empresa?',
      acao: excluirEmpresa
    })
  }, [confirmacao, excluirEmpresa, idSelecionado])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Empresas')
  }, [alterarTitulo])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  useEffect(() => {
    const trimmedEmpresas = empresas.map((empresa) => {
      return {...empresa, nome: empresa.nome.trim()}
    })
    const sortedEmpresas = [...trimmedEmpresas].sort((a, b) =>
      a.nome.localeCompare(b.nome)
    )
    setEmpresasFilter(filterArray(pesquisar, sortedEmpresas))
  }, [empresas, pesquisar])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <ActionBar
        inputValue={pesquisar}
        onInputChange={(e) => setPesquisar(e.target.value)}
        onSubmit={carregarDados}
      >
        <ActionBarIcon
          icon={<AddIcon />}
          tip="Adicionar"
          onClick={handleIncluir}
        />
        <ActionBarIcon
          icon={<EditIcon />}
          tip="Alterar"
          onClick={handleAlterar}
        />
        <ActionBarIcon
          icon={<DeleteIcon />}
          tip="Excluir"
          onClick={handleExcluir}
        />
      </ActionBar>

      <Table.Container>
        <Table.Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell width={120}>Código</Table.HeadCell>
              <Table.HeadCell>Nome</Table.HeadCell>
              <Table.HeadCell>E-mail</Table.HeadCell>
              <Table.HeadCell width={200}>Cnpj</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {empresasFilter.map((empresa) => (
              <Table.Row
                key={empresa.id}
                selected={String(empresa.id) === String(idSelecionado)}
                onClick={() => handleCellClick(String(empresa.id))}
                onDoubleClick={handleAlterar}
              >
                <Table.Cell>{empresa.id}</Table.Cell>
                <Table.Cell>{empresa.nome}</Table.Cell>
                <Table.Cell>{empresa.email}</Table.Cell>
                <Table.Cell>{empresa.cnpj}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Table>
      </Table.Container>
    </>
  )
}
