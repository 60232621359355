import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Loading} from 'components/Loading'
import {Table} from 'components/Table'

import {useDialogo} from 'contexts/dialogo'
import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {ILicenca} from 'types/ILicenca'

import {filterArray} from 'helpers/array'
import {apiErroHandle} from 'helpers/erro'

export const LicencaPesquisa: React.FC = () => {
  /**
   * Hooks
   */
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()
  const {push} = useHistory()
  const {confirmacao} = useDialogo()

  /**
   * States
   */
  const [pesquisar, setPesquisar] = useState('')
  const [loading, setLoading] = useState(false)
  const [idSelecionado, setIdSelecionado] = useState<string | null>(null)

  const [licencas, setLicencas] = useState<ILicenca[]>([])
  const [licencasFilter, setLicencasFilter] = useState<ILicenca[]>([])

  /**
   * Callbacks
   */
  const carregarLicencas = useCallback(async () => {
    const {data} = await api.get('/licenca')
    setLicencas(data)
  }, [])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarLicencas()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarLicencas, error])

  const excluirLicenca = useCallback(async () => {
    if (!idSelecionado) return

    try {
      setLoading(true)

      await api.delete(`/licenca/${idSelecionado}`)

      await carregarLicencas()
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarLicencas, error, idSelecionado])

  /**
   * Handles
   */
  const handleAdicionar = useCallback(() => {
    push('/licenca/0')
  }, [push])

  const handleEditar = useCallback(() => {
    if (!idSelecionado) return

    push(`/licenca/${idSelecionado}`)
  }, [idSelecionado, push])

  const handleExlcuir = useCallback(() => {
    if (!idSelecionado) return

    confirmacao({
      titulo: 'Atenção',
      conteudo: 'Deseja realmente excluir a licenca?',
      acao: excluirLicenca
    })
  }, [confirmacao, excluirLicenca, idSelecionado])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo('Licenças')
  }, [alterarTitulo])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  useEffect(() => {
    setLicencasFilter(filterArray(pesquisar, licencas))
  }, [licencas, pesquisar])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar
        inputValue={pesquisar}
        onInputChange={(e) => setPesquisar(e.target.value)}
        onSubmit={carregarDados}
      >
        <ActionBarIcon
          icon={<AddIcon />}
          tip="Adicionar"
          onClick={handleAdicionar}
        />
        <ActionBarIcon
          icon={<EditIcon />}
          tip="Alterar"
          onClick={handleEditar}
        />
        <ActionBarIcon
          icon={<DeleteIcon />}
          tip="Excluir"
          onClick={handleExlcuir}
        />
      </ActionBar>

      <Table.Container>
        <Table.Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell width={150}>Código</Table.HeadCell>
              <Table.HeadCell>Nome</Table.HeadCell>
              <Table.HeadCell>Valor</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {licencasFilter.map((licenca) => (
              <Table.Row
                key={licenca.id}
                selected={licenca.id === idSelecionado}
                onClick={() => setIdSelecionado(licenca.id)}
                onDoubleClick={handleEditar}
              >
                <Table.Cell>{licenca.id}</Table.Cell>
                <Table.Cell>{licenca.nome}</Table.Cell>
                <Table.Cell>{licenca.valor}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Table>
      </Table.Container>
    </>
  )
}
