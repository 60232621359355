import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect
} from 'react'

import {api} from 'services/api'

export interface AutenticacaoContextProps {
  autenticado: boolean
  token: string
  entrar(nomeUsuario: string, senha: string): Promise<void>
  sair(): void
}

const AutenticacaoContext = createContext({} as AutenticacaoContextProps)

export const TOKEN_KEY = '@SHOPSEG_BEE_WEB/token'

export const AutenticacaoProvider: React.FC = ({children}) => {
  /**
   * Consts
   */
  const TOKEN = String(localStorage.getItem(TOKEN_KEY) || '')

  /**
   * States
   */
  const [token, setToken] = useState(TOKEN)

  /**
   * Memos
   */
  const autenticado = useMemo(() => Boolean(token), [token])

  /**
   * Callbacks
   */
  const entrar = useCallback(async (nomeUsuario: string, senha: string) => {
    const {data} = await api.post('/sessao', {nomeUsuario, senha})

    setToken(data.token)
  }, [])

  const sair = useCallback(() => {
    setToken('')
  }, [])

  /**
   * Effects
   */
  useEffect(() => {
    localStorage.setItem(TOKEN_KEY, token)
  }, [token])

  /**
   * Returns
   */
  return (
    <AutenticacaoContext.Provider value={{autenticado, token, entrar, sair}}>
      {children}
    </AutenticacaoContext.Provider>
  )
}

export const useAutenticacao = () => {
  const context = useContext(AutenticacaoContext)

  return context
}
