import EqualizerIcon from '@material-ui/icons/Equalizer'
import React, {useState, useEffect} from 'react'
import {useHistory, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Loading} from 'components/Loading'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../components/DrawerApp/index'
import useStyles from './style'

export const AppOpVendas: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const token = localStorage.getItem(TOKEN_APP_ID)
  const id = localStorage.getItem(ID_APP)
  const [top10, setTop10] = React.useState<boolean>(false)
  const [vendaFilial, setVendaFilial] = React.useState<boolean>(false)
  const [vendaDiaria, setVendaDiaria] = React.useState<boolean>(false)
  const [vendaDiariaSlucro, setVendaDiariaSlucro] =
    React.useState<boolean>(false)
  const [vendaMensal, setVendaMensal] = React.useState<boolean>(false)
  const [vendaMensalSlucro, setVendaMensalSlucro] =
    React.useState<boolean>(false)
  const [vendaHortifruti, setVendaHortifruti] = React.useState<boolean>(false)
  const [vendaAcougue, setVendaAcougue] = React.useState<boolean>(false)
  const [vendaGrupo, setVendaGrupo] = React.useState<boolean>(false)
  const [vendaPeriodo, setVendaPeriodo] = React.useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  // setLoading(true)

  const fetchRelatorios = async () => {
    if (!socket || !token || !id) return

    socket.emit('filtroRelatoriosApp', {
      message: {room: token, id: id}
    })

    socket.on('relatoriosDadosApp', (data: any) => {
      try {
        const dados = data?.dados

        if (data) {
          // setLoading(false)
          const filterTop10 = dados.filter(
            (item: any) => item?.valorrelatorio === 'top-10-vendas'
          )
          const filterVendaFilial = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_filial'
          )
          const filterVendaDiária = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_dia'
          )
          const filterVendaDiariaSLucro = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_dia_slucro'
          )
          const filterVendaMensal = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_acm_mes'
          )
          const filterVendaMensalSLucro = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_acm_mes_slucro'
          )
          const filterVendaHortifruti = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_hortifruti'
          )
          const filterVendaAcougue = dados.filter(
            (item: any) => item?.valorrelatorio === 'venda-acougue'
          )
          const filterVendaGrupo = dados.filter(
            (item: any) => item?.valorrelatorio === 'vendas_grupo'
          )
          const filterVendaPeriodo = dados.filter(
            (item: any) => item?.valorrelatorio === 'venda_periodo'
          )
          if (filterTop10 && filterTop10?.length > 0) {
            setTop10(true)
          }
          if (filterVendaFilial && filterVendaFilial?.length > 0) {
            setVendaFilial(true)
          }
          if (filterVendaDiária && filterVendaDiária?.length > 0) {
            setVendaDiaria(true)
          }
          if (filterVendaDiariaSLucro && filterVendaDiariaSLucro?.length > 0) {
            setVendaDiariaSlucro(true)
          }
          if (filterVendaMensal && filterVendaMensal?.length > 0) {
            setVendaMensal(true)
          }
          if (filterVendaMensalSLucro && filterVendaMensalSLucro?.length > 0) {
            setVendaMensalSlucro(true)
          }
          if (filterVendaHortifruti && filterVendaHortifruti?.length > 0) {
            setVendaHortifruti(true)
          }
          if (filterVendaAcougue && filterVendaAcougue?.length > 0) {
            setVendaAcougue(true)
          }
          if (filterVendaGrupo && filterVendaGrupo?.length > 0) {
            setVendaGrupo(true)
          }
          if (filterVendaPeriodo && filterVendaPeriodo?.length > 0) {
            setVendaPeriodo(true)
          }
        }
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchRelatorios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  if (loading) {
    return <Loading />
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <GridContainer>
          {top10 && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/top10')}
              >
                <EqualizerIcon className={classes.icon} />
                Top 10 Vendas
              </Button>
            </GridItem>
          )}
          {vendaFilial && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendafilial')}
              >
                <EqualizerIcon className={classes.icon} />
                Venda por Filial
              </Button>
            </GridItem>
          )}
          {vendaPeriodo && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendaperiodo')}
              >
                <EqualizerIcon className={classes.icon} />
                Venda por Período
              </Button>
            </GridItem>
          )}
          {/* {vendaDiariaSlucro && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendadiariasl')}
              >
                <EqualizerIcon className={classes.icon} />
                Venda Diária
              </Button>
            </GridItem>
          )} */}
          {vendaDiaria && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendadiaria')}
              >
                <EqualizerIcon className={classes.icon} />
                Venda Diária C/ Lucro
              </Button>
            </GridItem>
          )}
          {vendaMensalSlucro && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendamensalsl')}
              >
                <EqualizerIcon className={classes.icon} />
                Venda Mensal
              </Button>
            </GridItem>
          )}
          {vendaMensal && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendamensal')}
              >
                <EqualizerIcon className={classes.icon} />
                Venda Mensal C/ Lucro
              </Button>
            </GridItem>
          )}
          {vendaHortifruti && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendahortifruti')}
              >
                <EqualizerIcon className={classes.icon} />
                Vendas Hortifruti
              </Button>
            </GridItem>
          )}
          {vendaAcougue && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/vendaacougue')}
              >
                <EqualizerIcon className={classes.icon} />
                Vendas Açougue
              </Button>
            </GridItem>
          )}
          {vendaGrupo && (
            <GridItem xs={12}>
              <Button
                className={classes.touchable}
                onClick={() => handleButtonClick('/app/grupos')}
              >
                <EqualizerIcon className={classes.icon} />
                Vendas Por Grupo
              </Button>
            </GridItem>
          )}
        </GridContainer>
      </Paper>
    </GridContainer>
  )
}
