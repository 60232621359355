import axios from 'axios'

import {TOKEN_KEY} from 'contexts/autenticacao'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_KEY)

  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  return config
})
