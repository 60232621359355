import {IconButton} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import {formatPrice} from 'util/format'

import {List} from 'components/List'

import {IItemPedidoCompraDireta} from 'types/IItemPedidoCompraDireta'

type Props = {
  itens: IItemPedidoCompraDireta[]
  handleClickItemEdicao: (item: IItemPedidoCompraDireta) => void
  handleClickDeleteItem: (idItem: number) => void
  handleClickObservacaoItem: (
    idItem: number,
    descricao: string,
    observacao: string
  ) => void
}

export const ListaItensMobile = ({
  itens,
  handleClickItemEdicao,
  handleClickDeleteItem,
  handleClickObservacaoItem
}: Props) => {
  return (
    <List.Container>
      {itens.map((item) => (
        <List.Item key={item.id}>
          <List.ItemText
            primary={
              <>
                <strong>Produto: </strong>
                {item.fornecedorProduto.produto.produtoNome}
                <br />
                <strong>Ean: </strong>
                {item.fornecedorProduto.produto.produtoEan}
                <br />
                <strong>Referência: </strong>
                {item.fornecedorProduto.referencia}
                <br />
                <strong>Und. medida: </strong>
                {item.usaEmbalagem
                  ? `${item.embalagem.unidadeMedida.codigo}(${Number(
                      item.embalagem.fatorConversao
                    ).toFixed(item.unidadeMedida.casasDecimais)})`
                  : item.unidadeMedida.codigo}
                <br />
                <strong>Quantidade: </strong>
                {item.quantidade}
                <br />
                <strong>Preço: </strong>
                {formatPrice(item.custo)}
                <br />
                <strong>Total: </strong>
                {formatPrice(item.custo * item.quantidade)}
              </>
            }
            secondary={
              <>
                <IconButton
                  aria-label="Obs."
                  size="medium"
                  onClick={() =>
                    handleClickObservacaoItem(
                      item.id,
                      `${item.fornecedorProduto.produto.produtoEan} - ${item.fornecedorProduto.produto.produtoNome}`,
                      item.observacao
                    )
                  }
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={() => handleClickItemEdicao(item)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={() => handleClickDeleteItem(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          />
        </List.Item>
      ))}
    </List.Container>
  )
}
