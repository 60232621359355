import {TextFieldProps} from '@material-ui/core'
import React from 'react'
import {NumericFormat} from 'react-number-format'

import {TextField} from 'components/TextField'

function Number(props: any) {
  const {inputRef, onChange, decimalScale, fixedDecimalScale, ...other} = props

  return (
    //@ts-ignore
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator="."
      valueIsNumericString
      decimalSeparator=","
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      inputMode="decimal"
    />
  )
}

type AdditionalProps = {
  decimalScale?: number
  fixedDecimalScale?: boolean
}

export const NumericTaxtField: React.FC<TextFieldProps & AdditionalProps> = ({
  ...props
}) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: Number,
        inputProps: {
          decimalScale: props.decimalScale,
          fixedDecimalScale: props.fixedDecimalScale
        },
        ...props.InputProps
      }}
    />
  )
}
