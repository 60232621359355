import {makeStyles} from '@material-ui/core'

export const useClasses = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
})
