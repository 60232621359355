import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {DrawerApp} from 'components/DrawerApp'
import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import useStyles from './style'

interface Product {
  id: string
  nome: string
  codigo: string
}

export const AppGrupos: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)

  const [products, setProducts] = useState<Product[]>([])
  const [socketConnected, setSocketConnected] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    setLoading(true)
    socket.emit('filtroGruposApp', {
      message: {
        room: token
      }
    })
    socket.on('gruposDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setProducts(dados)
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  useEffect(() => {
    // Atualiza a lista de produtos filtrados sempre que o valor de searchValue mudar
    const filtered = products.filter((product) =>
      product.nome.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredProducts(filtered)
  }, [searchValue, products])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <div className={classes.busca}>
          <TextField
            label="Buscar..."
            margin="normal"
            variant="outlined"
            className={classes.search}
            InputProps={{
              type: 'search',
              value: searchValue,
              onChange: handleChange
            }}
          />
        </div>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.cardContainer}>
          {filteredProducts.length > 0 && !loading
            ? filteredProducts.map((result) => (
                <Link
                  className={classes.card}
                  key={result.id}
                  to={{
                    pathname: '/app/vendagrupo',
                    state: {productId: result.codigo}
                  }}
                >
                  <Card className={classes.bkcard}>
                    <CardContent>
                      <h3>{result?.nome}</h3>
                    </CardContent>
                  </Card>
                </Link>
              ))
            : products.map((result) => (
                <Link
                  className={classes.card}
                  key={result.id}
                  to={{
                    pathname: '/app/vendagrupo',
                    state: {productId: result.codigo}
                  }}
                >
                  <Card className={classes.bkcard}>
                    <CardContent>
                      <h3>{result?.nome}</h3>
                    </CardContent>
                  </Card>
                </Link>
              ))}
        </div>
      </Paper>
    </GridContainer>
  )
}
