import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GetAppIcon from '@material-ui/icons/GetApp';
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import TelegramIcon from '@material-ui/icons/Telegram';
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {Paper} from 'components/Paper'

import {useAutenticacao} from 'contexts/autenticacao'
import {useTitulo} from 'contexts/titulo'

import {useClasses} from '../private/styles'

export const PrivateLayout: React.FC = ({children}) => {
  /**
   * Hooks
   */
  const classes = useClasses()
  const {titulo} = useTitulo()
  const {sair} = useAutenticacao()

  /**
   * States
   */
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dialogSairOpen, setDialogSairOpen] = useState(false)
  const {push} = useHistory()

  /**
   * Handles
   */
  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const handleOpenDialogSair = useCallback(() => {
    handleCloseDrawer()
    setDialogSairOpen(true)
  }, [handleCloseDrawer])

  const handleCloseDialogSair = useCallback(() => {
    setDialogSairOpen(false)
  }, [])

  const handleNavigate = useCallback(
    (path: string) => {
      if (!path) return

      handleCloseDrawer()
      push(path)
    },
    [handleCloseDrawer, push]
  )

  /**
   * Returns
   */
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.menuButton}
            onClick={handleOpenDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {titulo}
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer open={drawerOpen} onClose={handleCloseDrawer}>
        <List className={classes.list}>
          <ListItem button onClick={() => handleNavigate('/empresa')}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Empresas" />
          </ListItem>

          <ListItem button onClick={() => handleNavigate('/licenca')}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary="Licenças" />
          </ListItem>

          <ListItem button onClick={() => handleNavigate('/relatorioBot')}>
            <ListItemIcon>
              <TelegramIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios do Bot" />
          </ListItem>

          <ListItem button onClick={() => handleNavigate('/usuario')}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem>

          <ListItem button onClick={() => handleNavigate('/download')}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary="Página de downloads" />
          </ListItem>

          <ListItem button onClick={() => handleNavigate('/build')}>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText primary="Builds" />
          </ListItem>

          <Divider />

          <ListItem button onClick={handleOpenDialogSair}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Drawer>

      <Dialog open={dialogSairOpen} onClose={handleCloseDialogSair}>
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText>Deseja sair do sistema?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogSair} color="primary">
            Não
          </Button>
          <Button onClick={sair} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.paper}>{children}</Paper>
    </>
  )
}
