import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Grid} from 'components/Grid'
import {Loading} from 'components/Loading'
import {TextField} from 'components/TextField'

import {useTitulo} from 'contexts/titulo'
import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {ILicenca} from 'types/ILicenca'

import {apiErroHandle} from 'helpers/erro'

export const Licenca: React.FC = () => {
  /**
   * Hooks
   */
  const {idLicenca} = useParams<{idLicenca: string}>()
  const {push} = useHistory()
  const {alterarTitulo} = useTitulo()
  const {error} = useToast()

  /**
   * Consts
   */
  const LICENCA = {} as ILicenca

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [licenca, setLicenca] = useState(LICENCA)

  /**
   * Memos
   */
  const incluirRegistro = useMemo(() => !Boolean(Number(idLicenca)), [
    idLicenca
  ])

  /**
   * Callbacks
   */
  const carregarLicenca = useCallback(async () => {
    if (incluirRegistro) return

    const {data} = await api.get(`/licenca/${idLicenca}`)

    setLicenca(data)
  }, [idLicenca, incluirRegistro])

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([carregarLicenca()])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [carregarLicenca, error])

  const add = useCallback(async () => {
    await api.post('/licenca', licenca)
  }, [licenca])

  const edit = useCallback(async () => {
    if (incluirRegistro) return

    await api.put(`/licenca/${idLicenca}`, licenca)
  }, [idLicenca, incluirRegistro, licenca])

  /**
   * Handles
   */
  const handleCancelar = useCallback(() => {
    push('/licenca')
  }, [push])

  const handleConfirmar = useCallback(async () => {
    try {
      setLoading(true)

      if (incluirRegistro) {
        await add()
      } else {
        await edit()
      }

      push('/licenca')
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [add, edit, error, incluirRegistro, push])

  /**
   * Effects
   */
  useEffect(() => {
    alterarTitulo(`Licença ${incluirRegistro ? '' : idLicenca}`)
  }, [alterarTitulo, idLicenca, incluirRegistro])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <ActionBar noInput>
        <ActionBarIcon
          icon={<CheckIcon />}
          tip="Confirmar"
          onClick={handleConfirmar}
        />
        <ActionBarIcon
          icon={<CloseIcon />}
          tip="Cancelar"
          onClick={handleCancelar}
        />
      </ActionBar>

      <Grid.Container>
        <Grid.Item xs={12}>
          <TextField label="Código" disabled value={licenca.id} />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TextField
            label="Nome"
            value={licenca.nome}
            onChange={(e) => setLicenca({...licenca, nome: e.target.value})}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TextField
            label="Valor"
            value={licenca.valor}
            onChange={(e) => setLicenca({...licenca, valor: e.target.value})}
          />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
