import {TableCell, TableCellProps} from '@material-ui/core'
import React from 'react'

export const TableHeadCell: React.FC<TableCellProps> = ({
  children,
  ...props
}) => {
  return (
    <TableCell {...props}>
      <strong>{children}</strong>
    </TableCell>
  )
}
