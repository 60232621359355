import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BarChartIcon from '@material-ui/icons/BarChart'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import StorageIcon from '@material-ui/icons/Storage'
import React, {useEffect, useState} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {GridItem} from 'components/Grid/Item'
import {Paper} from 'components/Paper'

import logo from '../../../assets/logo.png'
import {DrawerApp} from '../../../components/DrawerApp/index'
import useStyles from './style'

export const AppHome: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const socket = getSocket()
  const ID_APP = '@ID_APP'
  const TOKEN_APP = '@TOKEN_APP'
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const idUsuario = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP)
  const tokenId = localStorage.getItem(TOKEN_APP_ID)
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  useEffect(() => {
    if (socket) {
      if (tokenId) {
        disconnectSocket(tokenId)
      }
      socket.emit('filtroLoginIdApp', {
        message: {room: token, id: idUsuario}
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  const handleButtonClick = (route: string) => {
    history.push(route)
  }

  if (!idUsuario || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <GridContainer>
          <GridItem xs={12}>
            <img src={logo} className={classes.logo} alt="Logo" />
          </GridItem>
          <GridItem xs={12}>
            <Button
              className={classes.touchable}
              onClick={() => handleButtonClick('/app/vendas')}
            >
              <BarChartIcon className={classes.icon} />
              Vendas
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Button
              className={classes.touchable}
              onClick={() => handleButtonClick('/app/financeiro')}
            >
              <AttachMoneyIcon className={classes.icon} />
              Financeiro
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Button
              className={classes.touchable}
              onClick={() => handleButtonClick('/app/opprodutos')}
            >
              <ShoppingBasketIcon className={classes.icon} />
              Produtos
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Button
              className={classes.touchable}
              onClick={() => handleButtonClick('/app/outros')}
            >
              <StorageIcon className={classes.icon} />
              Outros
            </Button>
          </GridItem>
        </GridContainer>
      </Paper>
    </GridContainer>
  )
}
