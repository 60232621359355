import {Typography, useMediaQuery} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {ActionBar} from 'components/ActionBar'
import {ActionBarIcon} from 'components/ActionBarIcon'
import {Loading} from 'components/Loading'

import {useToast} from 'contexts/toast'

import {api} from 'services/api'

import {IFornecedorCompraDireta} from 'types/IFornecedorCompraDireta'
import {IPedidoCompraDireta} from 'types/IPedidoCompraDireta'

import {apiErroHandle} from 'helpers/erro'

import {DialogComfirmExclusaoPedido} from './DialogComfirmExclusaoPedido'
import {ListaPedidosMobile} from './ListaPedidosMobile'
import {TablePedidos} from './TablePedidos'

export const CompraDireta: React.FC = () => {
  /**
   * Hooks
   */
  const {chave} = useParams<{chave: string}>()
  const {push} = useHistory()
  const {error, success} = useToast()
  const matches = useMediaQuery('(min-width:750px)')

  /**
   * Memos
   */
  const mobile = useMemo(() => !matches, [matches])

  /**
   * States
   */
  const [loading, setLoading] = useState(false)
  const [fornecedorCompraDireta, setFornecedorCompraDireta] =
    useState<IFornecedorCompraDireta>()
  const [pedidosCompraDireta, setPedidosCompraDireta] = useState<
    IPedidoCompraDireta[]
  >([])
  const [isShowDialogExclusaoPedido, setIsShowDialogExclusaoItem] =
    useState(false)
  const [idPedidoExclusao, setIdPedidoExclusao] = useState(0)

  /**
   * Callbacks
   */
  const carregarPedidosFornecedorCompraDireta = useCallback(async () => {
    try {
      const {data} = await api.get<IPedidoCompraDireta[]>(
        `/obterPedidosCompraDireta/${chave}`
      )

      setPedidosCompraDireta(data)
    } catch (err) {
      throw err
    }
  }, [chave])

  const carregarFornecedorCompraDireta = useCallback(async () => {
    try {
      const {data} = await api.get<IFornecedorCompraDireta>(
        `/fornecedorCompraDireta/${chave}`
      )

      setFornecedorCompraDireta(data)
    } catch (err) {
      push('/compraDireta')

      throw err
    }
  }, [chave, push])

  const handleClickConfirmExclusaoPedido = useCallback(
    async (idPedido: number) => {
      try {
        setLoading(true)

        await api.delete<IPedidoCompraDireta[]>(
          `/pedidoCompraDireta/${idPedido}`
        )

        await carregarPedidosFornecedorCompraDireta()

        setIdPedidoExclusao(0)
        setIsShowDialogExclusaoItem(false)

        success('Pedido excluído com sucesso!')
      } catch (err) {
        error(apiErroHandle(err))
      } finally {
        setLoading(false)
      }
    },
    [carregarPedidosFornecedorCompraDireta, error, success]
  )

  const handleClickDuplicarPedido = useCallback(
    async (idPedido: number) => {
      try {
        setLoading(true)

        await api.post<IPedidoCompraDireta[]>(
          `/pedidoCompraDireta/${idPedido}/duplicar`
        )

        await carregarPedidosFornecedorCompraDireta()

        success('Pedido duplicado com sucesso!')
      } catch (err) {
        error(apiErroHandle(err))
      } finally {
        setLoading(false)
      }
    },
    [carregarPedidosFornecedorCompraDireta, error, success]
  )

  const carregarDados = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        carregarFornecedorCompraDireta(),
        carregarPedidosFornecedorCompraDireta()
      ])
    } catch (err) {
      error(apiErroHandle(err))
    } finally {
      setLoading(false)
    }
  }, [
    carregarFornecedorCompraDireta,
    carregarPedidosFornecedorCompraDireta,
    error
  ])

  /**
   * Effects
   */
  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  /**
   * Returns
   */
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Typography>
        Cliente:{' '}
        {`${fornecedorCompraDireta?.empresa?.cnpj} - ${fornecedorCompraDireta?.empresa?.nome}`}
      </Typography>

      <ActionBar noInput>
        <ActionBarIcon
          icon={<AddIcon />}
          tip="Adicionar"
          onClick={() => push(`/compraDireta/${chave}/pedido/new`)}
        />
      </ActionBar>
      {mobile ? (
        <ListaPedidosMobile
          handleClickDuplicarPedido={handleClickDuplicarPedido}
          handleClickExcluirPedido={(id) => {
            setIdPedidoExclusao(id)
            setIsShowDialogExclusaoItem(true)
          }}
          pedidosCompraDireta={pedidosCompraDireta}
        />
      ) : (
        <TablePedidos
          handleClickDuplicarPedido={handleClickDuplicarPedido}
          handleClickExcluirPedido={(id) => {
            setIdPedidoExclusao(id)
            setIsShowDialogExclusaoItem(true)
          }}
          pedidosCompraDireta={pedidosCompraDireta}
        />
      )}
      {isShowDialogExclusaoPedido && (
        <DialogComfirmExclusaoPedido
          handleClickCancel={() => {
            setIdPedidoExclusao(0)
            setIsShowDialogExclusaoItem(false)
          }}
          idPedido={idPedidoExclusao}
          handleClickConfirmExclusao={handleClickConfirmExclusaoPedido}
        />
      )}
    </>
  )
}
