import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#fff',
    width: '100%'
  },
  paper: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: -25,
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#fff',
    width: '100%'
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20,
    width: '100%'
  },
  card: {
    marginTop: 15,
    minWidth: 200,
    minHeight: 120,
    borderRadius: 15,
    width: '100%',
    backgroundColor: '#f5f5f5',
    border: 15
  },
  label: {
    textAlign: 'left',
    fontSize: '17px',
    marginBottom: 15,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  textField: {
    width: '100%',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  table: {
    backgroundColor: '#fff',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  legend: {
    maxWidth: '1px',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 300,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none'
  },
  rowCell: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none',
  },
}))

export default useStyles;
