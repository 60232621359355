import {TextField} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  Value
} from '@material-ui/lab'
import React from 'react'

interface IAutocomplete<
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  label?: string
  required?: boolean
  textFieldRef?: React.Ref<any>
  loading?: boolean
  styleInput?: React.CSSProperties
  inputVariant?: 'outlined' | 'filled' | 'standard'
}

interface IAutocompleteProps<
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined
> extends Omit<IAutocomplete<T, Multiple, DisableClearable, FreeSolo>, 'onChange'> {
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<T, Multiple, DisableClearable, FreeSolo> | any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void
}

export default function Autocomplete<
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined
>({
  label,
  required,
  textFieldRef,
  loading,
  styleInput,
  inputVariant = 'standard',
  ...props
}: IAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <MuiAutocomplete
      {...props}
      openOnFocus={false}
      clearText="Limpar"
      closeText="Fechar"
      noOptionsText="Nenhuma opção encontrada"
      openText="Abrir"
      renderInput={(renderParams) => (
        <TextField
          {...renderParams}
          required={required}
          inputRef={textFieldRef}
          label={label}
          fullWidth
          variant={inputVariant}
          InputProps={{
            ...renderParams.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {renderParams.InputProps.endAdornment}
              </>
            ),
            style: styleInput
          }}
        />
      )}
    />
  )
}
