import {makeStyles} from '@material-ui/core'

export const useClasses = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  list: {
    width: 300
  },
  paper: {
    padding: 5
  }
}))
