import {IconButton, Tooltip} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {useHistory, useParams} from 'react-router-dom'
import {formatData, formatPrice} from 'util/format'

import {Table} from 'components/Table'
import {TableContainer} from 'components/Table/TableContainer'

import {
  IPedidoCompraDireta,
  StatusPedidoCompraDireta
} from 'types/IPedidoCompraDireta'

type Props = {
  pedidosCompraDireta: IPedidoCompraDireta[]
  handleClickExcluirPedido: (idPedido: number) => void
  handleClickDuplicarPedido: (idPedido: number) => void
}

export const TablePedidos = (props: Props) => {
  const {push} = useHistory()
  const {chave} = useParams<{chave: string}>()

  return (
    <TableContainer>
      <Table.Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Código</Table.HeadCell>
            <Table.HeadCell>Data Emissão</Table.HeadCell>
            <Table.HeadCell>Prev. Entrega</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell width={250}>Valor</Table.HeadCell>
            <Table.HeadCell width={40} />
            <Table.HeadCell width={40} />
            <Table.HeadCell width={40} />
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {props.pedidosCompraDireta.map((pedido) => (
            <Table.Row key={pedido.id}>
              <Table.Cell>{pedido.id}</Table.Cell>
              <Table.Cell>{formatData(pedido.dataCriacao)}</Table.Cell>
              <Table.Cell>{formatData(pedido.dataPrevisao)}</Table.Cell>
              <Table.Cell>
                {pedido.status === StatusPedidoCompraDireta.ABERTO
                  ? 'Aberto'
                  : 'Fechado'}
              </Table.Cell>
              <Table.Cell>{formatPrice(pedido.valorTotal)}</Table.Cell>
              <Table.Cell>
                <Tooltip title="Editar">
                  <IconButton
                    size="medium"
                    onClick={() =>
                      push(`/compraDireta/${chave}/pedido/${pedido.id}`)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Table.Cell>
              <Table.Cell>
                <Tooltip title="Duplicar">
                  <IconButton
                    size="medium"
                    onClick={() => props.handleClickDuplicarPedido(pedido.id)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Table.Cell>
              <Table.Cell>
                <Tooltip title="Excluir">
                  <IconButton
                    size="medium"
                    onClick={() => props.handleClickExcluirPedido(pedido.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Table>
    </TableContainer>
  )
}
