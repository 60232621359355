export const filterArray = (searchTerm: string, array: any[]) => {
  if (!searchTerm) return array

  const SEARCH_TERM = searchTerm.toLowerCase()

  const filter = (item: any): boolean => {
    if (!item) return false

    let equals = false
    const keys = Object.keys(item)

    keys.forEach((key) => {
      if (!equals) {
        if (typeof item[key] !== 'object') {
          equals = String(item[key]).toLowerCase().includes(SEARCH_TERM)
        }

        if (typeof item[key] === 'object') {
          equals = filter(item[key])
        }
      }
    })

    return equals
  }

  return array.filter(filter)
}
