import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

interface Product {
  codigo: string
  produto: string
  custo: number
  custoAnterior: number
  custoDiferenca: number
  dataNotaAnterior: string
  notaAnterior: string
  quantidade: number
  quantidadeAnterior: number
  quantidadeDiferenca: number
}

export const AppDivergencia: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)

  const [socketConnected, setSocketConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [produtos, setProdutos] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [nota, setNota] = useState<string>('')
  const [dataNota, setDataNota] = useState<string>('')
  const [usuario, setUsuario] = useState<string>('')
  const [idNotaFiscal, setIdNotaFiscal] = useState<string>('')

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = async () => {
    socket.emit('filtroDivergenciaNFApp', {
      message: {
        room: token,
        idNotaFiscal: idNotaFiscal
      }
    })
    socket.on('divergenciaNFDadosApp', (data: any) => {
      try {
        const dados = data?.dados
        setData(dados?.itens)
        setNota(dados?.nota)
        setDataNota(dados?.dataNota)
        setUsuario(dados.usuario)
        if (dados?.itens?.length > 0) {
          setProdutos(true)
        } else {
          setProdutos(false)
        }
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }

  function formatData(dataOriginal: string): string {
    const dataHoraObj: Date = new Date(dataOriginal)

    const dia: number = dataHoraObj.getUTCDate()
    const mes: number = dataHoraObj.getUTCMonth() + 1
    const ano: number = dataHoraObj.getUTCFullYear()

    const dataFormatada: string = `${dia < 10 ? '0' : ''}${dia}-${
      mes < 10 ? '0' : ''
    }${mes}-${ano}`

    return dataFormatada
  }

  useEffect(() => {
    if (socketConnected) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.preventDefault()
    if (idNotaFiscal.trim() !== '') {
      fetchProducts()
    }
  }

  const handleChangeIdNotaFiscal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIdNotaFiscal(event.target.value)
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nota Fiscal"
            margin="normal"
            variant="outlined"
            onChange={handleChangeIdNotaFiscal}
            className={classes.search2}
            InputProps={{
              type: 'search',
              value: idNotaFiscal,
              color: 'primary'
            }}
          />
        </form>
        {loading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {!loading && !produtos && idNotaFiscal && !nota && (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        )}
        {produtos && (
          <div>
            <Card className={classes.card}>
              <CardContent>
                <h3 className={classes.p}>Nota Fiscal: {nota}</h3>
                <p className={classes.p}>
                  Data da Nota: {formatData(dataNota)}
                </p>
                <p className={classes.p}>Usuário: {usuario}</p>
              </CardContent>
            </Card>
            {data.map((item: Product, index: number) => (
              <Card key={index} className={classes.card}>
                <CardContent>
                  <h4 className={classes.p}>{item.produto}</h4>
                  <p className={classes.p}>Código: {item.codigo}</p>
                  <p className={classes.p}>Quantidade: {item.quantidade}</p>
                  <p className={classes.p}>
                    Custo: {formatPrice(item.custo) || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Data da Nota Anterior: {formatData(item.dataNotaAnterior)}
                  </p>
                  <p className={classes.p}>
                    Nota Anterior: {item.notaAnterior}
                  </p>
                  <p className={classes.p}>
                    Quantidade Anterior: {item.quantidadeAnterior}
                  </p>
                  <p className={classes.p}>
                    Custo Anterior:{' '}
                    {formatPrice(item.custoAnterior) || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Diferença de Custo:{' '}
                    {formatPrice(item.custoDiferenca) || 'Não informado'}
                  </p>
                  <p className={classes.p}>
                    Diferença de Quantidade: {item.quantidadeDiferenca}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </Paper>
    </GridContainer>
  )
}
