import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {Button} from 'components/Button'
import {Grid} from 'components/Grid'
import {Paper} from 'components/Paper'
import {TextField} from 'components/TextField'

import {useToast} from 'contexts/toast'

import {useClasses} from './styles'

export const CotacaoEntrar: React.FC = () => {
  /**
   * Hooks
   */
  const classes = useClasses()
  const {push} = useHistory()

  /**
   * States
   */
  const [chave, setChave] = useState('')

  /**
   * Handles
   */
  const handleSubmit = useCallback(() => {
    push(`/cotacao/${chave}`)
  }, [chave, push])

  /**
   * Returns
   */
  return (
    <Grid.Container spacing={0} className={classes.container}>
      <Paper component="form" onSubmit={handleSubmit}>
        <Grid.Container>
          <Grid.Item xs={12}>
            <TextField
              required
              className={classes.textField}
              label="Chave cotação"
              value={chave}
              onChange={(e) => setChave(e.target.value)}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <Button type="submit">Entrar</Button>
          </Grid.Item>
        </Grid.Container>
      </Paper>
    </Grid.Container>
  )
}
