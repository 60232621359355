import {IconButton, Tooltip} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {useHistory, useParams} from 'react-router-dom'
import {formatData, formatPrice} from 'util/format'

import {List} from 'components/List'
import {Table} from 'components/Table'
import {TableContainer} from 'components/Table/TableContainer'

import {
  IPedidoCompraDireta,
  StatusPedidoCompraDireta
} from 'types/IPedidoCompraDireta'

type Props = {
  pedidosCompraDireta: IPedidoCompraDireta[]
  handleClickExcluirPedido: (idPedido: number) => void
  handleClickDuplicarPedido: (idPedido: number) => void
}

export const ListaPedidosMobile = (props: Props) => {
  const {push} = useHistory()
  const {chave} = useParams<{chave: string}>()

  return (
    <List.Container>
      {props.pedidosCompraDireta.map((pedido) => (
        <List.Item key={pedido.id}>
          <List.ItemText
            primary={
              <>
                <strong>Código: </strong>
                {pedido.id}
                <br />
                <strong>Data Emissão: </strong>
                {formatData(pedido.dataCriacao)}
                <br />
                <strong>Prev. Entrega: </strong>
                {formatData(pedido.dataPrevisao)}
                <br />
                <strong>Status: </strong>
                {pedido.status === StatusPedidoCompraDireta.ABERTO
                  ? 'Aberto'
                  : 'Fechado'}
                <br />
                <strong>Valor: </strong>
                {formatPrice(pedido.valorTotal)}
              </>
            }
            secondary={
              <>
                <IconButton
                  size="medium"
                  onClick={() =>
                    push(`/compraDireta/${chave}/pedido/${pedido.id}`)
                  }
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={() => props.handleClickDuplicarPedido(pedido.id)}
                >
                  <FileCopyIcon />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={() => props.handleClickExcluirPedido(pedido.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          />
        </List.Item>
      ))}
    </List.Container>
  )
}
