import React, {createContext, useCallback, useContext, useState} from 'react'

export interface TituloContextProps {
  titulo: string
  alterarTitulo(novoTitulo: string): void
}

const TituloContext = createContext({} as TituloContextProps)

export const TituloProvider: React.FC = ({children}) => {
  /**
   * States
   */
  const [titulo, setTitulo] = useState('')

  /**
   * Callbacks
   */
  const alterarTitulo = useCallback((novoTitulo: string) => {
    if (!novoTitulo) return

    setTitulo(novoTitulo)
  }, [])

  /**
   * Returns
   */
  return (
    <TituloContext.Provider value={{titulo, alterarTitulo}}>
      {children}
    </TituloContext.Provider>
  )
}

export const useTitulo = () => {
  const context = useContext(TituloContext)

  return context
}
